import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { FormInput } from '../../../../components/Input';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { StakeholderCombobox } from '../Components';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepOne as stepOneFormSchema } from '../Validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  companyId,
  lockMode,
  filedState,
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();
  const { success } = stepOneFormSchema.safeParse(stepOne);

  const isValid = success && !filedState('stepOne').invalid;

  stepOne;

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <StakeholderCombobox companyId={companyId} control={control} />
        <FormInput
          control={control}
          currencySign
          name="stepOne.investment"
          numberOnly
          placeholder={<AppFormattedMessage id={StringKey.INVESTMENT} />}
          shouldFormatNumber
        />

        <FormDatePicker
          calendar={{ toDate: new Date() }}
          defaultMonth={stepOne?.issueDate || undefined}
          inputValue={stepOne?.issueDate ? format(stepOne?.issueDate, 'dd/MM/yyyy') : ''}
          inputWrapperClassName="w-full"
          isDefaultOpenCalendar={isOpenCalendar}
          onSelect={(date) => {
            setFormData('stepOne.issueDate', date);
            setOpenCalendar(false);
          }}
          placeholder={<AppFormattedMessage id={StringKey.ISSUE_DATE} />}
          value={stepOne?.issueDate}
        />
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
