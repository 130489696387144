import { StringKey } from '../string-key.enum';

export const frMessages: Record<StringKey, string> = {
  /// For this keys Translation is missing
  [StringKey.EMPLOYEE]: 'Employé',
  [StringKey.FOUNDER]: 'Fondateur',
  [StringKey.BOARD_MEMBERS]: 'Membres du CA',
  [StringKey.VCS]: 'Venture Capital',
  [StringKey.INVESTMENT_BANKS]: "Banque d'investissement",
  [StringKey.PES]: 'Capital-investissement',
  [StringKey.FAMILY_OFFICE]: 'Family Office',
  [StringKey.OTHER_INST]: 'Autre Investisseurs Inst.',
  [StringKey.STAKEHOLDER]: 'Actionnaire',
  [StringKey.NO_GO_BACK]: 'Non, reviens sur la page',
  [StringKey.NO_KEEP_1]: 'Non',
  [StringKey.NO_KEEP_2]: 'Non',
  [StringKey.NO_KEEP_3]: 'Non, garde le',
  [StringKey.NO_KEEP_4]: 'Non, garde les',
  [StringKey.NO_KEEP_5]: "Non, garde l'accés",
  [StringKey.CAN_REACTIVATE]: 'Vous pouvez réactiver votre abonnement dans les 6 prochains mois',
  [StringKey.SAVE_APPROX]: '(économisez environ 17%)',
  [StringKey.BETWEEN_SYMBOL]: 'à',
  [StringKey.COUNT_STAKEHOLDERS]: '{count} actionnaires',
  [StringKey.ACTIVE]: 'Actif',
  [StringKey.ACTIVE_UNTIL]: "Actif jusqu'au {date}",
  [StringKey.ACTIVITY]: 'Activité',
  [StringKey.ADD_NEW_COMPANY]: 'Ajouter une nouvelle entreprise',
  [StringKey.ADD_STAKEHOLDER]: 'Ajouter un actionnaire',
  [StringKey.ADDRESS]: 'Adresse',
  [StringKey.ADDRESS_OPTIONAL]: 'Adresse (facultatif)',
  [StringKey.INACTIVE]: 'Inactif',
  [StringKey.ROWS_SELECTED]: '{count, plural, =1 {# ligne} other {# lignes}} sélectionnées',
  [StringKey.ACCESS_AND_PERMISSIONS]: 'Accès et autorisations',
  [StringKey.ACCESS_REVOKED]: 'Accès révoqué',
  [StringKey.ACCOUNT]: 'Compte',
  [StringKey.ADMIN]: 'Administrateur',
  [StringKey.ADVISOR]: 'Conseiller',
  [StringKey.ALL]: 'Tous',
  [StringKey.PLAN_SOON_EXPIRE]:
    "Nous sommes navrés de vous voir partir. Vous pouvez continuer à utiliser CapQuest jusqu'à ce que votre abonnement actuel expire le {date}.",
  [StringKey.ALL_ASSOCIATED_STAKEHOLDER_DATA_WILL_BE_LOST]:
    "Toutes les données associées à l'actionnaire seront perdues.",
  [StringKey.ALL_STAKEHOLDERS]: 'Tous les actionnaires',
  [StringKey.ALREADY_HAVE_ACCOUNT]: 'Vous avez déjà un compte?',
  [StringKey.AMOUNT_TO_PAY_TODAY]: "Montant à payer aujourd'hui",
  [StringKey.ANGEL]: 'Angel',
  [StringKey.ANGELS]: 'Angels',
  [StringKey.ANNUAL]: 'Annuel',
  [StringKey.ANNUAL_REVENUE]: 'Revenu annuel ($)',
  [StringKey.ANOTHER_BENEFIT]: 'Autre avantage n°{num}',
  [StringKey.ANY_ADDITIONAL_FEEDBACK]: 'Des commentaires supplémentaires ?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_CANCEL]: "Êtes-vous sûr de vouloir annuler l'abonnement ?",
  [StringKey.ARE_YOU_SURE_YOU_WANT_DEACTIVATE]:
    "Etes-vous sûr de vouloir désactiver l'entreprise ?",
  [StringKey.LOSE_ACCESS_CAUTION]:
    " Vous et tous les actionnaires n’y aurez plus accès. Vous avez {time} pour réactiver l'entreprise après quoi elle sera définitivement supprimée.",
  [StringKey.ARE_YOU_SURE_YOU_WANT_DELETE_ACCOUNT]:
    'Êtes-vous sûr de vouloir supprimer votre compte ?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_GRANT_ACCESS]: " Êtes-vous sûr de vouloir accorder l'accès ?",
  [StringKey.ARE_YOU_SURE_YOU_WANT_MAKE_CONTRIBUTOR]:
    'Êtes-vous sûr de vouloir rendre cet utilisateur contributeur ?',
  [StringKey.AT_LEAST_ONE_LOWER_CASE_LETTER]: 'Au moins 1 lettre minuscule (a-z)',
  [StringKey.AT_LEAST_ONE_SPECIAL_CHAR]: 'Au moins 1 caractère spécial (par exemple !@#$%^&*)',
  [StringKey.AT_LEAST_ONE_UPPER_CASE_LETTER]: 'Au moins 1 lettre majuscule (A-Z)',
  [StringKey.AT_LEAST_EIGHT_CHARS]: 'Au moins 8 caractères',
  [StringKey.AUTO_RENEW_ON]: 'Renouvellement automatique activé',
  [StringKey.BACK]: 'Retour',
  [StringKey.BACK_TO_SIGN_IN]: 'Retour à la page de connexion',
  [StringKey.BEFORE_YOU_START_YOUR_JOURNEY_CONFIRMATION]:
    'Avant de commencer votre parcours, nous voulions simplement vous montrer comment vos informations ont été enregistrées. Si vous avez des commentaires, veuillez contacter votre administrateur',
  [StringKey.BILLED_ANNUALY]: 'Facturé annuellement',
  [StringKey.ANNUALY]: 'Annualy',
  [StringKey.BILLED_MONTHLY]: 'Facturé mensuellement tous les {date}',
  [StringKey.BILLED_MONTHLY_PLAN]: 'Facturé mensuellement',
  [StringKey.BIRTHDAY]: 'Date de naissance',
  [StringKey.BIRTHDAY_OPTIONAL]: 'Date de naissance (facultatif)',
  [StringKey.BULK_MODIFY]: 'Modification groupée',
  [StringKey.BULK_MODIFY_STAKEHOLDERS]: 'Modifier en bloc les actionnaires',
  [StringKey.BY_PROCEEDING_YOU_AGREE_TO]: 'En poursuivant, vous acceptez',
  [StringKey.TERMS_OF_SERVICE]: "les conditions d'utilisation",
  [StringKey.AND]: 'et',
  [StringKey.PRIVACY_POLICY]: 'la politique de confidentialité',
  [StringKey.CANCEL]: 'Annuler',
  [StringKey.CANCEL_INVITATION]: "Annuler l'invitation",
  [StringKey.CANCEL_SUBSCRIPTION]: "Annuler l'abonnement",
  [StringKey.CANNOT_DOWNGRADE]: "Impossible de changer d'abonnement",
  [StringKey.CAP_TABLE_MANAGEMENT]: 'Gestion de la Cap Table',
  [StringKey.MISSING_FEATURES]: 'CapQuest a des fonctionnalités manquantes',
  [StringKey.TOO_EXPENSIVE]: 'CapQuest est trop cher',
  [StringKey.CARD_NUMBER]: 'Numéro de carte',
  [StringKey.CARDHOLDER_NAME]: 'Nom du titulaire',
  [StringKey.CHANGE_PASSWORD]: 'Changer le mot de passe',
  [StringKey.CHANGE_PLAN]: "Changer d'abonnement",
  [StringKey.CHANGE_YOUR_PASSWORD]: 'Changez votre mot de passe',
  [StringKey.CHECK_THE_URL]: "Vérifiez que l'URL ne contient pas de fautes de frappe et réessayez.",
  [StringKey.CHECK_YOUR_EMAIL]: 'Vérifiez votre e-mail',
  [StringKey.CHECK_YOUR_INBOX]: 'Vérifiez votre boîte de réception',
  [StringKey.CITY]: 'Ville',
  [StringKey.CLICK_TO_UPLOAD]: 'Cliquez pour télécharger',
  [StringKey.OR_DRAG_AND_DROP]: 'ou glisser-déposer',
  [StringKey.CLOSE]: 'Fermer',
  [StringKey.COLLAPSE]: 'Réduire',
  [StringKey.COMPANY_ADDRESS]: 'Adresse de la société',
  [StringKey.COMPANY_ID_NUMBER_OPTIONAL]: "Numéro d'identification de l'entreprise (facultatif)",
  [StringKey.COMPANY_IDENTIFIER]: "Identifiant de l'entreprise",
  [StringKey.COMPANY_IDENTIFIER_OPTIONAL]: "Identifiant de l'entreprise (facultatif)",
  [StringKey.COMPANY_LOGO_OPTIONAL]: "Logo de l'entreprise (facultatif)",
  [StringKey.COMPANY_NAME]: "Nom de l'entreprise",
  [StringKey.COMPANY_REGISTRATION_DETAILS]: "Détails d'enregistrement de l'entreprise",
  [StringKey.COMPANY_SETTINGS]: "Paramètres de l'Entreprise",
  [StringKey.COMPANY_SETUP]: "Configuration de l'entreprise",
  [StringKey.COMPANY_TYPE]: "Type d'entreprise",
  [StringKey.COMPANY_URL]: "URL de l'entreprise",
  [StringKey.COMPANY_URL_OPTIONAL]: "URL de l'entreprise (facultatif)",
  [StringKey.CONFIRM]: 'Confirmer',
  [StringKey.CONFIRM_PASSWORD]: 'Confirmez le mot de passe',
  [StringKey.MAXIMUM_FILE_SIZE]: 'Taille maximale du fichier {size} Mo',
  [StringKey.MAXIMUM_PHOTO_SIZE]: 'Taille maximale: {rules}',
  [StringKey.CARD_ENDING_NUMBERS]: '{brand} se terminant par {number}',
  [StringKey.LETS_TALK]: 'Parlons-en',
  [StringKey.LAST_UPDATE]: 'Dernière mise à jour : {date}',
  [StringKey.INDUSTRY]: 'Industrie',

  [StringKey.INVITE_USER]: 'Inviter un utilisateur',
  [StringKey.INVITED]: 'Invité',
  [StringKey.ACTIVE_STAKEHOLDERS]: 'Actif Stakeholders',
  [StringKey.INACTIVE_STAKEHOLDERS]: 'Inactif Stakeholders',
  [StringKey.ALL_TERMINATED]: 'Actionnaires avec rupture de contrat',
  [StringKey.ACTIVE_TERMINATED]: 'Active Terminated',
  [StringKey.INACTIVE_TERMINATED]: 'Inactive Terminated',
  [StringKey.STAKEHOLDERS_TAB]: 'Actionnaires',
  [StringKey.TERMINATED_TAB]: 'Rupture de contrat',
  [StringKey.FILTER_ALL]: 'Tous',
  [StringKey.FILTER_ACTIVE]: 'Actif',
  [StringKey.FILTER_INACTIVE]: 'Inactif',
  [StringKey.FILTER_IN_USE]: 'Utilisée',
  [StringKey.FILTER_NOT_USED]: 'Non utilisée',
  [StringKey.KILOBYTE]: 'Ko',
  [StringKey.MINUTES_AGO]: 'Il y a {date}',
  [StringKey.SINGULAR_MIN]: 'minute',
  [StringKey.PLURAL_MIN]: 'minutes',
  [StringKey.HOURS_AGO]: 'Il y a {date}',
  [StringKey.SINGULAR_HOUR]: 'heure',
  [StringKey.PLURAL_HOUR]: 'heures',
  [StringKey.DAYS_AGO]: 'Il y a {date}',
  [StringKey.SINGULAR_DAY]: 'jours',
  [StringKey.PLURAL_DAY]: 'jour',
  [StringKey.MONTHS_AGO]: 'Il y a {date}',
  [StringKey.SINGULAR_MONTH]: 'mois',
  [StringKey.PLURAL_MONTH]: 'mois',
  [StringKey.LESS_THAN_MIN_AGO]: "il y a moins d'une minute",
  [StringKey.ZIP_CODE]: 'Code postal',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_MESSAGE]: 'Votre abonnement sera renouvelé le {date}',
  [StringKey.CONGRATS_ON_YOUR_GROWTH]:
    "Félicitations pour votre croissance! Vous avez atteint votre limite d'actionnaires. Veuillez mettre à niveau votre abonnement pour continuer. ",
  [StringKey.CONTINUE]: 'Continuer',
  [StringKey.CONTINUE_TO_PLANS]: 'Continuer vers les abonnements',
  [StringKey.CONTINUE_TO_USE_CAPQUEST_UNTIL]:
    "Continuez à utiliser CapQuest jusqu'à l'expiration de votre abonnement actuel, le {date}.",
  [StringKey.CONTINUE_WITH_GOOGLE]: 'Continuer avec Google',
  [StringKey.CONTRIBUTOR]: 'Contributeur',
  [StringKey.COUNTRY_OF_COMPANY]: "Pays de l'entreprise",
  [StringKey.CREATE_MY_ACCOUNT]: 'Créer mon compte',
  [StringKey.CREATING_YOUR_ACCOUNT]: 'Création de votre compte ...',
  [StringKey.CURRENT_PLAN]: 'Abonnement actuel',
  [StringKey.CURRENTLY_SELECTED]: 'Actuellement sélectionné',
  [StringKey.DASHBOARD]: 'Tableau de bord',
  [StringKey.DATE_CREATED]: 'Date de création',
  [StringKey.DATE_OF_INCORPORATION]: 'Date de constitution',
  [StringKey.DATE_OF_INCORPORATION_OPTIONAL]: 'Date de constitution (facultatif)',
  [StringKey.DAY]: 'Jour',
  [StringKey.DEACTIVATE]: 'Désactiver',
  [StringKey.DEACTIVATE_COMPANY]: "Désactiver l'entreprise",
  [StringKey.DELETE]: 'Supprimer',
  [StringKey.DELETE_ACCOUNT]: 'Supprimer le compte',
  [StringKey.DELETE_STAKEHOLDER]:
    "Supprimer {count, plural, =1 {l'actionnaire} other {les actionnaires}} ",
  [StringKey.DELETE_YOUR_CAPQUEST_ACCOUNT]: 'Supprimer votre compte CapQuest',
  [StringKey.DEMO]: 'Démo',
  [StringKey.DETAILS]: 'Détails',
  [StringKey.DONT_HAVE_AN_ACCOUNT_YET]: "Vous n'avez pas encore de compte",
  [StringKey.DONT_WORRY_YOUR_DATA_IS_SAVED]:
    "Ne vous inquiétez pas, vos données sont sauvegardées chez nous. Vous pouvez réactiver l'abonnement pendant {timeDistance}.",
  [StringKey.DOWNGRADE]: "Changer l'abonnement",
  [StringKey.SWITCH_TO_ANNUAL_PLAN_CHARGE_MESSAGE]:
    'Votre méthode de facturation principale sera facturée {price} {currency}.',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_ACTIVE_MESSAGE]: 'Votre abonnement sera activé immédiatement',
  [StringKey.PAY_MONTHLY]: 'Payez mensuellement',
  [StringKey.COMPANY_SETUP_EXIT]: 'Votre progression sera perdue si vous quittez',
  [StringKey.VENTURE_CAPITAL]: 'Capital-risque',
  [StringKey.COMPANY]: 'Entreprise',
  [StringKey.STAKEHOLDER_HUMAN_GROUP]: 'Personnes physiques',
  [StringKey.MONTH]: 'Mois',
  [StringKey.MONTHS]: 'Mois',
  [StringKey.MONTHLY]: 'Mensuel',
  [StringKey.STAKEHOLDERS_LIMIT]:
    "Vous ne pouvez pas rétrograder votre abonnement car vous comptez actuellement plus de 25 actionnaires. Contactez l'assistance client.",
  [StringKey.YOURE_SAVE_APPROX_WITH_ANNUAL]: 'Vous économisez env. 17%',
  [StringKey.UPGRADE]: 'Mise à niveau',
  [StringKey.SWITCH_ANNUAL]: "Passer à l'annuel",
  [StringKey.SELECT]: 'Sélectionner',
  [StringKey.SPACIAL_PLAN_TEXT]: 'Contactez un agent',
  [StringKey.CANNOT_DOWNGRADE_STAKEHOLDERS]:
    'Vous ne pouvez pas déclasser votre plan parce que vous avez actuellement un plan annuel.',
  [StringKey.CANCEL_SUBSCRIPTION_PROBLEM_1]:
    'Vous et tous les actionnaires n’aurez plus accès à cette entreprise par la suite.',
  [StringKey.EXIT]: 'Oui, quitter',
  [StringKey.CONFIRM_DELETE]: 'Oui, supprimer',
  [StringKey.CONFIRM_DEACTIVATE]: 'Oui, désactiver',
  [StringKey.CONFIRM_CANCEL]: 'Oui, annuler',
  [StringKey.YEAR]: 'Année',
  [StringKey.YEARS]: 'Années',
  [StringKey.WHERERE_LOCATED]: 'Où êtes-vous situé?',
  [StringKey.WELCOME]: 'Bienvenue',
  [StringKey.WELCOME_TO_CAPQUEST]: 'Bienvenue sur CapQuest',
  [StringKey.WELCOME_BACK]: 'Content de vous revoir',
  [StringKey.WELCOME_ABOARD]: 'Bienvenue à bord',
  [StringKey.FEW_DETAILS]: 'Nous avons juste besoin de quelques détails pour commencer',
  [StringKey.SENDED_RECEIPT_TO_EMAIL]:
    'Nous vous avons envoyé le reçu sur votre adresse email affiliée',
  [StringKey.SENDED_LOGIN_VERIFY_LINK]:
    'Nous vous avons envoyé un lien de connexion sécurisé. Veuillez cliquer sur le lien pour authentifier votre compte',
  [StringKey.WANT_EXIT]: 'Vous souhaitez partir ?',
  [StringKey.VOTING_RIGHTS]: '% Droits de vote',
  [StringKey.VOTING_RIGHT]: 'Droit de vote',
  [StringKey.VIEW_MY_COMPANIES]: 'Voir mes entreprises',
  [StringKey.VIEW_MODIFICATION_GUID]: 'Voir le guide de modification',
  [StringKey.VIEW_IMPORT_GUID]: "Afficher le guide d'importation",
  [StringKey.VIEW_DETAILS]: 'Voir les détails',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART1]: 'Télécharger',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART2]: 'les informations',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART3]: "sur l'actionnaire",
  [StringKey.DOWNLOAD_TEMPLATE]: 'Télécharger le modèle',
  [StringKey.EDIT_DETAILS]: 'Modifier les détails',
  [StringKey.EDIT_STAKEHOLDER]: "Modifier l'actionnaire",
  [StringKey.EFFICIENTLY_HANDLE_MANAGEMENT]:
    'Gérez efficacement vos stock-options grâce à nos abonnement annuels avantageux.',
  [StringKey.EMAIL]: 'E-mail',
  [StringKey.EMAIL_ADDRESS]: 'Adresse e-mail',
  [StringKey.ENTER_A_NEW_PASSWORD_BELOW]:
    'Entrez un nouveau mot de passe ci-dessous pour modifier votre mot de passe actuel et pursuivez votre accès à CapQuest',
  [StringKey.ENTER_YOUR_EMAIL_ADDRESS_AND_WE_WILL]:
    'Entrez votre adresse email et nous vous enverrons un e-mail avec les instructions de réinitialisation du mot de passe',
  [StringKey.ENTERPRISE_AND_FI]: 'Entreprises et Institutions Financières',
  [StringKey.EQUITY_AND_CAPITAL_CHANGE]: 'Evolution de la valeur et détention du capital',
  [StringKey.EVENTS]: 'Événements',
  [StringKey.EVERYTHING_FROM_STARTER_PLAN]: 'Tous les bénéfices du plan Startup +',
  [StringKey.EXPIRY]: "Date d'expiration",
  [StringKey.EXPORT]: 'Exporter',
  [StringKey.FILE_FORMAT_NOT_SUPPORTED]: "Le format de fichier n'est pas pris en charge",
  [StringKey.FILE_PROCESSED_SUCCESSFULLY]: 'Fichier traité avec succès',
  [StringKey.FOR_CUSTOM_PRICING]: 'pour un prix personnalisé',
  [StringKey.FORGOT_PASSWORD]: 'Mot de passe oublié',
  [StringKey.FULL_NAME]: 'Nom et prénom',
  [StringKey.GENERATING_INVITATION_AGAIN_WILL_VOID]:
    'Générer une nouvelle invitation annulera l’invitation précédente.',
  [StringKey.GRANT_ACCESS]: "Accorder l'accès",
  [StringKey.HELP_ME_SETUP]: 'Aidez-moi à configurer',
  [StringKey.HELP_US_MAKE_CAPQUEST_BETTTER]:
    'Aidez-nous à améliorer CapQuest - dites-nous pourquoi vous avez annulé cet abonnement',
  [StringKey.HERE_ARE_A_FEW_THING_TO_TRY]: 'Voici quelques astuces que vous pouvez essayer',
  [StringKey.I_FOUND_BETTER_ALTERNATIVE]: "J'ai trouvé une meilleure alternative",
  [StringKey.I_HAVE_SEEN_MY_INFO]: "J'ai vu mes informations",
  [StringKey.I_NO_LONGER_NEED_IT]: "je n'en ai plus besoin",
  [StringKey.IF_YOU_CANCEL]: 'Si vous annulez',
  [StringKey.IF_YOU_RECEIVED_LINK_IN_EMAIL]:
    "Si vous avez reçu le lien dans un e-mail, essayez de contacter l'expéditeur pour en obtenir un nouveau.",
  [StringKey.IMPORT]: 'Importer',
  [StringKey.IMPORT_STAKEHOLDERS]: 'Importer les actionnaires',
  [StringKey.IN_THE_MEANTIME_FEEL_FREE_TO]: "En attendant, n'hésitez pas à parcourir notre",
  [StringKey.INACTIVE_COMPANY]: 'Entreprise désactivée',
  [StringKey.INCOMPLETE_SETUP]: 'Configuration incomplète',
  [StringKey.LAST_ACTIVE]: 'Dernière activité',
  [StringKey.LET_US_HELP_TO_SETUP]: 'Laissez-nous vous aider à configurer votre CapQuest',
  [StringKey.LET_US_KNOW_ABOUT_YOUR_COMPANY_DETAILS]:
    'Faites-nous part des données de votre entreprise',
  [StringKey.LOCATION]: 'Adresse',
  [StringKey.LOG_IN]: 'Se connecter',
  [StringKey.LOG_OUT]: 'Se déconnecter',
  [StringKey.LOSE_SPECIAL_OFFER_PRICE]: 'Perdez le prix de votre offre spéciale, le cas échéant.',
  [StringKey.MAKE_CONTRIBUTOR]: 'Rendre contributeur',
  [StringKey.MY_BUSINESS_IS_NO_LONGER_ACTIVE]: "Mon entreprise n'est plus active",
  [StringKey.MY_COMPANIES]: 'Mes entreprises',
  [StringKey.MY_COMPANY]: 'Mon Entreprise',
  [StringKey.MY_HOLDING_EQUITY]: 'Mes avoirs / mes capitaux propres',
  [StringKey.MY_HOLDINGS]: 'Mes avoirs',
  [StringKey.MY_PROFILE]: 'Mon profil',
  [StringKey.NAME]: 'Nom',
  [StringKey.NATIONALITY]: 'Nationalité',
  [StringKey.NATIONALITY_OPTIONAL]: 'Nationalité (facultatif)',
  [StringKey.NEW_PASSWORD]: 'Nouveau mot de passe',
  [StringKey.NEXT]: 'Suivant',
  [StringKey.VAT_GST]: 'Numéro de TVA/TPS',
  [StringKey.USER_ROLE]: "Rôle de l'utilisateur",
  [StringKey.MISC_ADVISE_3]:
    'Utilisez la barre de recherche du site pour trouver les informations que vous recherchez.',
  [StringKey.UPLOAD_AGAIN]: 'Uploader à nouveau',
  [StringKey.UPGRADE_PLAN_NOW]: 'Upgrader votre abonnement maintenant',
  [StringKey.UPDATE]: 'Mise à jour',
  [StringKey.UNABLE_DELETE]: 'Impossible de supprimer',
  [StringKey.TYPE]: 'Type',
  [StringKey.TERMINATION]: 'Rupture conventionnelle',
  [StringKey.TERMINATED]: 'Dissous',
  [StringKey.TERMINATE]: 'Rupture de contrat',
  [StringKey.SWITCH_TO_ANNUAL_BILLING]: 'Passer à la facturation annuelle',
  [StringKey.SWITCH_COMPANY]: "Changer d'entreprise",
  [StringKey.SUPPORTED_FORMATS]: 'Formats pris en charge : {formats}',
  [StringKey.SUBSCRIPTION_CYCLE]: "Cycle d'abonnement",
  [StringKey.SUBSCRIPTION_CANCELED]: 'Abonnement annulé',
  [StringKey.SUBMIT]: 'Soumettre',
  [StringKey.STATUS]: 'Statut',
  [StringKey.STATE]: 'État',
  [StringKey.STAKEHOLDERS]: 'Actionnaires',
  [StringKey.STAKEHOLDER_UPDATE_TOAST]: 'Actionnaire mis à jour avec succès',
  [StringKey.STAKEHOLDER_TYPE]: "Type d'actionnaire",
  [StringKey.STAKEHOLDER_DELETED_TOAST]: 'Supprimé avec succès',
  [StringKey.STAKEHOLDER_CREATED_TOAST]: 'Actionnaire créé avec succès',
  [StringKey.SOMETHING_ELSE]: 'Autre raison',
  [StringKey.SKYROCKETING]: 'Bravo pour votre croissance fulgurante !',
  [StringKey.SKIP]: 'Ignorer',
  [StringKey.SIGN_UP]: "S'inscrire",
  [StringKey.SIGN_UP_TO_CAPQUEST]: 'Connectez-vous à votre compte CapQuest',
  [StringKey.SIGN_IN_TO_CAPQEUST]: 'Connectez-vous à votre compte CapQuest',
  [StringKey.SIGN_IN]: 'Se connecter',
  [StringKey.SHARE_CLASSES]: "Classes d'actions",
  [StringKey.POOL_PLANS_POOL]: 'Détails de la piscine',
  [StringKey.POOL_PLANS_PLAN]: 'Détails du plan',
  [StringKey.POOL_PLANS]: 'Pool et Plans',
  [StringKey.SETTINGS]: 'Paramètres',
  [StringKey.ACCEPT_INVITATION]: "Accepter l'invitation",
  [StringKey.LOGIN]: 'Login',
  [StringKey.REGISTER]: 'Registre',
  [StringKey.REGISTER_VERIFY]: 'Register Verify',
  [StringKey.PASSWORD_RECOVERY]: 'Récupération du mot de passe',
  [StringKey.BILLING]: 'Facturation',
  [StringKey.ERROR]: 'Erreur',
  [StringKey.SEND_EMAIL]: 'Envoyer un e-mail',
  [StringKey.SEARCH]: 'Recherche',
  [StringKey.SAVED_APPROX_V2]: 'Économisé env. 17%',
  [StringKey.SAVE_APPROX_V2]: 'Économisé env. 17%',
  [StringKey.SAVE_APPROX_W_ANNUAL_BILLING]: 'Économisez env. 17% avec une facturation annuelle',
  [StringKey.SAVE]: 'Sauvegarder',
  [StringKey.REVOKE_CONTRIBUTOR_ROLE]: 'Révoquer le rôle de contributeur',
  [StringKey.REVOKE_ACCESS]: 'Accès révoqué',
  [StringKey.RESUME_COMPANY_SETUP]: 'Reprendre la création de votre entreprise',
  [StringKey.RESUME]: 'CV',
  [StringKey.RESIGNATION]: 'Démission',
  [StringKey.RESEND_VERIFICATION_EMAIL]: "Renvoyer l'e-mail de vérification",
  [StringKey.RESEND_INVITATION]: "Renvoyer l'invitation",
  [StringKey.RESEND_EMAIL]: "Ré-envoyer l'email",
  [StringKey.NO]: 'Non',
  [StringKey.NO_OF_EMPLOYEES]: "Nombre d'employés",
  [StringKey.NO_RESULTS_MATCHS]: 'Aucun résultat ne correspond à votre recherche',
  [StringKey.NO_REV]: 'Pas de rév.',
  [StringKey.NO_THANKS]: 'Non merci',
  [StringKey.NOTE_THAT_YOUR_INFORMATION_STILL_SAVED]:
    "Veuillez noter qu'en tant qu'actionnaire vos informations seront encore enregistrées et visibles par les administrateurs et les contributeurs.",
  [StringKey.OOPS]: 'Oups!',
  [StringKey.OR]: 'OU',
  [StringKey.PASSPORT_EXPIRY_DATE]: "Date d'expiration du passeport",
  [StringKey.PASSPORT_EXPIRY_DATE_OPTIONAL]: "Date d'expiration du passeport (facultatif)",
  [StringKey.PASSPORT_NO]: 'No de passeport',
  [StringKey.PASSPORT_NO_OPTIONAL]: 'No de passeport (facultatif)',
  [StringKey.PASSWORD]: 'Mot de passe',
  [StringKey.PASSWORD_CHANGED]: 'Mot de passe modifié',
  [StringKey.PAY_NOW]: 'Payez maintenant',
  [StringKey.PAY_YEARLY]: 'Payer annuellement',
  [StringKey.PAYMENT_METHOD]: 'Mode de paiement',
  [StringKey.PER_MONTH]: 'Par mois',
  [StringKey.PERSONAL_INFO]: 'Informations personnelles',
  [StringKey.PHONE_NUMBER]: 'Numéro de téléphone',
  [StringKey.PHONE_NUMBER_OPTIONAL]: 'Numéro de téléphone (facultatif)',
  [StringKey.PLAN_BILLING]: 'Abonnements et facturations',
  [StringKey.BILLING_HISTORY]: 'Billing History',
  [StringKey.PLAN_FOR_ALL_SIZES]: 'Abonnements adaptés à la taille de votre Entreprise',
  [StringKey.PLAN_SELECTED]: 'Abonnement sélectionné',
  [StringKey.PLEASE_CHECK_THE_EMAIL]:
    "Veuillez vérifier l'e-mail envoyé à l'adresse e-mail {email} pour obtenir les instructions pour réinitialiser votre mot de passe.",
  [StringKey.PLEASE_TRY_AGAIN]: 'Veuillez réessayer',
  [StringKey.POOLS_PLANS]: 'Pool et Plans',
  [StringKey.PREVIOUS]: 'Précédent',
  [StringKey.REACTIVATE]: 'Réactiver',
  [StringKey.REACTIVATE_SUBSCRIPTION]: "Réactiver l'abonnement",
  [StringKey.REENTER_NEW_PASSWORD]: 'ré-entrez le nouveau mot de passe',
  [StringKey.REGISTRATION_NUMBER_OPTIONAL]: "Numéro d'enregistrement (facultatif)",
  [StringKey.REPORTING_CURRENCY]: 'Devise de la société',
  [StringKey.YES]: 'Oui',
  [StringKey.YES_CANCEL]: 'Oui, annuler',
  [StringKey.YES_DEACTIVATE]: 'Oui, désactiver',
  [StringKey.YES_DELETE]: 'Oui, supprimer',
  [StringKey.YES_EXIT]: 'Oui, quitter',
  [StringKey.YES_GRANT]: 'Oui, accorder',
  [StringKey.YES_RESEND]: 'Oui, renvoyer',
  [StringKey.YES_REVOKE]: 'Oui, révoquer',
  [StringKey.YES_UPDATE]: 'Oui, mettre à jour',
  [StringKey.YES_TERMINATE]: 'Oui, Terminer',
  [StringKey.UPDATE_STAKEHOLDER]: 'Mise à jour de la rupture de contrat',
  [StringKey.UPDATE_TERMINATION]: 'Mise à jour de la rupture de contrat',
  [StringKey.SIGN_UP_MESSAGE]:
    "Merci d'être un pionnier de CapQuest. Pour commencer à profiter des avantages, configurons votre compte.",
  [StringKey.COMPANY_ID_EXPLANATION]:
    "Le numéro d'identification de l'entreprise peut être tout identifiant unique généralement utilisé pour une entreprise dans les contrats, les subventions et la facturation (par exemple pour les entreprises américaines, il peut s'agir d'un numéro d'identification de l'employeur (EIN), et pour les entreprises française il peut s'agir du numéro de SIRET.)",
  [StringKey.UPDATE_INFORMATION_MESSAGE]:
    'Les informations que vous avez modifiées seront mises à jour et enregistrées.',
  [StringKey.INCORRECT_LINK_PT_1]:
    'Le lien sur lequel vous avez cliqué est peut-être obsolète ou incorrect.',
  [StringKey.INCORRECT_LINK_PT_2]: 'Ne vous inquiétez pas, ce sont des choses qui arrivent !',
  [StringKey.NOT_ABLE_TO_USE_STAKEHOLDER]:
    "L'actionnaire sélectionné ne pourra pas utiliser le CapQuest.",
  [StringKey.STAKEHOLDER_CANCEL_INVITE_DESCRIPTION]:
    "L'actionnaire ne pourra peut-être pas rejoindre votre équipe sur CapQuest.",
  [StringKey.GREETING_COMPANY_SETUP]:
    'Pour commencer, ajoutez vos actionnaires et invitez des personnes de votre entreprise à rejoindre votre équipe',
  [StringKey.PASSWORD_RECOMMENDATION]: 'Votre mot de passe doit contenir :',
  [StringKey.PASSWORD_SUCCESSFULLY_CHANGED]: 'Votre mot de passe a été changé avec succès',
  [StringKey.TERMINATE_STAKEHOLDER]: 'Rupture de contrat des actionnaire',
  [StringKey.STAKEHOLDER_SUCCESSFULLY_CREATED]: 'Actionnaire supprimé avec succès',
  [StringKey.FRENCH]: 'Français',
  [StringKey.ENGLISH]: 'English',
  [StringKey.NO_KEEP]: 'Non, garde-le',
  [StringKey.CONTACT_SUPPORT]: "Contactez l'assistance client",
  [StringKey.WEBSITE]: 'Site web',
  [StringKey.CAP_TABLE]: 'Cap Table',
  [StringKey.OVERVIEW]: 'Aperçu',
  [StringKey.LATEST_CAP_TABLE]: 'Cap Table actuelle',
  [StringKey.OVERALL_STATUS]: 'Récapitulatif',
  [StringKey.OWNERSHIP_PERCENTAGE]: 'Distribution du capital (%)',
  [StringKey.VOTING_RIGHTS_PERCENTAGE]: 'Droits de vote (%)',
  [StringKey.SWITCH_TO_VOTING_RIGHTS]: 'Voir les droits de vote',
  [StringKey.SWITCH_TO_OWNERSHIP]: 'Voir la distribution du capital',
  [StringKey.POST_MONEY_VALUATION]: 'Valorisation Postmoney',
  [StringKey.VALUATION_DATE]: 'Date de la valorisation',
  [StringKey.ISSUED_SHARES]: 'Actions émises',
  [StringKey.LIFETIME_EQUITY_INVESTMENT]: 'Total investissements',
  [StringKey.INVESTORS]: 'Investisseurs',
  [StringKey.DILUTED_SHARES]: 'Actions pleinement diluées',
  [StringKey.BY_STAKEHOLDER]: 'Par actionnaire',
  [StringKey.BY_SHARE_CLASS]: "Par classe d'actions",
  [StringKey.CUSTOMIZE_VIEW]: "Personnaliser l'affichage",
  [StringKey.EQUITY_POOLS_GRANTABLE]: 'Pool dispo pour attribution',
  [StringKey.POOLS]: 'Pools',
  [StringKey.PLANS]: 'Plans',
  [StringKey.ISSUED_PERCENTAGE]: 'Émis %',
  [StringKey.DILUTED_PERCENTAGE]: 'Base Diluée %',
  [StringKey.VOTING_PERCENTAGE]: '% de vote',
  [StringKey.NO_ISSUED]: 'Nbre émises',
  [StringKey.INVESTMENT]: 'Investissement',
  [StringKey.AVERAGE_SHARE_PRICE]: "Prix moyen de l'action",
  [StringKey.SWITCH]: 'Changer la vue',
  [StringKey.VIEW_POSITIONS]: 'Voir les détentions',
  [StringKey.POSITIONS]: 'Détentions de capital',
  [StringKey.VIEW_ASSOCIATED_STAKEHOLDERS]:
    'Afficher les {stakeholdersCount} actionnaires associés',
  [StringKey.CUSTOM_DATE]: 'Date personnalisée',
  [StringKey.RESET]: 'Réinitialiser',
  [StringKey.BY]: 'Par',
  [StringKey.SHARE_CLASS]: "Classe d'actions",
  [StringKey.VIEW_BY]: 'Afficher par',
  [StringKey.COLUMNS]: 'Colonnes',
  [StringKey.OTHER_SHARE_CLASSES]: "Autres classes d'actions",
  [StringKey.SET_AS_DEFAULT_VIEW]: 'Définir comme vue par défaut',
  [StringKey.STAKEHOLDER_NAME]: "Nom de l'actionnaire",
  [StringKey.COMMON_STOCK]: 'Actions ordinaires',
  [StringKey.SHARE_CLASS_NAME]: "Nom de la classe d'actions",
  [StringKey.NO_OF_STAKEHOLDERS]: "No. d'actionnaires",
  [StringKey.SELECT_AT_LEAST_ONE_VIEW_BY]: 'Sélectionné au moins un View By',
  [StringKey.EMPTY]: ' ',
  [StringKey.CURRENT_SHARE_PRICE]: `Prix actuel de l'action`,
  [StringKey.VALUE_AT_LATEST_VALUATION_EVENT]: 'Valeur à la dernière valorisation',
  [StringKey.ADD_SHARE_CLASS]: `Ajouter une classe d'actions`,
  [StringKey.ADD_NEW_SHARE_CLASS]: `Ajouter une nouvelle classe d'actions`,
  [StringKey.DIVIDENDS]: 'Dividendes',
  [StringKey.DIVIDEND_RIGHT]: 'Droit aux dividendes',
  [StringKey.ANTI_DILUTION]: 'Anti - Dilution',
  [StringKey.CONVERSION_RATIO]: 'Taux de conversion',
  [StringKey.CONVERSION_RATIO_X]: 'Taux de conversion (x)',
  [StringKey.CONVERSION_RATIO_X_OPTIONAL]: 'Taux de conversion (x) (facultatif)',
  [StringKey.SENIORITY]: 'Priorité',
  [StringKey.LIQUIDITY_PREF]: 'Préf. de liquidité',
  [StringKey.LIQUIDITY]: 'Liquidité',
  [StringKey.LIQUIDITY_PREFERENCES]: 'Droit de liquidation préférentielle',
  [StringKey.PARTICIPATING_CAP]: 'Participation au boni de liquidation',
  [StringKey.PARTICIPATING]: 'Participation au boni de liquidation',
  [StringKey.MULTIPLY]: 'Multiple',
  [StringKey.MULTIPLY_VALUE]: 'Multiple',
  [StringKey.INTEREST]: 'Intérêt',
  [StringKey.DOC]: 'Fichier',
  [StringKey.DOCUMENT]: 'Fichier',
  [StringKey.CREATION_DATE]: 'Date de création',
  [StringKey.RIGHTS]: 'Droits',
  [StringKey.EDIT]: 'Modifier',
  [StringKey.EDIT_SHARE_CLASS]: `Modifier une classe d'actions`,
  [StringKey.GENERAL]: 'Général',
  [StringKey.HOW_TO_NAME_A_SHARE_CLASS]: 'Comment nommer une classe d’actions ?',
  [StringKey.WHAT_ARE_DIVIDEND_RIGHTS]: 'Que sont les droits aux dividendes ?',
  [StringKey.WHAT_ARE_VOTING_RIGHTS]: 'Que sont les droits de vote ?',
  [StringKey.WHAT_IS_CONVERSION_RATIO]: `Qu'est-ce que le taux de conversion ?`,
  [StringKey.CAP_VALUE]: 'Valeur plafond (cap)',
  [StringKey.YEARLY_INTEREST]: 'Intérêt annuel',
  [StringKey.NON_COMPOUNDING]: 'Simple',
  [StringKey.DAYS_PER_YEAR]: 'Jours par an',
  [StringKey.WHAT_ARE_LIQUIDITY_PREFERENCES]: 'Que sont les droits de liquidation préférentielle?',
  [StringKey.WHAT_IS_SENIORITY]: 'Qu’est-ce que la priorité?',
  [StringKey.WHAT_IS_MULTIPLY]: 'Qu’est-ce que le multiple?',
  [StringKey.WHAT_IS_PARTICIPATING]: 'Qu’est-ce que la participation au boni de liquidation?',
  [StringKey.WHAT_IS_CAP_VALUE]: 'Qu’est-ce que la valeur plafond (cap)?',
  [StringKey.ANTI_DILUTION_RIGHTS]: `Droits d'anti-dilution`,
  [StringKey.WHAT_IS_ANTI_DILUTION]: `Qu'est-ce que l'anti-dilution ?`,
  [StringKey.BASE]: 'Base du calcul',
  [StringKey.BROAD_BASED_WEIGHTED_AVERAGE]: 'Moyenne pondérée générale',
  [StringKey.FULL_RATCHET]: 'Full Ratchet',
  [StringKey.NARROW_BASED_WEIGHTED_AVERAGE]: 'Moyenne pondérée à base étroite',
  [StringKey.BOARD_RESOLUTION]: `Résolution du conseil d'administration`,
  [StringKey.NO_FILE_ATTACHED]: 'Aucun fichier joint',
  [StringKey.SHARE_CLASS_CREATED]: `Classe d'actions créée avec succès`,
  [StringKey.SHARE_CLASS_UPDATED]: `Classe d'actions mise à jour avec succès`,
  [StringKey.OWNERSHIP_SUMMARY]: 'Récapitulatif',
  [StringKey.YOUR_CONSOLIDATED_POSITION]: 'Vos détentions',
  [StringKey.NUMBER_OF_ISSUED_SHARES]: `Nbre d'actions émises détenues`,
  [StringKey.COMPANY_OWNED]: '% du capital détenu',
  [StringKey.VOTING]: 'Droit de vote (%)',
  [StringKey.SHARES_PRICE]: `Prix de l'action`,
  [StringKey.VALUE_OF_SHARES]: 'Valeur du capital détenu',
  [StringKey.ROI]: 'RSI',
  [StringKey.YOUR_POSITION_ISSUED]: 'Votre position au capital vs Cap Table',
  [StringKey.YOU]: 'Vous',
  [StringKey.YOUR_ESTIMATED_VALUE_MILLION]: 'Votre valeur estimée (millions)',
  [StringKey.YOUR_EQUITY]: 'Votre détention du capital',
  [StringKey.AUTOMATIC_VESTING]: 'Acquisition automatique',
  [StringKey.VESTING_SCHEDULE_AND_PLAN]: `Calendrier de la période d'indisponibilité (vesting)`,
  [StringKey.ABOUT_PLAN]: `Détails du plan d'attribution`,
  [StringKey.PLAN]: 'Plan',
  [StringKey.PLAN_TYPE]: 'Type de plan',
  [StringKey.YOUR_VESTING_STRUCTURE]: `Votre structure d'acquisition (vesting)`,
  [StringKey.GRANT_ID]: `Identifiant d'attribution`,
  [StringKey.CURRENT_PRICE]: 'Prix ​​actuel',
  [StringKey.STRIKE_PRICE]: `Prix d'exercice`,
  [StringKey.GRANTED]: 'Attribué',
  [StringKey.REMAINING]: 'Restant',
  [StringKey.VESTED]: 'Acquis',
  [StringKey.NEXT_VESTING]: 'Prochaine acquisition',
  [StringKey.FULL_VESTING]: 'Acquisition complète',
  [StringKey.EXERCISE]: 'Exercice',
  [StringKey.INVESTMENT_SUMMARY]: 'Résumé des investissements',
  [StringKey.TOTAL_ROUNDS]: 'Nbre total de rounds',
  [StringKey.CURRENT_VALUATION]: 'Valorisation actuelle',
  [StringKey.TOTAL_RAISED_AMOUNT]: 'Total capital levé',
  [StringKey.AVG_VALUATION_CHANGE]: 'Variation de valorisation moy.',
  [StringKey.PER_ROUND]: 'Par round',
  [StringKey.LATEST_SHARE_PRICE]: `Dernier prix de l'action`,
  [StringKey.YOUR_PARTICIPATION]: 'Votre participation',
  [StringKey.TOTAL_INVESTMENT]: 'Investissement total',
  [StringKey.ESTIMATED_VALUE]: 'Valeur actuelle estimée',
  [StringKey.OVERALL_EQUITY_CHANGE]: 'Variation de la detention du capital moy.',
  [StringKey.AVERAGE_INVESTMENT]: 'Investissement moyen',
  [StringKey.TOTAL_RAISED]: 'Total Levé',
  [StringKey.YOUR_EQUITY_CHANGE]: 'Votre variation de détention du capital',
  [StringKey.HURDLE]: 'Hurdle',
  [StringKey.SPV]: 'SPV',
  [StringKey.OTHER]: 'Other financial institutions',
  [StringKey.STAKEHOLDER_COMPANY_GROUP]: 'Legal entities',
  [StringKey.ALL_DONE]: 'All done',
  [StringKey.HANG_TIGHT]: 'Hang tight',
  [StringKey.ONBOARDING]: 'Onboarding',
  [StringKey.TOTAL]: 'Total',
  [StringKey.BASE_PRICE]: 'Prix ​​de base',
  [StringKey.VESTING_PLAN]:
    "Acquisition à exercée - Calendrier de la période d'indisponibilité (vesting)",
  [StringKey.EXERCISABLE]: 'Exerçable',
  [StringKey.EXERCISED]: 'Exercé',
  [StringKey.TO_BE_VESTED]: 'Indisponible',
  [StringKey.WARRANT_PRICE]: "Prix ​​d'exercice",
  [StringKey.RSA_PRICE]: "Prix ​​d'exercice",
  [StringKey.NOTHING_HERE]: "Pas d'informations disponibles !",
  [StringKey.HOLDINGS_INFO]:
    "Lorsqu'elles seront disponibles, les informations sur vos détentions seront affichées ici.",
  [StringKey.REQUEST_EXERCISE]: "Demande d'exercice",
  [StringKey.STOCK_OPTIONS]: 'Stock Options',
  [StringKey.WARRANT]: 'Bons de souscriptions (Warrant)',
  [StringKey.RSA]: 'Restricted Stock Awards (RSA)',
  [StringKey.GRANT]: "Identifiant d'attribution",
  [StringKey.GROWTH_HURDLE]: 'Plan Croissance / Hurdle',
  [StringKey.SAR]: 'Droit à la plus-value des actions (SAR)',
  [StringKey.RSU]: `Unite d'actions restreintes (RSU)`,
  [StringKey.PHANTOM]: 'Actions Fantômes (Phantom)',
  [StringKey.BSPCE]: 'BSPCE',
  [StringKey.REQUEST_DATE]: 'Date de la demande',
  [StringKey.TOTAL_COST]: 'Cout total :',
  [StringKey.AVAILABLE]: 'Disponible :',
  [StringKey.PRIVATE_EQUITY]: 'Private Equity',
  [StringKey.TOP_5_STAKEHOLDERS]: 'Top 5 des actionnaires',
  [StringKey.POOL_ALLOCATION]: 'Allocation du pool',
  [StringKey.VALUATION_HISTORY]: 'Historique de la valorisation',
  [StringKey.CURRENCY_USD]: 'Devise : USD (millions)',
  [StringKey.FULLY_DILUTED]: 'Entièrement dilué',
  [StringKey.SHAREHOLDER]: 'Actionnaire',
  [StringKey.SHARES]: 'Actions',
  [StringKey.VALUE]: 'Valeur',
  [StringKey.ACTIVITY_CENTER]: 'Centre de notifications',
  [StringKey.UNREAD_ONLY]: 'Filtrer par non lu',
  [StringKey.MARK_ALL_AS_READ]: 'Marquer tout comme lu',
  [StringKey.GETTING_STARTED]: 'Guide de configuration',
  [StringKey.REQUESTS]: 'Demandes',
  [StringKey.RESOLVED]: 'Résolues',
  [StringKey.STAKEHOLDER_ADDED]: 'Actionnaire ajouté',
  [StringKey.INVITATION_ACCEPTED]: 'Invitation acceptée',
  [StringKey.STAKEHOLDER_TERMINATED]: 'Actionnaire licencié',
  [StringKey.ACCEPTED_INVITATION]: 'Invitation acceptée',
  [StringKey.TODAY]: "Aujourd'hui",
  [StringKey.READ_NOTIFICATIONS_DELETED]:
    'Les notifications lues dans le Centre de Notifications sont supprimées après 90 jours',
  [StringKey.MARK_AS_READ]: 'Marquer comme lu',
  [StringKey.EXERCISE_REQUESTED]: 'Exercice demandé',
  [StringKey.HURDLE_APPROVAL]: 'Approbation du plan Hurdle',
  [StringKey.MARK_AS_RESOLVED]: 'Marquer comme résolu',
  [StringKey.GRANT_EXERCISED]: 'Attribution exercée',
  [StringKey.MARKED_AS_RESOLVED]: 'Marqué comme résolu',
  [StringKey.HURDLE_APPROVED]: 'Plan Hurdle approuvé',
  [StringKey.NO_NOTIFICATIONS]: 'Aucune notification !',
  [StringKey.GET_STARTED]: 'Guide de configuration',
  [StringKey.ADD_POOL]: 'Ajouter un pool',
  [StringKey.ADD_PLAN]: 'Ajouter un plan',
  [StringKey.ADD_EVENT]: 'Ajouter un événement',
  [StringKey.ALL_CAUGHT_UP]: 'Vous avez tout lu !',
  [StringKey.SHOW_ALL]: 'Tout afficher',
  [StringKey.EXERCISE_STOCK_OPTIONS]: 'Exercice des Stock Options',
  [StringKey.EXERCISE_EXPIRE_WARRANTS]: 'Exercice / expiration des Warrants',
  [StringKey.EXERCISED_WARRANTS]: 'Warrants exercés',
  [StringKey.EXERCISE_RSA]: 'Exercice des RSA',
  [StringKey.EXERCISE_SAR]: "Exercice des SAR's",
  [StringKey.RSA_PURCHASE_PRICE]: "Prix ​​d'achat RSA",
  [StringKey.ADDITIONAL_NOTES]: 'Notes additionnelles (facultatif)',
  [StringKey.DOCUMENTS_UPLOAD]: 'Uploader des fichiers',
  [StringKey.APPROVE]: 'Approuver',
  [StringKey.SUCCESSFUL_COMPANY_SETUP]: 'Vous avez créé avec succès votre entreprise',
  [StringKey.ADD_STAKEHOLDER_MESSAGE]:
    'Ajoutez des détails sur les actionnaires de votre entreprise',
  [StringKey.ADD_SHARE_CLASS_MESSAGE]:
    "Ajoutez des détails sur les classes d'actions de votre entreprise",
  [StringKey.ADD_POOL_MESSAGE]: 'Configurez votre premier pool',
  [StringKey.ADD_PLAN_MESSAGE]: 'Configurez votre premier plan',
  [StringKey.ADD_EVENT_MESSAGE]: 'Ajouter des événements nouveaux ou existants',
  [StringKey.APPROVE_HURDLE_PLAN]: 'Approve Hurdle Plan',
  [StringKey.PAYOUT_PHANTOM_SHARES]: 'Payout Phantom Shares',
  [StringKey.EXERCISE_BSPCE]: 'Exercise BSPCE',
  [StringKey.CAP_TABLE_DETAILS]:
    "Lorsqu'elles seront disponibles, les informations détaillées de votre cap table seront affichées ici.",
  [StringKey.DATE]: 'Date',
  [StringKey.LOADING]: 'Chargement...',
  [StringKey.NO_GRANTS_FOUND]: 'Aucune attribution trouvée',
  [StringKey.NO_SHAREHOLDER_FOUND]: 'Aucun actionnaire trouvé',
  [StringKey.CONVERSION]: 'Conversion',
  [StringKey.EXERCISED_OPTIONS]: 'Options exercées',
  [StringKey.EXERCISE_DATE]: "Date d'exercice",
  [StringKey.OTHERS]: 'Autres',
  [StringKey.INTEREST_PERCENTAGE_PROMPT]: "Veuillez saisir le taux d'intérêt",
  [StringKey.UPLOADED_PERCENTAGE]: '% téléchargé...',
  [StringKey.INVALID_FILE_TYPE]:
    "Le type {fileType} n'est pas valide. Veuillez sélectionner un format de fichier pris en charge.",
  [StringKey.VOTING_MULTIPLIER]: 'Multiple du droit de vote',
  [StringKey.PRE_EMPTIVE_RIGHTS]: 'Droits de préemption',
  [StringKey.DELETE_SHARE_CLASS]: 'Supprimer la classe d’actions ?',
  [StringKey.SHARE_CLASS_DATA_LOSS_WARNING]:
    'Toutes les données associées à la classe d’actions seront perdues.',
  [StringKey.UPDATE_SHARE_CLASS]: 'Mettre à jour la classe d’actions ?',
  [StringKey.UPDATE_SHARE_CLASS_CONFIRM]:
    'Etes-vous sûr de vouloir mettre à jour cette classe d’actions ?',
  [StringKey.REVIEW_CHANGES_WARNING]:
    'Veuillez examiner attentivement vos modifications. La modification de la {text} peut avoir des implications significatives pour tous les actionnaires associés et les allocations futures.',
  [StringKey.BY_POOLS]: 'Par Pools',
  [StringKey.VEST_BY]: 'Acquisition par :',
  [StringKey.ADD]: 'Ajouter',
  [StringKey.POOL_GRANTED]: 'Pool Accordé',
  [StringKey.TOTAL_GRANT_VESTED]: 'Total Accordé Acquis',
  [StringKey.SHARES_VESTED]: 'Actions Vestées',
  [StringKey.CASH_SETTLED]: 'Réglé en espèces',
  [StringKey.RETURNED_TO_POOL]: 'Remis dans le pool',
  [StringKey.EXPIRED]: 'Expiré',
  [StringKey.TERMINATION_SET]: 'Résiliation définie :',
  [StringKey.EDIT_TERMINATION_DATE]: 'Modifier la date de dissolution',
  [StringKey.POOL_NAME]: 'Nom du Pool',
  [StringKey.FULLY_DILUTED_SHARES]: "Nbre d'actions entièrement diluées",
  [StringKey.DILUTION_CONDITION]: 'Condition de Dilution',
  [StringKey.DOCUMENTS]: 'Fichiers',
  [StringKey.ADDITIONAL_NOTES_OPTIONAL]: 'Notes additionnelles (facultatif)',
  [StringKey.TERMINATE_POOL]: 'Dissoudre le pool',
  [StringKey.CURRENT_TERMINATION_DATE]: 'Date de dissolution actuelle',
  [StringKey.NEW_TERMINATION_DATE]: 'Nouvelle date de dissolution',
  [StringKey.POOL_TERMINATION_WORKS]: 'Comment fonctionne la dissolution du pool ?',
  [StringKey.TERMINATE_POOL_CONFIRMATION]: 'Êtes-vous sûr de vouloir mettre fin au ',
  [StringKey.IF_YOU_TERMINATE]: 'Si vous dissolvez',
  [StringKey.TERMINATION_WARNING]:
    'Vous et toutes les parties prenantes n’aurez plus accès à ce {text}.',
  [StringKey.POOL_NO_LINK_AFTER_TERMINATION]:
    'Vous ne pourrez plus lier de plans à ce pool après la date de résiliation',
  [StringKey.PLAN_NO_GRANTS_AFTER_TERMINATION]: `Vous ne pourrez pas faire d'attribution de ce plan après la date de dissolution.`,
  [StringKey.AVAILABLE_POOL]: 'Pool Disponible',
  [StringKey.NEW_POOL]: 'Nouveau Pool',
  [StringKey.NEW_PLAN]: 'Nouveau Plan',
  [StringKey.VIEW_POOL]: 'Voir le {text}',
  [StringKey.EDIT_POOL]: 'Modifier le {text}',
  [StringKey.DELETE_POOL]: 'Supprimer le {text}',
  [StringKey.DELETE_POOL_WARNING]:
    'Le pool ne peut pas être supprimé car il est associé à des plans.',
  [StringKey.DELETE_PLAN_WARNING]: 'Toutes les données associées au {text} seront perdues.',
  [StringKey.ADD_NEW_POOL]: 'Ajouter un Nouveau Pool',
  [StringKey.SELECT_POOL]: 'Associez le pool',
  [StringKey.SELECT_SHARE_CLASS]: `Sélectionnez la classe d'actions`,
  [StringKey.INCLUDE_CAP_TABLE]: `Inclure dans la cap table`,
  [StringKey.EXCLUDE_CAP_TABLE]: `Exclure de la cap table`,
  [StringKey.AMOUNT_FULLY_DILUTED_SHARES_HELP]:
    "Qu’est-ce que le « Nbre d'actions entièrement diluées » ?",
  [StringKey.DILUTION_CONDITIONS_HELP]: 'Quelles sont les conditions de dilution ?',
  [StringKey.POOL_CREATED_SUCCESS]: 'Nouveau pool créé avec succès',
  [StringKey.ADD_NEW_PLAN]: 'Ajouter un nouveau plan',
  [StringKey.PLAN_NAME]: 'Nom du Plan',
  [StringKey.EQUITY_PLAN_TYPE]: 'Type de plan',
  [StringKey.VALUATION_REQUIRED]: 'Évaluation requise',
  [StringKey.WHAT_IS_HURDLE]: `Qu'est ce qu'un plan Hurdle ?`,
  [StringKey.VESTING]: `Vesting (période d'indisponibilité)`,
  [StringKey.TIME_BASED_STANDARD]: 'Vesting progressif (Standard)',
  [StringKey.TIME_BASED_DYNAMIC]: 'Vesting progressif (Dynamique)',
  [StringKey.TARGET_BASED]: 'Vesting sur performance',
  [StringKey.TIME_BASED]: 'Vesting progressif',
  [StringKey.STANDARD]: 'Standard',
  [StringKey.DYNAMIC]: 'Dynamique',
  [StringKey.VESTING_ON]: 'Vesting le',
  [StringKey.FIRST_DAY_OF_MONTH]: 'Premier jour du mois',
  [StringKey.LAST_DAY_OF_MONTH]: 'Dernier jour du mois',
  [StringKey.GRANT_DATE]: `Jour de l'attribution`,
  [StringKey.DURATION]: 'Durée',
  [StringKey.VESTING_FREQUENCY]: 'Fréquence de vesting',
  [StringKey.CLIFF]: 'Cliff',
  [StringKey.ACCELERATION_DATE]: 'Date d’Accélération',
  [StringKey.VESTING_FREQUENCY_ABBR]: 'Fréq. de vesting',
  [StringKey.PLAN_ALLOCATION]: 'Attribution du plan',
  [StringKey.ADD_PERIOD]: 'Ajouter une Période',
  [StringKey.MILESTONE_NAME]: 'Objectif',
  [StringKey.WEIGHT]: 'Attribution',
  [StringKey.TARGET_DATE]: 'Échéance prévue',
  [StringKey.ADD_MILESTONE]: 'Ajouter un objectif',
  [StringKey.TERMINATION_LOGIC]: 'Conditions de départ',
  [StringKey.NO_POOLS_FOUND]: 'Aucun Pool Trouvé',
  [StringKey.TERMINATE_PLAN]: 'Dissoudre le plan',
  [StringKey.PLAN_TERMINATION_WORKS]: 'Comment fonctionne la dissolution du plan ?',
  [StringKey.ADD_EXERCISING]: 'Ajouter un Exercice',
  [StringKey.PLAN_EXERCISE_COMPLETED]: 'Exercice du Plan Terminé',
  [StringKey.EXPIRED_WARRANTS]: 'Bons Expirés',
  [StringKey.PHANTOM_SHARES]: 'Actions Fantômes',
  [StringKey.TOTAL_PAYOUT]: 'Paiement Total',
  [StringKey.PURCHASE_PRICE]: 'Prix d’Achat',
  [StringKey.SELECT_HURDLE_PLAN]: 'Sélectionner un Plan avec Obstacles',
  [StringKey.APPROVAL_DATE]: 'Date d’Approbation',
  [StringKey.EXERCISED_SUCCESSFULLY]: 'Exercé avec succès',
  [StringKey.VESTING_ON_VALUE_HELP]: 'Qu’est-ce que « Vesting le » ?',
  [StringKey.SET_DURATION_HELP]: 'Comment fixer la durée ?',
  [StringKey.VESTING_FREQUENCY_HELP]: 'Quelle est la fréquence d’acquisition ?',
  [StringKey.VESTING_CLIFF_VALUE_HELP]: "Qu'est-ce que le cliff ?",
  [StringKey.MILESTONE_WEIGHT_HELP]: `Qu’est-ce que l'attribution ?`,
  [StringKey.ACTIVITY_OVERVIEW]: 'Récapitulatif des activités',
  [StringKey.OWNERSHIP_HISTORY]: 'Historique de la détention de capital',
  [StringKey.OWNERSHIP_HISTORY_PERCENT]: 'Historique du % de détention de capital',
  [StringKey.BY_SHARES]: 'Par actions',
  [StringKey.CURRENCY]: 'Devise',
  [StringKey.MILLIONS]: 'Millions',
  [StringKey.SEED]: 'Seed',
  [StringKey.EVENT_HISTORY]: 'Historique des événements',
  [StringKey.ALL_TYPES]: 'Tous types',
  [StringKey.OPEN_ROUND]: 'Round en cours',
  [StringKey.PRE_MONEY_VALUATION]: 'Valorisation pre-money',
  [StringKey.TRANSACTIONS]: 'Transactions',
  [StringKey.TRANSACTION]: 'Transaction',
  [StringKey.TRANSACTION_DATE]: 'Date de transaction',
  [StringKey.TRANSACTION_TYPE]: 'Type de transaction',
  [StringKey.NO_OF_SHARES]: "Nombre d'actions en milliers",
  [StringKey.FILE]: 'Fichier',
  [StringKey.ADD_INVESTMENT]: 'Ajouter un investissement',
  [StringKey.PIN_EVENT]: "Épingler l'événement",
  [StringKey.DELETE_EVENT]: "Supprimer l'événement",
  [StringKey.UNPIN]: 'Détacher le pin',
  [StringKey.UPDATE_EVENT]: "Mettre à jour l'événement [Nom de l'événement] ?",
  [StringKey.CONFIRM_UPDATE_EVENT]: "Êtes-vous sûr de vouloir mettre à jour l'événement {text} ?",
  [StringKey.REVIEW_CHANGES]:
    "Veuillez examiner attentivement vos modifications. La modification de l'événement peut affecter la structure globale des capitaux propres.",
  [StringKey.VIEW_EVENT]: "Voir l'événement",
  [StringKey.EDIT_EVENT]: "Modifier l'événement",
  [StringKey.NO_EVENTS]: "Aucun événement pour l'instant !",
  [StringKey.ADD_FIRST_EVENT]:
    "Vous n'avez pas encore créé d'événements. Ajoutez votre premier événement !",

  [StringKey.SAR_EXERCISED]: 'SAR Exercised',
  [StringKey.SAR_PAYOUT]: 'SAR Payout',
  [StringKey.HURDLE_VALUE]: 'Valeur Hurdle',
  [StringKey.SHARES_PAID_OUT]: 'Shares Paid Out',
  [StringKey.FROM_SHARE_CLASS]: "Classe d'actions à convertir",
  [StringKey.TO_SHARE_CLASS]: "Nouvelle classe d'actions",
  [StringKey.EQUITY_PLAN]: 'Equity Plan',
  [StringKey.INVESTMENTS]: 'Investments',
  [StringKey.EQUITY_POOL]: 'Equity Pool',
  [StringKey.VALUATION_EVENT]: 'Valuation Event',
  [StringKey.SHARE_PRICE]: 'Share Price',
  [StringKey.FROM_STAKEHOLDER]: 'Actionnaire cédant',
  [StringKey.TO_STAKEHOLDER]: 'Actionnaire acquérant',
  [StringKey.SHARE_VALUE]: 'Share Value',
  [StringKey.TRANSFER_AMOUNT]: 'Transfer Amount',
  [StringKey.ESTABLISH_CUSTOM_TERMINATION]:
    'Établir des conditions de départ personnalisées pour différents types de départs',
  [StringKey.NO_EXERCISE_EXPIRATION_PERIOD]: `Aucune période d’exercice forcé`,
  [StringKey.EXERCISE_EXPIRATION_PERIOD]: `Période d'exercice forcé`,
  [StringKey.TERMINATION_WITH_CAUSE]: 'Rupture pour cas de force majeure',
  [StringKey.RETIREMENT]: 'Retraite',
  [StringKey.INTERVAL]: 'Période',
  [StringKey.PLAN_ALLOCATION_DOES_NOT_REACH_100]: `Le pourcentage d'attribution du plan n'atteint pas 100 %`,
  [StringKey.WEIGHT_ALLOCATION_DOES_NOT_REACH_100]: `La répartition de l'attribution n'atteint pas 100 %`,
  [StringKey.DURATION_SHOULD_BE_MULTIPLY]: 'La durée doit être un multiple de votre fréquence',
  [StringKey.DATE_OF_TERMINATION]: 'Date de dissolution',
  [StringKey.UPDATE_POOL]: 'Êtes-vous sûr de vouloir mettre à jour ce {text}?',
  [StringKey.WHAT_IS_STRIKE_PRICE]: `Qu'est-ce que le prix d'exercice ?`,
  [StringKey.SAR_BASE_PRICE]: 'Prix ​​de base SAR',
  [StringKey.WHAT_IS_SAR_BASE_PRICE]: `Quel est le prix de base SAR ?`,
  [StringKey.WHAT_IS_WARRANT_PRICE]: `Quel est le prix du warrant ?`,
  [StringKey.EXPIRY_DATE]: `Date d'expiration`,
  [StringKey.EXERCISE_STOCK_WITH_EASE]: 'Exercez vos Stock Options en toute simplicité',
  [StringKey.MANAGE_WARRANTS]: 'Gérez ou exercez vos warrants en toute simplicité',
  [StringKey.EXERCISE_RSA_WITH_EASE]: 'Exercez votre RSA en toute simplicité',
  [StringKey.EXECUTE_SAR]: 'Exécutez vos SAR en toute simplicité',
  [StringKey.PAYOUT_PHANTOM]: 'Paiement des actions fantômes',
  [StringKey.PAYOUT_PHANTOM_WITH_EASE]: 'Payez vos actions fantômes en toute simplicité',
  [StringKey.APPROVE_HURDLE]: 'Approuver les objectifs du plan Hurdle',
  [StringKey.APPROVE_HURDLE_WITH_EASE]: 'Approuvez facilement vos objectifs',
  [StringKey.EXPIRE]: 'Expirer',
  [StringKey.SAR_FOR_EXERCISING]: 'SAR pouvant être exercer',
  [StringKey.NO_OF_SARS]: 'Nbre de SAR',
  [StringKey.SELECT_HURDLE_TO_APPROVE]: 'Sélectionnez le plan Hurdle à approuver',
  [StringKey.SAFES]: 'BSA Air',
  [StringKey.EVENT]: 'Événement',
  [StringKey.NO_PLANS_TO_APPROVE]: "Vous n'avez aucun plan à approuver",
  [StringKey.NAME_SHOULD_BE_UNIQUE]: 'Le nom est déjà utilisé',
  [StringKey.ASSOCIATED_TO_THIS_PLAN]: 'associé à ce plan',
  [StringKey.ALL_SARS_MUST_BE_DISTRIBUTED]: 'Tous les SAR doivent être distribués',
  [StringKey.EXERCISE_DATE_CANT_BE_LATER_THAN_EXPIRE]:
    "La date d'exercice ne peut pas être postérieure à la date d'expiration",
  [StringKey.EXPIRED_SUCCESSFULLY]: 'Expiré avec succès',
  [StringKey.ONE_OF_COUNT_FILES_UPLOADED]: '{count} fichier sur {totalCount} téléchargé',
  [StringKey.PLAN_SUCCESSFULLY_UPDATED]: 'Plan mis à jour avec succès',
  [StringKey.STRIKE_PRICE_VALUE]: "Prix d'exercice",
  [StringKey.WARRANT_PRICE_VALUE]: 'Prix du Warrant',
  [StringKey.NO_VESTING_STRUCTURE_SELECTED]: 'Aucune structure de vesting sélectionnée',
  [StringKey.PERIOD]: 'Période',
  [StringKey.MILESTONE]: 'Objectif',
  [StringKey.NO_TERMINATION_LOGIC_SELECTED]:
    'Aucune structure en cas de rupture de contrat sélectionnée',
  [StringKey.NEW_PLAN_CREATED_SUCCESSFULLY]: 'Nouveau plan créé avec succès',
  [StringKey.POOL_SUCCESSFULLY_UPDATED]: 'Pool mis à jour avec succès',
  [StringKey.TERMINATION_DATE_UPDATED]: 'Date de rupture de contrat mise à jour avec succès',
  [StringKey.VIEW_ASSOCIATED_PLANS]: 'Afficher {sharePlansCount} plan(s) associé(s)',
  [StringKey.PLAN_SUCCESSFULLY_DELETED]: 'Plan supprimé avec succès',
  [StringKey.POOL_SUCCESSFULLY_DELETED]: 'Pool supprimé avec succès',
  [StringKey.CHANGES_SAVED]: 'Modifications enregistrées',
  [StringKey.REQUIRED]: 'Requis',
  [StringKey.CAP_VALUE_LESS_THAN_MULTIPLE]: 'La valeur du cap est inférieure au multiple',
  [StringKey.MAXIMUM_CHARACTERS]: 'Nombre maximum de {count} caractères',
  [StringKey.FILE_SIZE_TOO_LARGE]: 'La taille du fichier est trop grande',
  [StringKey.CANNOT_ADD_MORE_THAN_COUNT_ITEMS]:
    'Vous ne pouvez pas ajouter plus de {count} éléments',
  [StringKey.OPTIONS_MUST_BE_LESS_THAN_BALANCE]:
    'Les options doivent être inférieures ou égales au solde',
  [StringKey.SARS_MUST_BE_LESS_THAN_BALANCE]:
    'Le nombre de SAR doit être inférieur ou égal au solde',
  [StringKey.SHARES_MUST_BE_LESS_THAN_BALANCE]:
    'Les actions doivent être inférieures ou égales au solde',
  [StringKey.ENTER_VALID_ZIP_CODE]: 'Entrez un code postal valide',
  [StringKey.INVALID_URL]: 'URL invalide',
  [StringKey.INVALID_NUMBER]: 'Numéro invalide',
  [StringKey.ITEMS_MAXIMUM]: '{count} éléments maximum',
  [StringKey.CHARACTER_LIMIT_IS_COUNT]: 'La limite de caractères est de {count}',
  [StringKey.INVALID_DATE]: 'Date invalide',
  [StringKey.INVALID_INVESTMENT_DATE]: "Date d'investissement invalide",
  [StringKey.INVESTMENT_DATE_MUST_BE_BEFORE_CLOSE_DATE]:
    'La date d’investissement doit être au plus tard à la date de clôture',
  [StringKey.STAKEHOLDERS_MUST_BE_DIFFERENT]:
    "L'actionnaire cédant et l'actionnaire achetant doivent être différents",
  [StringKey.SHARE_CLASSES_MUST_BE_DIFFERENT]:
    'Classe d’action initiale et Classe d’action finale doivent être différents',
  [StringKey.MUST_SELECT_VESTING_OPTION]: 'Vous devez sélectionner une option de vesting',
  [StringKey.DURATION_SHOULD_BE_MULTIPLE_OF_FREQUENCY]:
    'La durée doit être un multiple de votre fréquence',
  [StringKey.INVALID_TARGET_DATE]: "Date de l'objectif invalide",
  [StringKey.PASSWORDS_DONT_MATCH]: 'Les mots de passe sont incorrects',
  [StringKey.MODIFY_GUIDE]: 'Modify Guide',
  [StringKey.BY_PERCENTAGE]: 'Par pourcentage',
  [StringKey.NUMBER_OF_SHARES_IN_THOUSANDS]: "Nombre d'actions en milliers",
  [StringKey.FUNDRAISING]: 'Augmentation de capital',
  [StringKey.SHARES_ISSUANCE]: "Émission d'actions",
  [StringKey.SECONDARIES]: 'Secondaires',
  [StringKey.BUYBACK_DECREASE]: "Rachat d'actions",
  [StringKey.VALUATION]: 'Valorisation',
  [StringKey.APPROVAL_HURDLE]: 'Approbation (plan Hurdle)',
  [StringKey.EXPIRE_WARRANTS]: 'Expiration (Warrants)',
  [StringKey.LAST_COUNT_DAYS]: '{count} derniers jours',
  [StringKey.LAST_QUARTER]: 'Dernier trimestre',
  [StringKey.LAST_YEAR]: 'Dernière année',
  [StringKey.ALL_TIME]: 'Toute la période',
  [StringKey.CLASS_CONVERSION]: 'Conversion de classe',
  [StringKey.FUNDRAISING_ROUND]: 'Round de levée de fonds',
  [StringKey.APPROVAL]: 'Approbation',
  [StringKey.PAYOUT]: 'Paiement',
  [StringKey.ADD_GRANT]: 'Ajouter une attribution',
  [StringKey.BULK_IMPORT_GRANT]: "Importation d'attributions",
  [StringKey.ADD_FUNDRAISING_ROUND]: 'Ajouter un round de levée de fonds',
  [StringKey.ADD_SHARE_ISSUANCE]: 'Émettre des actions',
  [StringKey.ADD_SECONDARIES]: "Ajouter des secondaires (transfert d'actions)",
  [StringKey.ADD_BUYBACK]: 'Ajouter un rachat (diminuer les actions)',
  [StringKey.ADD_CLASS_CONVERSION]: 'Ajouter une conversion de classe',
  [StringKey.ADD_VALUATION]: 'Ajouter une valorisation',
  [StringKey.DELETE_TRANSACTION]: 'Supprimer la transaction',
  [StringKey.CLOSED_ROUND]: 'Clôturer le round',
  [StringKey.START_DATE]: 'Date de début',
  [StringKey.END_DATE]: 'Date de fin',
  [StringKey.TRANSACTION_SUCCESSFULLY_DELETED]: 'Transaction supprimée avec succès',
  [StringKey.EVENT_SUCCESSFULLY_DELETED]: 'Événement supprimé avec succès',
  [StringKey.UNABLE_TO_DELETE_EVENT]:
    'Impossible de supprimer un événement lié à un autre événement',
  [StringKey.GRANTS_SUCCESSFULLY_IMPORTED]: 'Attributions importées avec succès',
  [StringKey.UNEXPECTED_ERROR_OCCURRED]: "Une erreur inattendue s'est produite",
  [StringKey.ERROR_RESPONSE_NOT_PARSED]:
    "Une erreur s'est produite et la réponse n'a pas pu être produite",
  [StringKey.NEW_EVENT_CREATED_SUCCESSFULLY]: 'Nouvel événement créé avec succès',
  [StringKey.NO_RESULTS_FOUND]: 'Aucun résultat trouvé',
  [StringKey.CHOOSE_VALUATION_NAME]: 'Choisir {valuationName}',
  [StringKey.ROUND_NAME]: 'Nom du Round',
  [StringKey.EVENT_NAME]: "Nom de l'événement",
  [StringKey.REMOVE]: 'Supprimer',
  [StringKey.NO_PLAN_FOUND]: 'Aucun plan trouvé',
  [StringKey.ADD_NEW_SHAREHOLDER]: 'Ajouter un nouvel actionnaire',
  [StringKey.ADD_ANOTHER_BUYBACK]: 'Ajouter un autre rachat',
  [StringKey.EVENT_SUCCESSFULLY_UPDATED]: 'Événement mis à jour avec succès',
  [StringKey.ADD_ANOTHER_CLASS_CONVERSION]: 'Ajouter une autre conversion de classe',
  [StringKey.ADD_ANOTHER_GRANT]: 'Ajouter une autre attribution',
  [StringKey.ADD_ANOTHER_SHARE_TRANSFER]: "Ajouter un autre transfert d'actions",
  [StringKey.ADD_ANOTHER_ISSUANCE]: "Ajouter une autre émission d'actions",
  [StringKey.EVENT_DATE]: "Date de l'événement",
  [StringKey.PLEASE_CHOOSE_DIFFERENT_SHARE_CLASSES]:
    "Veuillez choisir des classes d'actions différentes",
  [StringKey.CONVERTED_SHARES]: 'Actions converties',
  [StringKey.ADD_ANOTHER_INVESTMENT]: 'Ajouter un autre investissement',
  [StringKey.INVESTMENT_DATE]: "Date d'investissement",
  [StringKey.FUNDRAISING_ROUND_STATUS]: 'Statut du round de levée de fonds',
  [StringKey.ROUND_STATUS]: 'Statut du round',
  [StringKey.OPEN_DATE]: "Date d'ouverture",
  [StringKey.CLOSING_DATE]: 'Date de clôture',
  [StringKey.EMPOWER_STAKEHOLDERS]: 'Responsabilisez vos parties prenantes',
  [StringKey.BULK_IMPORT_GRANT_FILES]: "Importer des fichiers d'attribution en masse",
  [StringKey.INITIATE_FUNDRAISING_ROUND]: 'Initier un round de levée de fonds',
  [StringKey.DISTRIBUTE_SHARES]: 'Distribuer des actions',
  [StringKey.ISSUE_SHARES]: 'Émettre des actions',
  [StringKey.TRANSFER_SHARES_SEAMLESSLY]: 'Transférer des actions de manière fluide',
  [StringKey.EXECUTE_SHARE_BUYBACKS]: "Exécuter des rachats d'actions",
  [StringKey.BUYBACK_DECREASE_SHARES]: 'Rachat - Diminution des actions',
  [StringKey.CONVERT_SHARE_CLASSES]: "Convertissez les classes d'actions sans effort",
  [StringKey.ENTER_COMPANY_VALUATION]: "Saisir la valorisation de l'entreprise",
  [StringKey.VIEW_COUNT_TRANSACTIONS]: 'Afficher les {count} transactions',
  [StringKey.FUNDRAISING_ROUND_STATE]: 'La levée de fonds est {state} pour les investissements',
  [StringKey.FUNDRAISING_ROUND_CONDITIONS]: `The link should be made for the 'veuillez cliquez ici'
The 'click here' text shouldn't be displayed`,
  [StringKey.CALCULATED_BY]: 'Calculé par CapQuest',
  [StringKey.VALUE_CALCULATION_INFO]: 'Comment cette valeur est-elle calculée ?',
  [StringKey.GRANT_ASSOCIATION_INFO]:
    'Vous disposez déjà d’une attribution associée à cet actionnaire et à ce plan. Pour modifier cette attribution, veuillez cliquer ici',
  [StringKey.DIFFERENT_STAKEHOLDERS]: 'Veuillez choisir des actionnaires différents',
  [StringKey.ISSUANCE]: 'Émission',
  [StringKey.INVEST_DATE]: "Date d'investissement",
  [StringKey.BULK_IMPORT_GRANTS]:
    'Importez facilement des fichiers pour faire de multiples attributions',
  [StringKey.INITIATE_FUNDRAISING]: 'Lancez votre levée de fonds dès maintenant',
  [StringKey.TRANSFER_SHARES]: 'Transférez des actions en toute simplicité',
  [StringKey.SHARE_BUYBACKS]: 'Exécuter efficacement les rachats d’actions',
  [StringKey.COMPANY_VALUATION]: 'Entrez la valorisation de votre entreprise',
  [StringKey.VIEW_TRANSACTIONS]: 'Afficher {count} transaction(s)',
  [StringKey.NO_EVENTS_FOUND]: 'Aucun événement trouvé',
  [StringKey.FILTERS]: 'Filtres',
  [StringKey.TYPES]: 'Types',
  [StringKey.APPLY_FILTER]: 'Appliquer le filtre',
  [StringKey.FILES]: 'Fichiers',
  [StringKey.FILES_ATTACHED_TO_EVENT]: "Fichiers attachés à l'événement",
  [StringKey.DOWNLOAD_ALL]: 'Tout télécharger',
  [StringKey.WARRANTS_MUST_BE_BALANCE]: 'Les warrants doivent équilibrer les actions diluées',
  [StringKey.ATTACHED_FILES]: "{count} fichiers joints à l'événement",
  [StringKey.WARRANTS_BALANCE]: 'Les Warrants doivent être inférieurs ou égaux au solde',
  [StringKey.OPEN]: 'Ouverte',
  [StringKey.CLOSED]: 'Fermée',
  [StringKey.SHARE_ISSUANCE]: "Émission d'actions",
  [StringKey.BUYBACK]: "Rachat d'actions",
  [StringKey.VAULT]: 'Le Coffre-Fort',
  [StringKey.STAKEHOLDER_ROLE_CHANGED]: 'Stakeholder role changed',
  [StringKey.IS_STRING]: 'Doit être une chaîne de caractères',
  [StringKey.IS_EMAIL]: 'Doit être une adresse e-mail valide',
  [StringKey.IS_NOT_EMPTY]: 'Ne peut pas être vide',
  [StringKey.IS_ENUM]: "Doit correspondre à l'une des options autorisées",
  [StringKey.IS_OPTIONAL]:
    "Est optionnel mais doit respecter les exigences de validation s'il est fourni",
  [StringKey.IS_ISO2_ALPHA]: 'Doit être un code pays ISO à 2 lettres valide',
  [StringKey.IS_PHONE_NUMBER]: 'Doit être un numéro de téléphone valide',
  [StringKey.IS_BOOLEAN]: 'Doit être un booléen (vrai ou faux)',
  [StringKey.IS_ARRAY]: 'Doit être un tableau',
  [StringKey.IS_URL]: 'Doit être une URL valide',
  [StringKey.MAX_LENGTH]: 'Dépasse la longueur maximale autorisée',

  [StringKey.NO_SAFES_FOUND]: 'Aucun BSA Air trouvé',
  [StringKey.CONVERT_SAFE]: 'Convertir BSA Air',
  [StringKey.ADD_SAFE]: 'Ajouter BSA Air',
  [StringKey.INVESTMENT_THROUGH_SAFE]: 'Investissement par BSA Air',
  [StringKey.SHARES_CONVERTED_FROM_SAFE]: 'Actions converties de BSA Air',
  [StringKey.SAFE_HOLDERS]: 'Investisseurs Air',
  [StringKey.CONVERTED_SAFE_HOLDERS]: 'Investisseurs Air convertis',
  [StringKey.INVESTMENT_PENDING_CONVERSION]: 'Investissement en attente de conversion',
  [StringKey.SAFE_RECAP]: 'Récapitulatif BSA Air',
  [StringKey.VALUE_MILLION]: 'Valeur (millions)',
  [StringKey.DISCOUNT_PERCENTAGE]: 'Pourcentage de décote (%)',
  [StringKey.LOWEST_VALUES]: 'Valeurs les plus basses',
  [StringKey.HIGHEST_VALUES]: 'Valeurs les plus élevées',
  [StringKey.PENDING_CONVERSION]: 'En attente de conversion',
  [StringKey.CONVERTED]: 'Converti',
  [StringKey.ISSUE_DATE]: "Date d'émission",
  [StringKey.VAL_CAP]: 'Val. Plafond (Cap)',
  [StringKey.SHARE_PRICE_DISCOUNT_PERCENTAGE]: "Décote sur le prix de l'action %",
  [StringKey.FLOOR]: 'Plancher (Floor)',
  [StringKey.MATURITY_DATE]: 'Date de maturité',
  [StringKey.VIEW_SAFE]: 'Visualiser BSA Air',
  [StringKey.EDIT_SAFE]: 'Modifier BSA Air',
  [StringKey.DELETE_PLAN]: 'Supprimer le BSA Air',
  [StringKey.CONVERSION_METHOD]: 'Méthode de conversion',
  [StringKey.CONVERSION_DATE]: 'Date de conversion',
  [StringKey.PARAMETER]: 'Paramètre',
  [StringKey.WHEN_AVAILABLE_SAFES]: "Lorsqu'ils seront ajoutés, les BSA Air seront affichés ici",
  [StringKey.STAKEHOLDERS_SELECTED]: '{count} investisseurs sélectionnés',
  [StringKey.DELETE_SAFE]: 'Supprimer BSA Air ?',
  [StringKey.SELECTED_SAFES_DELETED]:
    'Les BSA Air sélectionnés seront définitivement supprimés et ne seront plus disponibles pour la conversion.',
  [StringKey.NO_SAFES_CONVERTED]: 'Aucun BSA Air converti',
  [StringKey.WHEN_AVAILABLE_CONVERTED_SAFES]:
    "Lorsqu'ils seront ajoutés, les BSA Air convertis seront affichés ici",
  [StringKey.CAP_OR_DISCOUNT_DETAILS]: 'Détails du cap ou de la décote',
  [StringKey.CAP]: 'Plafond (Cap) :',
  [StringKey.FLOOR_AND_MATURITY_DATE]: 'Plancher et date de maturité',
  [StringKey.SAMPLE_ADDITIONAL_NOTE]:
    "Quelques exemples de notes additionnelles de l'administrateur",
  [StringKey.VALUATION_CAP]: 'Cap',
  [StringKey.DISCOUNT]: 'Décote',
  [StringKey.STAKEHOLDERS_SUCCESSFULLY_IMPORTED]: 'Actionnaires importé avec succès',
  [StringKey.EQUITY_AND_CAP_TABLE]: 'Equity & Cap Table',
  [StringKey.LINKED_EVENT]: 'Événement lié',
  [StringKey.GENERAL_CONVERSION_SUMMARY]: 'Résumé des conversions',
  [StringKey.EVENT_SHARE_PRICE_POST_CONVERSION]:
    'Prix ​​de l’action de l’événement (après conversion)',
  [StringKey.SAFE_INVESTMENT_CONVERSION_SUM]: 'Somme des investissements AIR à convertir',
  [StringKey.TOTAL_PERCENT_OWNED_SAFE]: '% total détenu par les investisseurs AIR sélectionnés',
  [StringKey.CONVERSION_DETAILS]: 'Détails des conversions',
  [StringKey.SAFE_INVESTMENT_CONVERSION]: 'Investissements AIR pour la conversion',
  [StringKey.METHOD_OF_CONVERSION]: 'Méthode de conversion',
  [StringKey.PERCENT_CAPITAL_OWNED_POST_CONVERSION]: '% du capital détenu après conversion',
  [StringKey.SHARES_CONVERTED]: "Nombre d'actions converties",
  [StringKey.IMPORT_SAFE]: 'Importer BSA Air',
  [StringKey.SELECT_SHAREHOLDER]: 'Sélectionner un actionnaire',
  [StringKey.FLOOR_MATURITY_DATE]: 'Plancher (Floor) et date de maturité',
  [StringKey.OPTIONAL]: 'Facultatif',
  [StringKey.AMOUNT]: 'Montant',
  [StringKey.PERCENTAGE]: 'Pourcentage',
  [StringKey.AMOUNT_OPTIONAL]: 'Montant (facultatif)',
  [StringKey.DATE_OPTIONAL]: 'Date (facultatif)',
  [StringKey.CONVERT_SAFE_TO_SHARES]: 'Convertir BSA Air en actions',
  [StringKey.PLEASE_SELECT]: 'Veuillez sélectionner',
  [StringKey.SELECT_SAFE]: 'Sélectionnez BSA Air',
  [StringKey.CONVERT]: 'Convertir',
  [StringKey.REPOSITORY]: 'Archive',
  [StringKey.OPEN_DRIVE]: "Ouvrir l'archive",
  [StringKey.HOME]: 'Principal',
  [StringKey.LAST_EDIT]: 'Dernière modification',
  [StringKey.SIZE]: 'Taille',
  [StringKey.TO_OPEN]: 'Ouvrir',
  [StringKey.DOWNLOAD]: 'Télécharger',
  [StringKey.RENAME]: 'Rebaptiser',
  [StringKey.VIEW]: 'Voir',
  [StringKey.ADD_FILES]: 'Ajouter des fichiers',
  [StringKey.NOTHING_FOUND]: 'Aucune données',
  [StringKey.UPLOAD_PROMPT]: 'Téléchargez des fichiers ou créez un nouveau dossier.',
  [StringKey.UPLOAD_FILE]: 'Télécharger le fichier',
  [StringKey.FOLDER]: 'Dossier',
  [StringKey.CREATE_NEW_FOLDER]: 'Créer un nouveau dossier',
  [StringKey.FOLDER_NAME]: 'Nom du dossier',
  [StringKey.CREATE]: 'Créer',
  [StringKey.UPLOAD_FILES_DOCUMENTS]: 'Télécharger des fichiers/documents',
  [StringKey.DONE]: 'Confirmer',
  [StringKey.FILE_EXISTS]: 'Un fichier du même nom existe déjà.',
  [StringKey.RENAME_FILE]: 'Renommer le fichier',
  [StringKey.FILE_RENAME]: 'Renommer le fichier',
  [StringKey.CURRENT_FILE_NAME]: 'Nom du {text} actuel',
  [StringKey.NEW_FILE_NAME]: 'Nouveau nom du {text}',
  [StringKey.FOLDER_CREATED_SUCCESSFULLY]: 'Dossier créé avec succès',
  [StringKey.SIZE_B]: 'O',
  [StringKey.SIZE_MB]: 'Mo',
  [StringKey.SIZE_KB]: 'Ko',
  [StringKey.SIZE_GB]: 'Go',
  [StringKey.USE_TABLET_DESKTOP]:
    'Utilisez une tablette ou un ordinateur de bureau pour télécharger et créer de nouveaux fichiers et dossiers',
  [StringKey.FILES_FOLDERS_DISPLAYED]:
    'Vos fichiers/dossiers seront affichés ici lorsqu’ils seront disponibles.',
  [StringKey.CONVERTED_BASIS]: 'Converted Basis',
  [StringKey.CONVERTED_BASIS_PERCENT]: 'Converted Basis %',
  [StringKey.FILE_SUCCESSFULLY_UPLOADED]: '1 fichier téléchargé avec succès',
  [StringKey.FILES_SUCCESSFULLY_UPLOADED]: '{count} fichiers téléchargés avec succès',
};
