import { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import {
  CircleCheckIcon,
  CloudUploadIcon,
  DownloadIcon,
  ImportIcon,
  InfoCircle,
  UploadIcon,
} from '../../../../assets/icons';
import LoaderIcon from '../../../../assets/icons/LoaderIcon';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import {
  Company,
  useAppMutation,
  useInvalidateQueries,
  useLocale,
  useReactForm,
} from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { accessTokenStorage } from '../../../../storage/accessTokenStorage';
import { FormSchema, formSchema } from './validation';

export type ImportSafesProps = {
  isOpenModal: boolean;
  setOpen: (state: boolean) => void;
};

export const ImportSafes: FC<ImportSafesProps> = ({ isOpenModal, setOpen }) => {
  const { messagesLocale, locale } = useLocale();
  const { selectedCompanyId: companyId } = Company.useSelected();
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_SAFES);
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    getFieldState,
    formState: { isValid, errors },
  } = useReactForm({ schema: formSchema(messagesLocale) });

  const { mutate: mutateTemplate } = useAppMutation([QueryKey.SAFE_DOWNLOAD_TEMPLATE, companyId], {
    mutationFn: () =>
      ApiService.downloadFile(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'safes', 'template'],
        },
        { locale },
      ),
  });

  const { mutate, isPending } = useAppMutation([QueryKey.STAKEHOLDERS_BULK_IMPORT, companyId], {
    mutationFn: async ({ file }: { file: File }) => {
      const fileData = new FormData();

      await ApiService.refreshTokens();
      const accessToken = accessTokenStorage.get();
      fileData.append('file', file);
      return fetch(
        `${process.env.REACT_APP_BACKEND_URL}${BackendRoute.COMPANIES}/${companyId}/safes/import`,
        {
          body: fileData,
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            locale: locale,
          },
        },
      );
    },
    onSuccess: async (response) => {
      setOpen(false);
      reset();
      invalidateQuery();

      if (response.ok) return toast.success('Safes successfully imported');

      const body = await response.json();

      if (body?.message) {
        return toast.error(body?.message);
      }

      const row: number = body?.row;
      const errors: {
        property: string;
        constrains: { [key: string]: string };
        value: string;
      }[] = body?.errors;

      const firstError = errors?.[0];
      const firstConstraintKey = Object.keys(firstError?.constrains)[0];

      return toast.error(`Error at row ${row}: ${firstError?.constrains?.[firstConstraintKey]}`);
    },
  });
  const handleCloseModal = useCallback(() => {
    reset();
    setOpen(false);
  }, [reset, setOpen]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      mutate({ file: data.file });
    },
    [mutate],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue('file', acceptedFiles[0], { shouldValidate: true });
    },
    [setValue],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  const isValidFileState = getValues('file') && !getFieldState('file').invalid;
  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden overflow-y-auto rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.IMPORT_SAFE} />
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <form className="flex h-full flex-col gap-4" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex h-full flex-col gap-6 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
              <div className="flex h-11 w-full justify-between">
                <Button
                  className={twMerge(
                    'flex w-fit items-center gap-4 rounded border border-gray-100 px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs',
                  )}
                  onClick={() => mutateTemplate()}
                  styleType="NONE"
                  type="button"
                >
                  <DownloadIcon />
                  <AppFormattedMessage id={StringKey.DOWNLOAD_TEMPLATE} />
                </Button>
                <Button
                  className="pointer-events-none flex w-fit items-center gap-2 text-sm font-[450] text-brand-700 underline"
                  styleType="NONE"
                >
                  <AppFormattedMessage id={StringKey.VIEW_IMPORT_GUID} />
                  <ImportIcon />
                </Button>
              </div>
              <input {...getInputProps()} accept=".xlsx" className="hidden" type="file" />
              <label
                {...getRootProps()}
                className={twMerge(
                  'relative flex w-full border-spacing-[7px] cursor-pointer flex-col items-center gap-6 rounded-lg border border-dashed border-gray-200 py-6',
                )}
              >
                {isValidFileState ? (
                  isPending ? (
                    <LoaderIcon className="size-14" />
                  ) : (
                    <CircleCheckIcon />
                  )
                ) : (
                  <>
                    {errors.file ? (
                      <InfoCircle className="size-14" thinIconVariant />
                    ) : (
                      <CloudUploadIcon />
                    )}
                  </>
                )}
                <div className="flex flex-col items-center gap-2 text-center">
                  <div className="flex justify-center">
                    {isValidFileState ? (
                      <span className="text-sm font-[550] text-forest-500">
                        <AppFormattedMessage id={StringKey.FILE_PROCESSED_SUCCESSFULLY} />
                      </span>
                    ) : errors.file ? (
                      <span className="text-sm font-[550] text-fireside-600">
                        {errors.file.message?.toString()}
                      </span>
                    ) : (
                      <p className="text-sm font-medium text-gray-700">
                        <span className="underline">
                          <AppFormattedMessage id={StringKey.CLICK_TO_UPLOAD} />{' '}
                        </span>
                        <AppFormattedMessage id={StringKey.OR_DRAG_AND_DROP} />
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col gap-1 text-[10px] font-[450] leading-4 text-gray-500">
                    {isValidFileState ? (
                      <>
                        <span>{(getValues('file') as unknown as File).name}</span>
                        <span>
                          {((getValues('file') as unknown as File).size / 1024).toFixed(3)}
                          <AppFormattedMessage id={StringKey.KILOBYTE} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <AppFormattedMessage
                            id={StringKey.SUPPORTED_FORMATS}
                            values={{
                              formats: 'xlsx, xlx',
                            }}
                          />
                        </span>
                        <span>
                          <AppFormattedMessage
                            id={StringKey.MAXIMUM_FILE_SIZE}
                            values={{ size: 10 }}
                          />
                        </span>
                      </>
                    )}
                  </div>
                  {errors.file && (
                    <span className="flex h-9 w-fit items-center gap-1 rounded border border-gray-100 bg-gray-25 px-3 py-[6px] text-sm font-[450] text-gray-700">
                      <UploadIcon />
                      <AppFormattedMessage id={StringKey.UPLOAD_AGAIN} />
                    </span>
                  )}
                </div>
              </label>
            </div>

            <div className="flex h-9 w-full justify-end gap-3 px-6">
              <Button
                className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700"
                onClick={() => setOpen(false)}
                styleType="NONE"
                type="button"
              >
                <AppFormattedMessage id={StringKey.CLOSE} />
              </Button>
              <Button
                className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
                disabled={!isValid}
                type="submit"
              >
                Confirm Import
              </Button>
            </div>
          </form>
        </div>
      </SheetContent>
    </Sheet>
  );
};
