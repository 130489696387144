import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';
import { Company } from '../../hooks';
import { StringKey } from '../../lang';
import { percentage } from '../../utils/percentage';
import { AppFormattedMessage } from '../AppFormattedMessage';

type ProgressToolTipProps = {
  companyId: string;
  onClick: () => void;
};

export const ProgressToolTip: FC<ProgressToolTipProps> = ({ companyId, onClick }) => {
  const { companyProgress } = Company.useGetStartedProgress({ companyId });

  if (!companyProgress) return;

  const completedStepsMap = Object.values(companyProgress);

  const progress = percentage(
    completedStepsMap.length + 1,
    completedStepsMap.filter((state) => state).length + 1,
  );

  if (progress === 100) return;

  return (
    <div
      className="absolute bottom-5 right-20 z-1 flex cursor-pointer items-center gap-10 rounded-full border-[1px] border-[#F2F2F2] bg-white px-4 py-3 shadow-xl"
      onClick={onClick}
    >
      <div className="flex items-center gap-3">
        <div className={twMerge('relative flex h-[34px] w-[34px] items-center justify-center')}>
          <div
            className="absolute rounded-full"
            style={{
              width: '100%',
              height: '100%',
              background: `conic-gradient(#12B76A ${progress * 3.6}deg, #EAECF0 0deg)`,
            }}
          />
          <div
            className="absolute rounded-full bg-white"
            style={{
              width: 'calc(100% - 6px)',
              height: 'calc(100% - 6px)',
            }}
          />
          <div className="absolute text-label-sm font-[500] text-[#12B76A]">
            {completedStepsMap.filter((state) => state).length + 1}/{completedStepsMap.length + 1}
          </div>
        </div>
        <span className="text-base font-semibold text-gray-700">
          <AppFormattedMessage id={StringKey.GET_STARTED} />
        </span>
      </div>
      <ChevronDownIcon className="-rotate-90" iconColor="#172335" />
    </div>
  );
};
