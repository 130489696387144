import React, { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../../../../components/Combobox';
import { StringKey } from '../../../../lang';

const getDaysArray = (year = 2024, month = 0) => {
  const numberOfDays = new Date(year, month, 0).getDate();
  const daysArray = Array.from({ length: numberOfDays }, (_, index) => index + 1);

  return daysArray;
};

type DayItemsListProps = {
  selectedYear?: number;
  selectedMonth?: number;
  selectedDay?: null | number;
};

const DayItemsList: FC<DayItemsListProps> = ({ selectedMonth, selectedYear, selectedDay }) => (
  <>
    {selectedDay && (
      <ComboboxOption className="text-sm font-normal text-gray-700" value={''}>
        Reset
      </ComboboxOption>
    )}
    {getDaysArray(selectedYear, selectedMonth).map((day) => (
      <ComboboxOption key={day} value={day as unknown as string}>
        <span className="text-sm font-normal text-gray-700">{day}</span>
      </ComboboxOption>
    ))}
  </>
);

export type DayComboboxProps = {
  onSelect?: (day: number) => void;
  selectedYear?: number;
  selectedMonth?: number;
  defaultValue?: number;
};

const DayCombobox: FC<DayComboboxProps> = ({
  onSelect,
  selectedMonth,
  selectedYear,
  defaultValue,
}) => {
  const [selectedDay, setDay] = useState<null | number>(null);
  return (
    <Combobox
      as={'div'}
      className="relative"
      onChange={(e: number) => {
        setDay(e);
        onSelect?.(e);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className={'absolute z-0 bg-gray-900'}
          icon={<ChevronDownIcon className="mt-3" />}
          onChange={() => ''}
          placeholder={<AppFormattedMessage id={StringKey.DAY} />}
          readOnly
          value={defaultValue?.toString() || selectedDay?.toString() || ''}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <DayItemsList
          selectedDay={selectedDay}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      </ComboboxOptions>
    </Combobox>
  );
};

export default DayCombobox;
