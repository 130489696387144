import React, { FC } from 'react';

import { EditIcon, FolderIcon, ThreeDotsIcon, TrashBinIcon } from '../../../assets/icons';
import { FolderIconVariant } from '../../../assets/icons/FolderIcon';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { FormattedDateDistance } from '../../../components/FormattedDateDistance';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { Repository, VaultFolder, VaultSelect, VaultTab } from '../../../types/vault.types';
import { formatBytes } from '../../../utils/formatBytes';
import { RenameModal } from '../Modals';

export type VaultMobileFolderProps = {
  data: Repository;
  item: VaultFolder;
  onFolderClick: () => void;
  handleDownload: (id: string) => void;
  handleRename: (id: string, name: string) => void;
  handleDelete: (id: string) => void;
  selectedTab: VaultTab;
};

export const VaultMobileFolder: FC<VaultMobileFolderProps> = ({
  data,
  item,
  onFolderClick,
  handleDownload,
  handleRename,
  handleDelete,
  selectedTab,
}) => {
  const folderNames = data?.folders?.map((folder: VaultFolder) => folder?.name?.toLowerCase());
  const { fileCount, name, size, updatedAt, id } = item;

  const {
    toggler: renameModalToggler,
    isOpen: isOpenRenameModal,
    handleOpenModal: handleOpenRenameModal,
    handleCloseModal: handleCloseRenameModal,
    handleSuccessModal: handleSuccessRenameModal,
    modalData: currentName,
  } = useModalState<string | undefined, unknown, string>({
    onSuccess: (name?: string) => {
      handleRename(id, name || '');
    },
  });
  return (
    <>
      <AlertDialogWrapper control={{ onOpenChange: renameModalToggler, open: isOpenRenameModal }}>
        <RenameModal
          currentName={currentName}
          folderNames={folderNames}
          onClose={handleCloseRenameModal}
          onSuccess={handleSuccessRenameModal}
          type={VaultSelect.FOLDER}
        />
      </AlertDialogWrapper>

      <div className="flex w-full items-center justify-between gap-2 p-4 pr-2">
        <div className="flex w-full items-center gap-3">
          <FolderIcon className="size-6" variant={FolderIconVariant.FILLED} />
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-700">{name}</span>
            <span className="text-label-sm font-medium text-gray-400">
              {fileCount} <AppFormattedMessage id={StringKey.FILE} />
              {fileCount !== 1 && 's'} - <AppFormattedMessage id={StringKey.LAST_EDIT} />:{' '}
              {updatedAt && <FormattedDateDistance date={new Date(updatedAt)} />} -{' '}
              {formatBytes(size)}
            </span>
          </div>
        </div>
        <Popover>
          <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
            <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
              <ThreeDotsIcon />
            </div>
          </PopoverTrigger>
          <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
            <PopoverClose>
              <div
                className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                onClick={onFolderClick}
              >
                <FolderIcon className="size-4" />
                <span className="text-sm font-normal text-gray-700">
                  <AppFormattedMessage id={StringKey.TO_OPEN} />
                </span>
              </div>
              <div
                className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                onClick={() => handleDownload(id)}
              >
                <FolderIcon variant={FolderIconVariant.DOWNLOAD} />
                <span className="text-sm font-normal text-gray-700">
                  <AppFormattedMessage id={StringKey.DOWNLOAD} />
                </span>
              </div>
              {selectedTab === VaultTab.OPEN_DRIVE && (
                <>
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleOpenRenameModal(name)}
                  >
                    <EditIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.RENAME} />
                    </span>
                  </div>
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleDelete(id)}
                  >
                    <TrashBinIcon className="size-4" iconColor="#F04438" />
                    <span className="text-sm font-normal text-fireside-500">
                      <AppFormattedMessage id={StringKey.DELETE} />
                    </span>
                  </div>
                </>
              )}
            </PopoverClose>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};
