import React, { FC } from 'react';

import { EyeIconSmall, ThreeDotsIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { useFormat } from '../../../hooks';
import { StringKey } from '../../../lang';
import { conversionMethodTitle, DetailsTab, Safe } from '../../../types/safes.types';
import { toNumber } from '../../../utils/toNumber';

export type SafeTableItemProps = {
  handleView: (id: string) => void;
  safe: Safe;
  selectedTab: DetailsTab;
};

export const SafeTableItem: FC<SafeTableItemProps> = ({ handleView, safe, selectedTab }) => {
  const {
    id,
    investment,
    conversionDate,
    conversionMethod,
    convertedShares,
    floorValue,
    issueDate,
    maturityDate,
    discount,
    capValue,
  } = safe;

  const { format } = useFormat();

  return (
    <>
      <tr className="w-full bg-white">
        <td className="max-w-[151px] truncate p-4 text-sm text-gray-700">
          {toNumber(investment)?.toLocaleString('en-US')}
        </td>
        <td className="max-w-[113px] truncate p-4 text-sm text-gray-700">
          {issueDate ? format(issueDate, 'dd/MM/yyyy') : '-'}
        </td>
        {selectedTab === DetailsTab.PENDING_CONVERSION ? (
          <>
            <td className="max-w-[149px] truncate p-4 text-sm text-gray-700">
              {capValue ? toNumber(capValue)?.toLocaleString('en-US') : '-'}
            </td>
            <td className="max-w-[164px] truncate p-4 text-sm text-gray-700">
              {discount ? `${discount * 100}%` : '-'}
            </td>
            <td className="max-w-[100px] truncate p-4 text-sm text-gray-700">
              {floorValue ? toNumber(floorValue)?.toLocaleString('en-US') : '-'}
            </td>
            <td className="max-w-[113px] truncate p-4 text-sm text-gray-700">
              {maturityDate ? format(maturityDate, 'dd/MM/yyyy') : '-'}
            </td>
          </>
        ) : (
          <>
            <td className="p-4 text-sm text-gray-700">
              {conversionMethod ? (
                <AppFormattedMessage id={conversionMethodTitle[conversionMethod]} />
              ) : (
                '-'
              )}
            </td>
            <td className="max-w-[113px] truncate p-4 text-sm text-gray-700">
              {conversionDate ? format(conversionDate, 'dd/MM/yyyy') : '-'}
            </td>
            <td className="max-w-[100px] truncate p-4 text-sm text-gray-700">
              {convertedShares ? toNumber(convertedShares)?.toLocaleString('en-US') : '-'}
            </td>
          </>
        )}
        <td className="px-4 py-[22px]">
          <Popover>
            <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
              <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                <ThreeDotsIcon />
              </div>
            </PopoverTrigger>
            <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
              <PopoverClose>
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleView(id)}
                >
                  <EyeIconSmall />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.VIEW_SAFE} />
                  </span>
                </div>
              </PopoverClose>
            </PopoverContent>
          </Popover>
        </td>
      </tr>
    </>
  );
};
