import React, { FC } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { StringKey } from '../../../lang';

export type BreadcrumbsProps = {
  selectedFolders: { id: string; name: string }[];
  onBreadcrumbClick: (index: number, id: string | undefined) => void;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ onBreadcrumbClick, selectedFolders }) => {
  return (
    <nav className="flex items-center gap-2 text-sm">
      <div className="flex items-center">
        <button
          className="px-3 py-2 text-sm font-[450] text-gray-700"
          onClick={() => onBreadcrumbClick(-1, undefined)}
        >
          <AppFormattedMessage id={StringKey.HOME} />
        </button>
        {selectedFolders.length !== 0 && <ChevronDownIcon className="size-3 -rotate-90" />}
      </div>

      {selectedFolders.map((folder, index) => {
        const isLast = index === selectedFolders.length - 1;

        return (
          <div className="flex items-center" key={folder.id}>
            {isLast ? (
              <span className="cursor-default px-3 py-2 text-sm font-[450] text-gray-700">
                {folder.name}
              </span>
            ) : (
              <>
                <button
                  className="px-3 py-2 text-sm font-[450] text-gray-700"
                  onClick={() => onBreadcrumbClick(index, folder.id)}
                >
                  {folder.name}
                </button>
                <ChevronDownIcon className="size-3 -rotate-90" />
              </>
            )}
          </div>
        );
      })}
    </nav>
  );
};

export default Breadcrumbs;
