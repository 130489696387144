import React, { FC, useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CloseIcon, LogoutIcon, MessageIcon, ProfileIcon } from '../../assets/icons';
import BurgerMenuIcon from '../../assets/icons/BurgerMenuIcon';
import { useHeaderContext } from '../../context/headerContext';
import { useNotifications } from '../../context/notifications.context';
import { useModalState, User, useScreenSize } from '../../hooks';
import { StringKey } from '../../lang';
import { sideBarContext } from '../../storage/sideBarContext';
import { pageTitleTranslation } from '../../translations/pageTranslation';
import { replaceIdWithPath } from '../../types/pageTypes';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Button from '../Button';
import CompanySelect from '../CompanySelect';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../Popover';

interface HeaderProps {
  pageName?: string;
}

const Header: FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isShownCompanySelect } = useHeaderContext();

  const { closeSideBar, isOpen, openSideBar } = useContext(sideBarContext);
  const { isMobile } = useScreenSize();

  const { user } = User.useUser();
  const { logoutUser } = User.useLogout();

  const handleLogout = useCallback(() => {
    logoutUser();
  }, [logoutUser]);
  const { unreadNotification } = useNotifications();
  const { setParamValue: openActivityCenter } = useModalState('activity-bar');

  return (
    <div className="flex w-full items-center justify-between rounded-b-[12px] bg-white px-4 py-3 font-inter shadow-xs lg:rounded-b-[24px]">
      <div className="flex w-full items-center gap-2">
        <Button
          className="size-5 lg:hidden"
          onClick={isOpen ? closeSideBar : openSideBar}
          styleType="NONE"
        >
          {isOpen ? (
            <CloseIcon className="size-[10px]" />
          ) : (
            <BurgerMenuIcon height={20} width={20} />
          )}
        </Button>
        <div className="text-base font-[550] text-gray-700 lg:text-xl">
          <AppFormattedMessage id={pageTitleTranslation[replaceIdWithPath(location.pathname)]} />
        </div>
      </div>

      <div className="flex w-1/2 items-center justify-end gap-4">
        {!(isOpen && isMobile) && isShownCompanySelect && <CompanySelect isMobile={isMobile} />}

        <div
          className="relative h-fit w-fit cursor-pointer lg:hidden"
          onClick={() => openActivityCenter('true')}
        >
          <MessageIcon />
          {!!unreadNotification.total && (
            <span
              className="absolute -right-1 -top-1 flex size-4 shrink-0 items-center justify-center rounded-full bg-fireside-600 text-white"
              style={{
                fontSize: '9px',
                fontWeight: '600',
                lineHeight: '11.2px',
                textAlign: 'center',
              }}
            >
              {unreadNotification.total}
            </span>
          )}
        </div>

        <Popover>
          <PopoverTrigger className="flex h-[32px] w-[32px] min-w-[32px] items-center justify-center overflow-hidden rounded-[32px] bg-brand-100 ring-brand-700 data-[state=open]:ring-2 max-lg:hidden">
            <div className="flex h-full w-full items-center justify-center rounded-full">
              {user?.fullName[0]}
            </div>
          </PopoverTrigger>
          <PopoverContent className="flex w-fit flex-col gap-[6px] rounded p-2 text-sm font-normal shadow-2xl">
            <PopoverClose className="w-[168px] divide-y-[1px] divide-gray-200">
              <>
                <div
                  className="flex items-center gap-[6px] px-2 py-[6px]"
                  onClick={() => navigate('/profile')}
                >
                  <ProfileIcon />
                  <span className="text-sm font-[450] text-gray-800">
                    <AppFormattedMessage id={StringKey.MY_PROFILE} />
                  </span>
                </div>
                <div className="flex items-center gap-[6px] px-2 py-[6px]" onClick={handleLogout}>
                  <LogoutIcon />
                  <span className="text-sm font-[450] text-fireside-600">
                    <AppFormattedMessage id={StringKey.LOG_OUT} />
                  </span>
                </div>
              </>
            </PopoverClose>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default Header;
