import React, { FC } from 'react';

import { EditIcon, FileIcon, ThreeDotsIcon, TrashBinIcon } from '../../../assets/icons';
import { FileIconVariant } from '../../../assets/icons/FileIcon';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { FormattedDateDistance } from '../../../components/FormattedDateDistance';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { useModalState, VaultHooks } from '../../../hooks';
import { StringKey } from '../../../lang';
import { VaultFile, VaultSelect } from '../../../types/vault.types';
import { formatBytes } from '../../../utils/formatBytes';
import { RenameModal } from '../Modals';

export type VaultTableFileProps = {
  handleDownload: (link: string) => void;
  handleRename: (id: string, name: string, link?: string) => void;
  handleDelete: (id: string) => void;
  renderFileColumn?: boolean;
  item: VaultFile;
};

export const VaultTableFile: FC<VaultTableFileProps> = ({
  handleDownload,
  handleDelete,
  handleRename,
  renderFileColumn,
  item,
}) => {
  const { name, size, updatedAt, id, link } = item;

  const {
    toggler: renameModalToggler,
    isOpen: isOpenRenameModal,
    handleOpenModal: handleOpenRenameModal,
    handleCloseModal: handleCloseRenameModal,
    handleSuccessModal: handleSuccessRenameModal,
    modalData: currentName,
  } = useModalState<string | undefined, unknown, string>({
    onSuccess: (name?: string) => {
      handleRename(id, name || '', link);
    },
  });

  const { viewFile } = VaultHooks.useViewFile();

  return (
    <>
      <AlertDialogWrapper control={{ onOpenChange: renameModalToggler, open: isOpenRenameModal }}>
        <RenameModal
          currentName={currentName}
          onClose={handleCloseRenameModal}
          onSuccess={handleSuccessRenameModal}
          type={VaultSelect.FILE}
        />
      </AlertDialogWrapper>
      <tr className="w-full bg-white transition-all duration-300">
        <td className="max-w-[762px] truncate p-4 text-sm text-gray-700">
          <div className="flex items-center gap-2">
            <FileIcon variant={FileIconVariant.FILLED} />
            {name}
          </div>
        </td>
        {renderFileColumn && <td className="p-4 text-sm text-gray-300">-</td>}
        <td className="p-4 text-sm text-gray-700">
          {updatedAt ? <FormattedDateDistance date={new Date(updatedAt)} /> : '-'}
        </td>
        <td className="p-4 text-sm text-gray-700">{formatBytes(size)}</td>
        <td className="px-4 py-[22px]">
          <Popover>
            <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
              <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                <ThreeDotsIcon />
              </div>
            </PopoverTrigger>
            <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
              <PopoverClose>
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => viewFile({ fileId: id })}
                >
                  <FileIcon className="size-4" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.VIEW} />
                  </span>
                </div>
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleDownload(link)}
                >
                  <FileIcon variant={FileIconVariant.DOWNLOAD} />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.DOWNLOAD} />
                  </span>
                </div>
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleOpenRenameModal(name)}
                >
                  <EditIcon />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.RENAME} />
                  </span>
                </div>
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleDelete(id)}
                >
                  <TrashBinIcon className="size-4" iconColor="#F04438" />
                  <span className="text-sm font-normal text-fireside-500">
                    <AppFormattedMessage id={StringKey.DELETE} />
                  </span>
                </div>
              </PopoverClose>
            </PopoverContent>
          </Popover>
        </td>
      </tr>
    </>
  );
};
