import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { useFormat } from '../../../hooks';
import { StringKey } from '../../../lang';
import { Invoice } from '../../../types/companyTypes';
import { SubscriptionType, SubscriptionTypeTitle } from '../../../types/planTypes';
import { Tag, TagVariant } from '../../Events/Tag';

export type TableItemProps = {
  handleDocumentClick: (id: string) => void;
  planType?: SubscriptionType;
} & Pick<Invoice, 'amount_due' | 'currency' | 'id' | 'created' | 'invoice_pdf' | 'paid' | 'lines'>;

export const TableItem: FC<TableItemProps> = ({
  handleDocumentClick,
  amount_due,
  currency,
  id,
  created,
  invoice_pdf,
  paid,
  lines,
  planType,
}) => {
  const { format } = useFormat();
  return (
    <>
      <tr className="w-full bg-white">
        <td className="max-w-[300px] truncate p-4 text-sm text-gray-700">{id}</td>
        <td className="p-4 text-sm text-gray-700">
          {created ? format(created * 1000, 'dd/MM/yyy') : '-'}
        </td>
        <td className="p-4 text-sm text-gray-700">{planType && SubscriptionTypeTitle[planType]}</td>
        <td className="p-4 text-sm text-gray-700">
          <AppFormattedMessage
            id={lines?.data[0]?.plan?.interval === 'month' ? StringKey.MONTHLY : StringKey.ANNUALY}
          />
        </td>
        <td className="max-w-[300px] truncate p-4 text-sm text-gray-700">
          {currency === 'usd' && '$'} {(amount_due / 100)?.toLocaleString('en-US')}
        </td>
        <td className="max-w-[300px] truncate p-4 text-sm text-gray-700">
          {paid ? (
            <Tag
              className="w-[57px] items-center justify-center"
              title={'Paid'}
              variant={TagVariant.GREEN}
            />
          ) : (
            <Tag
              className="w-[57px] items-center justify-center"
              title={'Unpaid'}
              variant={TagVariant.GRAY}
            />
          )}
        </td>

        <td
          className="p-4 text-sm text-gray-700"
          onClick={() => handleDocumentClick(`${invoice_pdf}`)}
        >
          <span className="cursor-pointer text-sm font-[450] text-brand-700 underline">
            <AppFormattedMessage id={StringKey.DOWNLOAD} />
          </span>
        </td>
      </tr>
    </>
  );
};
