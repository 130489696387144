import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export enum FileIconVariant {
  DEFAULT,
  FILLED,
  DOWNLOAD,
}

export type FileIconProps = { variant?: FileIconVariant } & IconProps;

export const fileIconVariant: Record<
  FileIconVariant,
  (props: Except<FileIconProps, 'variant'>) => ReactNode
> = {
  [FileIconVariant.DEFAULT]: ({ iconColor = '#344054', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33332 1.51306V4.26676C9.33332 4.64012 9.33332 4.82681 9.40599 4.96942C9.4699 5.09486 9.57189 5.19684 9.69733 5.26076C9.83994 5.33342 10.0266 5.33342 10.4 5.33342H13.1537M9.33332 11.3334H5.33332M10.6667 8.66671H5.33332M13.3333 6.65886V11.4667C13.3333 12.5868 13.3333 13.1469 13.1153 13.5747C12.9236 13.951 12.6176 14.257 12.2413 14.4487C11.8135 14.6667 11.2534 14.6667 10.1333 14.6667H5.86666C4.74655 14.6667 4.1865 14.6667 3.75868 14.4487C3.38235 14.257 3.07639 13.951 2.88464 13.5747C2.66666 13.1469 2.66666 12.5868 2.66666 11.4667V4.53337C2.66666 3.41327 2.66666 2.85322 2.88464 2.42539C3.07639 2.04907 3.38235 1.74311 3.75868 1.55136C4.1865 1.33337 4.74655 1.33337 5.86666 1.33337H8.00784C8.49702 1.33337 8.74161 1.33337 8.97179 1.38863C9.17586 1.43763 9.37095 1.51844 9.54989 1.62809C9.75173 1.75178 9.92468 1.92473 10.2706 2.27063L12.3961 4.39612C12.742 4.74202 12.9149 4.91497 13.0386 5.1168C13.1483 5.29575 13.2291 5.49084 13.2781 5.69491C13.3333 5.92509 13.3333 6.16968 13.3333 6.65886Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </svg>
  ),
  [FileIconVariant.DOWNLOAD]: ({ iconColor = '#344054', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 8.33337V4.53337C13.3333 3.41327 13.3333 2.85322 13.1153 2.42539C12.9236 2.04907 12.6176 1.74311 12.2413 1.55136C11.8135 1.33337 11.2534 1.33337 10.1333 1.33337H5.86666C4.74655 1.33337 4.1865 1.33337 3.75868 1.55136C3.38235 1.74311 3.07639 2.04907 2.88464 2.42539C2.66666 2.85322 2.66666 3.41327 2.66666 4.53337V11.4667C2.66666 12.5868 2.66666 13.1469 2.88464 13.5747C3.07639 13.951 3.38235 14.257 3.75868 14.4487C4.1865 14.6667 4.74652 14.6667 5.86656 14.6667H8.33332M9.33332 7.33337H5.33332M6.66666 10H5.33332M10.6667 4.66671H5.33332M9.99999 12.6667L12 14.6667M12 14.6667L14 12.6667M12 14.6667V10.6667"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </svg>
  ),
  [FileIconVariant.FILLED]: ({ className, iconColor = '#344054', fill = '#EAECF0', ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66663 4.53337C2.66663 3.41327 2.66663 2.85322 2.88461 2.42539C3.07636 2.04907 3.38232 1.74311 3.75865 1.55136C4.18647 1.33337 4.74652 1.33337 5.86663 1.33337H8.00781C8.49699 1.33337 8.74158 1.33337 8.97176 1.38863C9.17583 1.43763 9.37092 1.51844 9.54986 1.62809C9.7517 1.75178 9.92465 1.92473 10.2706 2.27063L12.396 4.39612C12.7419 4.74202 12.9149 4.91497 13.0386 5.1168C13.1482 5.29575 13.229 5.49084 13.278 5.69491C13.3333 5.92509 13.3333 6.16968 13.3333 6.65886V11.4667C13.3333 12.5868 13.3333 13.1469 13.1153 13.5747C12.9236 13.951 12.6176 14.257 12.2413 14.4487C11.8135 14.6667 11.2534 14.6667 10.1333 14.6667H5.86663C4.74652 14.6667 4.18647 14.6667 3.75865 14.4487C3.38232 14.257 3.07636 13.951 2.88461 13.5747C2.66663 13.1469 2.66663 12.5868 2.66663 11.4667V4.53337Z"
        fill={fill}
      />

      <path
        d="M9.33329 1.51306V4.26676C9.33329 4.64012 9.33329 4.82681 9.40596 4.96942C9.46987 5.09486 9.57186 5.19684 9.6973 5.26076C9.83991 5.33342 10.0266 5.33342 10.4 5.33342H13.1537M9.33329 11.3334H5.33329M10.6666 8.66671H5.33329M13.3333 6.65886V11.4667C13.3333 12.5868 13.3333 13.1469 13.1153 13.5747C12.9236 13.951 12.6176 14.257 12.2413 14.4487C11.8135 14.6667 11.2534 14.6667 10.1333 14.6667H5.86663C4.74652 14.6667 4.18647 14.6667 3.75865 14.4487C3.38232 14.257 3.07636 13.951 2.88461 13.5747C2.66663 13.1469 2.66663 12.5868 2.66663 11.4667V4.53337C2.66663 3.41327 2.66663 2.85322 2.88461 2.42539C3.07636 2.04907 3.38232 1.74311 3.75865 1.55136C4.18647 1.33337 4.74652 1.33337 5.86663 1.33337H8.00781C8.49699 1.33337 8.74158 1.33337 8.97176 1.38863C9.17583 1.43763 9.37092 1.51844 9.54986 1.62809C9.7517 1.75178 9.92465 1.92473 10.2706 2.27063L12.396 4.39612C12.7419 4.74202 12.9149 4.91497 13.0386 5.1168C13.1482 5.29575 13.229 5.49084 13.278 5.69491C13.3333 5.92509 13.3333 6.16968 13.3333 6.65886Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  ),
};

const FileIcon: FC<FileIconProps> = ({ variant = FileIconVariant.DEFAULT, ...props }) =>
  fileIconVariant[variant](props);

export default FileIcon;
