import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useSelected } from '../company';
import { useAppMutation } from '../useAppMutation';

export const useViewFile = () => {
  const { selectedCompanyId: companyId } = useSelected();
  const { mutate: viewFile } = useAppMutation([QueryKey.FILE_VIEW, companyId], {
    mutationFn: ({ fileId }: { fileId: string }) =>
      ApiService.previewFile({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'vault', 'files', fileId, 'view'],
      }),
  });

  return { viewFile };
};
