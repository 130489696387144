import { StringKey } from '../lang';
import { Company } from './companyTypes';
import { Valuation } from './events.types';
import { ExerciseRequest } from './exerciseRequest.types';
import { SharePlan, SharePlanType, sharePlanTypeTitle } from './pool-plans.types';
import {
  Stakeholder,
  StakeholderType,
  stakeholderTypeTitle,
  TerminationCause,
  TerminationCauseTitle,
} from './stakeholderTypes';
import { TargetBasedVestingMilestoneItem, VestingTask } from './vestingTasks.types';

export enum NotificationType {
  ACTIVITY = 'activity',
  REQUEST = 'request',
}

export const notificationTypeMap = Object.values(NotificationType);

export enum NotificationAction {
  STAKEHOLDER_ADDED = 'stakeholder-added',
  INVITATION_ACCEPTED = 'invitation-accepted',
  STAKEHOLDER_TERMINATED = 'stakeholder-terminated',
  ACCESS_REVOKED = 'access-revoked',
  STAKEHOLDER_ROLE_CHANGED = 'stakeholder-role-changed',
}

export const notificationActionTitle: Record<NotificationAction, StringKey> = {
  [NotificationAction.STAKEHOLDER_ADDED]: StringKey.STAKEHOLDER_ADDED,
  [NotificationAction.INVITATION_ACCEPTED]: StringKey.INVITATION_ACCEPTED,
  [NotificationAction.STAKEHOLDER_TERMINATED]: StringKey.STAKEHOLDER_TERMINATED,
  [NotificationAction.ACCESS_REVOKED]: StringKey.ACCESS_REVOKED,
  [NotificationAction.STAKEHOLDER_ROLE_CHANGED]: StringKey.STAKEHOLDER_ROLE_CHANGED,
};

export const notificationRequestTitle: Record<SharePlanType, StringKey> = {
  [SharePlanType.COMMON_STOCK]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.GROWTH_HURDLE]: StringKey.HURDLE_APPROVAL,
  [SharePlanType.WARRANTS]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.BSPCE]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.PHANTOM]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.RSA]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.RSU]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.SAR]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.STOCK_OPTIONS]: StringKey.EXERCISE_REQUESTED,
};

export type NotificationStakeholdersRead = {
  id: string;
  notification: Notification;
  stakeholder: Stakeholder;
  notificationId: string;
  stakeholderId: string;
};

export type ApproveRequest = {
  id: string;
  vestingTasks: VestingTask[];
  sharePlan: SharePlan;
  valuation: Valuation;
};

export type AppNotification = {
  id: string;
  company: Company;
  type: NotificationType;
  action?: NotificationAction;

  stakeholderName?: string;
  stakeholderType?: StakeholderType;
  stakeholderId?: string;

  requestId?: string;
  requestSharesCount?: number;
  requestPlan?: string;
  requestPlanType?: SharePlanType;
  requestHurdleValue?: number;
  numberOfGrants?: number;
  requestTotalSharesCount?: number;
  requestNextTriggerTime?: string;
  approveRequestId?: string;
  adminName?: string;
  adminType?: StakeholderType;
  adminId?: string;

  resolved: boolean;
  createdAt: Date;
  companyId: string;
  is_read: boolean;
  exerciseRequest?: ExerciseRequest;
  targetBasedMilestoneRequest?: TargetBasedVestingMilestoneItem;
  milestones?: TargetBasedVestingMilestoneItem[];
  notificationStakeholdersRead: NotificationStakeholdersRead[];
};

type NotificationBaseOptions = {
  isAdminYou: boolean;
  adminName: string;
  stakeholderName: string;
  stakeholderType: StakeholderType;
};

type ActiveNotificationBaseOptions = {
  action?: NotificationAction;
  terminationType: TerminationCause;
} & NotificationBaseOptions;

type RequestNotificationBaseOptions = {
  planName: string;
  hurdleValue: number;
  planGrunts: number;
  planType: SharePlanType;
  planAvailableCount: number;
  requestedSharesCount: number;
  milestoneName: string;
  milestoneDate: string;
} & NotificationBaseOptions;

const getAdminPart = ({ isAdminYou, adminName }: NotificationBaseOptions) =>
  isAdminYou ? `${adminName} (You)` : adminName;

const createStakeholderAddedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} was added as a '${options.stakeholderType && stakeholderTypeTitle[options.stakeholderType]}' by ${getAdminPart(options)}`;

const createInvitationAcceptedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} has accepted the stakeholder invitation sent by ${getAdminPart(options)}`;

const createStakeholderTerminatedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} was Terminated ('${options.terminationType && TerminationCauseTitle[options.terminationType]}') as ${options.stakeholderType && stakeholderTypeTitle[options.stakeholderType]} by ${getAdminPart(options)}`;

const createAccessRevokedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} has had access revoked by ${getAdminPart(options)}`;

const createRoleChangedNotification = (options: ActiveNotificationBaseOptions) =>
  `Role of ${options.stakeholderName} has changed by ${getAdminPart(options)}`;

const createExerciseRequestedNotification = (options: RequestNotificationBaseOptions) =>
  `${options.stakeholderName} has requested to exercise ${options.requestedSharesCount} ${options.planType && sharePlanTypeTitle[options.planType]} out of ${options.planAvailableCount}`;

const createHurdleApprovalNotification = (options: RequestNotificationBaseOptions) =>
  `Hurdle Plan ${options.planName} has exceeded the $${options.hurdleValue?.toLocaleString('en-US')} hurdle value. ${options.planGrunts} grants are pending approval.`;

const createWarrantNotification = (options: RequestNotificationBaseOptions) =>
  `${options.stakeholderName} has requested to exercise ${options.requestedSharesCount?.toLocaleString('en-US')} Warrants out of ${options.planAvailableCount.toLocaleString('en-US')} available.`;

const createTargetBasedVestingApprovalNotification = (options: RequestNotificationBaseOptions) =>
  `Milestone ${options.milestoneName} under the Target-Based Plan ${options.planName} (${options.planType && sharePlanTypeTitle[options.planType]}) has a set target date of ${options.milestoneDate}. Would you like to Approve Target?`;

const NotificationFactory = {
  createActionNotification: (options: ActiveNotificationBaseOptions) => {
    switch (options.action) {
      case NotificationAction.STAKEHOLDER_ADDED:
        return createStakeholderAddedNotification(options);
      case NotificationAction.INVITATION_ACCEPTED:
        return createInvitationAcceptedNotification(options);
      case NotificationAction.STAKEHOLDER_TERMINATED:
        return createStakeholderTerminatedNotification(options);
      case NotificationAction.STAKEHOLDER_ROLE_CHANGED:
        return createRoleChangedNotification(options);
      default:
        return createAccessRevokedNotification(options);
    }
  },

  createRequestNotification: (options: RequestNotificationBaseOptions) => {
    switch (options.planType) {
      case SharePlanType.STOCK_OPTIONS:
        return createExerciseRequestedNotification(options);
      case SharePlanType.GROWTH_HURDLE:
        return createHurdleApprovalNotification(options);
      case SharePlanType.WARRANTS:
        return createWarrantNotification(options);
      default:
        return createTargetBasedVestingApprovalNotification(options);
    }
  },
};

export const activityNotificationBuilder = NotificationFactory.createActionNotification;
export const requestNotificationBuilder = NotificationFactory.createRequestNotification;
