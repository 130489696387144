import { ReactNode } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { StringKey } from '../../lang';

export enum CompanySettingsCardTab {
  COMPANY_DETAILS = 'details',
  COMPANY_ADDRESS = 'address',
  COMPANY_PLAN_BILLING = 'plan-billing',
  COMPANY_BILLING_HISTORY = 'company-billing-history',
  COMPANY_EQUITY = 'company-equity',
}

export const companySettingsCardTitleMap: Record<CompanySettingsCardTab, ReactNode> = {
  [CompanySettingsCardTab.COMPANY_DETAILS]: <AppFormattedMessage id={StringKey.DETAILS} />,
  [CompanySettingsCardTab.COMPANY_ADDRESS]: (
    <>
      <AppFormattedMessage id={StringKey.ADDRESS} />
    </>
  ),
  [CompanySettingsCardTab.COMPANY_PLAN_BILLING]: (
    <AppFormattedMessage id={StringKey.PLAN_BILLING} />
  ),
  [CompanySettingsCardTab.COMPANY_BILLING_HISTORY]: (
    <AppFormattedMessage id={StringKey.BILLING_HISTORY} />
  ),
  [CompanySettingsCardTab.COMPANY_EQUITY]: (
    <AppFormattedMessage id={StringKey.EQUITY_AND_CAP_TABLE} />
  ),
};

export const arrayOfCompanySettingsCardTitleMap = Object.entries(companySettingsCardTitleMap) as [
  CompanySettingsCardTab,
  string,
][];
