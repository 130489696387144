import React, { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../../../../components/Combobox';
import { StringKey } from '../../../../lang';

const getYearsFrom1800 = (max = new Date().getFullYear(), min = 1800) => {
  const year = [];
  for (let i = max; i >= min; i--) {
    year.push(i);
  }
  return year;
};

interface YearItemsListProps {
  maxYear?: number;
  minYear?: number;
  sortAsc?: boolean;
  selectedYear?: null | number;
}

const YearItemsList: FC<YearItemsListProps> = ({ maxYear, minYear, sortAsc, selectedYear }) => (
  <>
    {selectedYear && (
      <ComboboxOption className="text-sm font-normal text-gray-700" value={''}>
        Reset
      </ComboboxOption>
    )}
    {sortAsc
      ? getYearsFrom1800(maxYear, minYear)
          .sort((a, b) => a - b)
          .map((year) => (
            <ComboboxOption key={year} value={year as unknown as string}>
              <span className="text-sm font-normal text-gray-700">{year}</span>
            </ComboboxOption>
          ))
      : getYearsFrom1800(maxYear, minYear).map((year) => (
          <ComboboxOption key={year} value={year as unknown as string}>
            <span className="text-sm font-normal text-gray-700">{year}</span>
          </ComboboxOption>
        ))}
  </>
);

export type YearComboboxProps = {
  onSelect?: (year: number) => void;
  defaultValue?: number;
  sortAsc?: boolean;
  minYear?: number;
  maxYear?: number;
};

const YearCombobox: FC<YearComboboxProps> = ({
  onSelect,
  defaultValue,
  maxYear,
  minYear,
  sortAsc,
}) => {
  const [currentYear, setYear] = useState<null | number>(null);
  return (
    <Combobox
      as={'div'}
      className="relative"
      onChange={(value: number) => {
        onSelect?.(value);
        setYear(value);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className={'absolute z-0 bg-gray-900'}
          icon={<ChevronDownIcon className="mt-3" />}
          onChange={() => ''}
          placeholder={<AppFormattedMessage id={StringKey.YEAR} />}
          readOnly
          value={defaultValue?.toString() || (currentYear && currentYear?.toString()) || ''}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <YearItemsList
          maxYear={maxYear}
          minYear={minYear}
          selectedYear={currentYear}
          sortAsc={sortAsc}
        />
      </ComboboxOptions>
    </Combobox>
  );
};

export default YearCombobox;
