import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { CreateFileDto } from '../../types/vault.types';
import { useAppMutation } from '../useAppMutation';

export const useCreateFile = () => {
  const { mutate: create, isPending } = useAppMutation([QueryKey.CREATE_FILE], {
    mutationFn: ({ companyId, data }: { data: CreateFileDto; companyId: string }) =>
      ApiService.post(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'vault', 'files'] },
        { body: data },
      ),
  });
  return { create, isPending };
};
