import React, { FC, ReactNode, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { CloseModalButton } from '../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { AnalyticHooks, Stakeholder, useReactForm } from '../../../hooks';
import { StringKey } from '../../../lang';
import { PossibleAction } from '../../../types/google-analytics-types';
import { b2bStakeholderTypes } from '../../../types/stakeholderTypes';
import { transformDatetoISOString } from '../Stakeholders';
import { FinalStep, StepOne, StepTwo } from './Steps';
import { ChildrenFormProps } from './type';
import { FormSchema, formSchema } from './Validation';

export type AddStakeholderModalProps = {
  isOpenModal: boolean;
  setOpen: (state: boolean) => void;
  companyId: string;
  invalidateQuery: () => void;
};

const formSteps: ((props: ChildrenFormProps) => ReactNode)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <FinalStep {...props} />,
];

const AddStakeholderModal: FC<AddStakeholderModalProps> = ({
  isOpenModal,
  setOpen,
  companyId,
  invalidateQuery,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    resetField,
    clearErrors,
    setError,
    getFieldState,
    getValues,
    formState: { disabled, errors },
  } = useReactForm({
    schema: formSchema,
    defaultValues: {
      stepOne: {
        access: {
          capTable: true,
          companyTable: true,
          equity: true,
          position: true,
        },
      },
    },
  });

  const handleCloseModal = useCallback(() => {
    reset();
    setOpen(false);
    setCurrentStep(1);
  }, [reset, setOpen]);

  const addStakeholderTracker = AnalyticHooks.useClick(PossibleAction.ADD_STAKEHOLDER);
  const { createCompany, createHuman, isPendingCompany, isPendingHuman } = Stakeholder.useCreate();

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo } = data;
      const {
        address,
        email,
        fullName,
        stakeholderType,
        phoneNumber,
        access,
        phoneCountry,
        company,
        human,
      } = stepOne;
      if (b2bStakeholderTypes.includes(stakeholderType)) {
        createHuman(
          {
            companyId,
            data: {
              birthday: human?.birthdayDate
                ? transformDatetoISOString(
                    human?.birthdayDate.year,
                    human?.birthdayDate.month + 1,
                    human?.birthdayDate.day,
                  )
                : undefined,
              address: address || undefined,
              email,
              fullName,
              nationality: human?.nationality?.iso2Code || undefined,
              passportExpDate: human?.passportExpiryDate
                ? transformDatetoISOString(
                    human?.passportExpiryDate.year,
                    human?.passportExpiryDate.month + 1,
                    human?.passportExpiryDate.day,
                  )
                : undefined,
              passportNo: human?.passportNo.toString() || undefined,
              type: stakeholderType,
              phone: phoneCountry?.prefix ? phoneCountry?.prefix + phoneNumber : undefined,
              capTableAccess: access?.capTable,
              positionAccess: access?.position,
              capitalChangeAccess: access?.equity,
              companyTableAccess: access?.companyTable,
              additionalNotes: stepTwo?.additionalNotes,
              filesLinks:
                stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
            },
          },
          {
            onSuccess: () => {
              addStakeholderTracker('Natural persons');
              handleCloseModal();
              toast.success(
                <AppFormattedMessage id={StringKey.STAKEHOLDER_SUCCESSFULLY_CREATED} />,
              );
              invalidateQuery();
            },
          },
        );

        return;
      }

      if (!company) return;
      return createCompany(
        {
          companyId,
          data: {
            email,
            stakeholderCompanyId: company?.identifier.toString() || undefined,
            fullName,
            countryOfInc: company?.incorporationCountry?.iso2Code || undefined,
            incDate: company?.incorporationDate
              ? transformDatetoISOString(
                  company?.incorporationDate.year,
                  company?.incorporationDate.month + 1,
                  company?.incorporationDate.day,
                )
              : undefined,
            address: address || undefined,
            type: stakeholderType,
            phone: phoneCountry?.prefix ? phoneCountry?.prefix + phoneNumber : undefined,
            phoneCountryIso2: phoneCountry?.iso2Code || undefined,
            capTableAccess: access?.capTable,
            positionAccess: access?.position,
            capitalChangeAccess: access?.equity,
            companyTableAccess: access?.companyTable,
            additionalNotes: stepTwo?.additionalNotes,
            filesLinks:
              stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
          },
        },
        {
          onSuccess: () => {
            addStakeholderTracker('Legal entities');
            handleCloseModal();
            toast.success(<AppFormattedMessage id={StringKey.STAKEHOLDER_SUCCESSFULLY_CREATED} />);
            invalidateQuery();
          },
        },
      );
    },
    [
      createCompany,
      companyId,
      createHuman,
      addStakeholderTracker,
      handleCloseModal,
      invalidateQuery,
    ],
  );

  const handleNextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.ADD_STAKEHOLDER} />
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex w-full items-center gap-[5px] bg-white px-6 pb-6">
            {formSteps.map((_, i) => (
              <div
                className={twMerge(
                  'h-1 w-full rounded-[25px]',
                  i + 1 <= currentStep ? 'bg-[#12B76A]' : 'bg-gray-100',
                  i + 1 === currentStep &&
                    currentStep === formSteps.length &&
                    'bg-gradient-to-r from-forest-500 to-forest-300',
                )}
                key={`${i}_${companyId}`}
              />
            ))}
          </div>
          <div className="flex h-full flex-col gap-4 overflow-hidden">
            {formSteps[currentStep - 1]({
              formData: getValues,
              nextFormStep: handleNextStep,
              prevFormStep: handlePrevStep,
              setFormData: setValue,
              control,
              clearErrors,
              handleCloseModal,
              setError,
              filedState: getFieldState,
              errors,
              disabled,
              resetField,
              isLoading: isPendingCompany || isPendingHuman,
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default AddStakeholderModal;
