import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export enum FolderIconVariant {
  DEFAULT,
  FILLED,
  DOWNLOAD,
}

export type FolderIconProps = { variant?: FolderIconVariant } & IconProps;

export const folderIconVariant: Record<
  FolderIconVariant,
  (props: Except<FolderIconProps, 'variant'>) => ReactNode
> = {
  [FolderIconVariant.DEFAULT]: ({ iconColor = '#344054', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9 7.50002L11.896 5.49205C11.6071 4.91415 11.4626 4.62518 11.247 4.41407C11.0564 4.22738 10.8267 4.0854 10.5745 3.9984C10.2892 3.90002 9.96619 3.90002 9.32006 3.90002H5.88C4.87191 3.90002 4.36786 3.90002 3.98282 4.09621C3.64413 4.26878 3.36876 4.54415 3.19619 4.88284C3 5.26788 3 5.77193 3 6.78002V7.50002M3 7.50002H16.68C18.1921 7.50002 18.9482 7.50002 19.5258 7.79431C20.0338 8.05317 20.4469 8.46621 20.7057 8.97425C21 9.55181 21 10.3079 21 11.82V15.78C21 17.2922 21 18.0482 20.7057 18.6258C20.4469 19.1338 20.0338 19.5469 19.5258 19.8057C18.9482 20.1 18.1921 20.1 16.68 20.1H7.32C5.80786 20.1 5.05179 20.1 4.47423 19.8057C3.96619 19.5469 3.55314 19.1338 3.29428 18.6258C3 18.0482 3 17.2922 3 15.78V7.50002Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  [FolderIconVariant.FILLED]: ({
    className,
    iconColor = '#2565C8',
    fill = '#B4D1FE',
    ...props
  }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33337 4.66667H11.4667C12.5868 4.66667 13.1469 4.66667 13.5747 4.88465C13.951 5.0764 14.257 5.38236 14.4487 5.75869C14.6667 6.18651 14.6667 6.74656 14.6667 7.86667V10.8C14.6667 11.9201 14.6667 12.4802 14.4487 12.908C14.257 13.2843 13.951 13.5903 13.5747 13.782C13.1469 14 12.5868 14 11.4667 14H4.53337C3.41327 14 2.85322 14 2.42539 13.782C2.04907 13.5903 1.74311 13.2843 1.55136 12.908C1.33337 12.4802 1.33337 11.9201 1.33337 10.8V4.66667Z"
        fill={fill}
      />

      <path
        d="M8.66671 4.66667L7.92301 3.17928C7.70898 2.7512 7.60195 2.53715 7.44229 2.38078C7.30109 2.24249 7.13092 2.13732 6.94409 2.07287C6.73282 2 6.49351 2 6.0149 2H3.46671C2.71997 2 2.3466 2 2.06139 2.14532C1.8105 2.27316 1.60653 2.47713 1.4787 2.72801C1.33337 3.01323 1.33337 3.3866 1.33337 4.13333V4.66667M1.33337 4.66667H11.4667C12.5868 4.66667 13.1469 4.66667 13.5747 4.88465C13.951 5.0764 14.257 5.38236 14.4487 5.75869C14.6667 6.18651 14.6667 6.74656 14.6667 7.86667V10.8C14.6667 11.9201 14.6667 12.4802 14.4487 12.908C14.257 13.2843 13.951 13.5903 13.5747 13.782C13.1469 14 12.5868 14 11.4667 14H4.53337C3.41327 14 2.85322 14 2.42539 13.782C2.04907 13.5903 1.74311 13.2843 1.55136 12.908C1.33337 12.4802 1.33337 11.9201 1.33337 10.8V4.66667Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </svg>
  ),
  [FolderIconVariant.DOWNLOAD]: ({ iconColor = '#344054', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66665 4.66667L7.92295 3.17928C7.70892 2.7512 7.60189 2.53715 7.44223 2.38078C7.30103 2.24249 7.13086 2.13732 6.94403 2.07287C6.73276 2 6.49345 2 6.01484 2H3.46665C2.71991 2 2.34654 2 2.06133 2.14532C1.81044 2.27316 1.60647 2.47713 1.47864 2.72801C1.33331 3.01323 1.33331 3.3866 1.33331 4.13333V4.66667M1.33331 4.66667H11.4666C12.5868 4.66667 13.1468 4.66667 13.5746 4.88465C13.951 5.0764 14.2569 5.38236 14.4487 5.75869C14.6666 6.18651 14.6666 6.74656 14.6666 7.86667V10.8C14.6666 11.9201 14.6666 12.4802 14.4487 12.908C14.2569 13.2843 13.951 13.5903 13.5746 13.782C13.1468 14 12.5868 14 11.4666 14H4.53331C3.41321 14 2.85316 14 2.42533 13.782C2.04901 13.5903 1.74305 13.2843 1.5513 12.908C1.33331 12.4802 1.33331 11.9201 1.33331 10.8V4.66667ZM5.99998 9.33333L7.99998 11.3333M7.99998 11.3333L9.99998 9.33333M7.99998 11.3333V7.33333"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </svg>
  ),
};

const FolderIcon: FC<FolderIconProps> = ({ variant = FolderIconVariant.DEFAULT, ...props }) =>
  folderIconVariant[variant](props);

export default FolderIcon;
