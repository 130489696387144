import React, { FC, useEffect, useState } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { SummaryItem } from '../../../../components/SummaryItem';
import { SafeHooks } from '../../../../hooks';
import { SafeConversionSummaryResponse } from '../../../../hooks/safe';
import { StringKey } from '../../../../lang';
import { conversionMethodTitle } from '../../../../types/safes.types';
import { toRound } from '../../../../utils/getRoundedNumber';
import { ConversionSummaryWithId } from './ConversionSummary';
import { SafeDropDown } from './SafeDropDown';

export type ConvertSafeItemProps = {
  companyId: string;
  safeId: string;
  allSafeIds?: string[];
  currencySign?: string;
  setConversionSummaries?: React.Dispatch<React.SetStateAction<ConversionSummaryWithId[]>>;
  valuationId?: string;
};

export const ConvertSafeItem: FC<ConvertSafeItemProps> = ({
  companyId,
  safeId,
  allSafeIds,
  currencySign,
  valuationId,
  setConversionSummaries,
}) => {
  const [conversionSummary, setConversionSummary] = useState<SafeConversionSummaryResponse | null>(
    null,
  );
  const { getSafeConversionSummary, isPending } = SafeHooks.useSafeConversionSummary();

  useEffect(() => {
    getSafeConversionSummary(
      {
        companyId,
        safeId,
        data: {
          safeIds: allSafeIds || [],
          valuationId: valuationId === 'no-valuation' ? undefined : valuationId,
        },
      },
      {
        onSuccess: (data) => {
          setConversionSummary(data);
          if (setConversionSummaries) {
            setConversionSummaries((prev) => {
              const filteredSummaries = prev.filter((item) => allSafeIds?.includes(item.id));

              return filteredSummaries.some((item) => item.id === safeId)
                ? filteredSummaries.map((item) =>
                    item.id === safeId ? { ...item, summary: data } : item,
                  )
                : [...filteredSummaries, { id: safeId, summary: data }];
            });
          }
        },
      },
    );
  }, [
    companyId,
    safeId,
    allSafeIds,
    getSafeConversionSummary,
    setConversionSummaries,
    valuationId,
  ]);

  if (isPending || !conversionSummary) return;
  return (
    <>
      <SafeDropDown title={conversionSummary?.stakeholder?.fullName}>
        <div className="flex w-full flex-col divide-y-[1px] divide-gray-100">
          <SummaryItem
            className="pt-0"
            title={<AppFormattedMessage id={StringKey.SAFE_INVESTMENT_CONVERSION} />}
            value={`${currencySign} ${conversionSummary.investment.toLocaleString('en-US')}`}
          />
          <SummaryItem
            title="Valuation Cap - % at conversion"
            value={toRound(conversionSummary['valuation-cap'] * 100)}
          />
          <SummaryItem
            title="Discount - % at conversion"
            value={toRound(conversionSummary.discount * 100)}
          />
          <SummaryItem
            title="Floor - % at conversion"
            value={toRound(conversionSummary.floor * 100)}
          />
          <SummaryItem
            title={<AppFormattedMessage id={StringKey.METHOD_OF_CONVERSION} />}
            value={
              <AppFormattedMessage id={conversionMethodTitle[conversionSummary.conversionMethod]} />
            }
          />
          <SummaryItem
            title={<AppFormattedMessage id={StringKey.PERCENT_CAPITAL_OWNED_POST_CONVERSION} />}
            value={`${toRound(conversionSummary.capitalOwned * 100)}%`}
          />
          <SummaryItem
            className="pb-0"
            title={<AppFormattedMessage id={StringKey.SHARES_CONVERTED} />}
            value={`${toRound(conversionSummary.sharesConverted, 0).toLocaleString('en-US')}`}
          />
        </div>
      </SafeDropDown>
    </>
  );
};
