import { z } from 'zod';

import { StringKey } from '../../../lang';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];
export const fileSchemaMessage = getTranslation(StringKey.FILE_SIZE_TOO_LARGE);

export const formSchema = z.object({
  files: z
    .object({
      docLink: z.string(),
      loadProgress: z.number().max(100),
      abort: z.function(),
      id: z.string(),
      doc: z
        .custom<File>()
        .or(
          z.object({
            size: z.number(),
            type: z.string(),
            name: z.string(),
          }),
        )
        .refine(
          ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
          getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
        )
        .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
    })
    .array(),
});

export type FormSchema = z.infer<typeof formSchema>;
