import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useSelected } from '../company';
import { useAppMutation } from '../useAppMutation';

export const useFolderExport = () => {
  const { selectedCompanyId: companyId } = useSelected();
  const { mutate: mutateFolderExport } = useAppMutation([QueryKey.FOLDER_EXPORT, companyId], {
    mutationFn: async ({ folderId }: { folderId: string }) => {
      await ApiService.downloadFile({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'vault', 'folders', folderId, 'export'],
      });
    },
  });

  return { mutateFolderExport };
};
