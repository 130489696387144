import { FC, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ShareClass, useFakePagination, useLocale } from '../../../../../hooks';
import { stakeholderTypeTranslation } from '../../../../../translations/stakeholderTranslation';
import {
  Column,
  DetailsSortVariant,
  StakeholderColumns,
  StakeholderStats,
  ViewBy,
} from '../../../../../types/snapshot.types';
import { filterByAllFields } from '../../../../../utils/filterByAllFields';
import { toRound } from '../../../../../utils/getRoundedNumber';
import { toNumber } from '../../../../../utils/toNumber';
import { AmountOfFetchedItems } from '../../../variables';
import { getShareClasses } from '../../SnapshotViewBy/type';
import { TableRow } from '../TableRow';
import { PaginationItem } from './PaginationItem';

type StakeholderTableItemProps = {
  visibleColumns: Column[];
  viewBy: Record<ViewBy, boolean>;
  fields: Record<StakeholderColumns, string | number | string[] | number[] | null>;
  className?: string;
  trClassName?: string;
  allShareClassNames: string[];
} & Optional<{
  search: string;
  stakeholders: StakeholderStats[];
}>;

export const StakeholderTableItem: FC<StakeholderTableItemProps> = ({
  viewBy,
  visibleColumns,
  fields,
  stakeholders = [],
  className,
  trClassName,
  allShareClassNames,
  search = '',
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.FIVE);

  const searchResult = useMemo(
    () => filterByAllFields(stakeholders, search),
    [stakeholders, search],
  );

  const { data, totalPages } = useFakePagination({
    currentPage,
    data: searchResult,
    take: Number(itemsToFetch),
  });

  const [isExpanded, setExpanded] = useState(false);

  const { shareClass } = ShareClass.useDefaultShareClass(isExpanded);

  const defaultShareClassId = shareClass?.id || '';

  const { messagesLocale } = useLocale();

  const isStakeholdersEmpty = stakeholders.length === 0;

  return (
    <>
      <TableRow
        fields={fields}
        handleExpandRow={setExpanded}
        isExpanded={isExpanded}
        isExpandedRow={isStakeholdersEmpty}
        tdClassName={className}
        trClassName={twMerge(isExpanded && 'bg-brand-25', trClassName)}
        type={DetailsSortVariant.BY_STAKEHOLDER}
        viewBy={viewBy}
        visibleColumns={visibleColumns}
      />
      {isExpanded &&
        data.map(
          (
            {
              diluted,
              dilutedPercentage,
              issued,
              issuedPercentage,
              name,
              type,
              votingPercentage,
              shareClasses,
              votingRight,
              invested,
              avgSharePrice,
              converted,
              convertedPercentage,
            },
            i,
          ) => {
            const { defaultShareClass, otherShareClasses } = getShareClasses(
              defaultShareClassId,
              shareClasses,
            );

            const otherShareClassesMap = otherShareClasses
              .sort((a, b) => a.name.localeCompare(b.name))
              .reduce<Record<string, (typeof otherShareClasses)[0]>>((acc, curr) => {
                acc[curr.name] = curr;
                return acc;
              }, {});

            return (
              <TableRow
                className="bg-[#F9FAFB] shadow-[inset_2px_0px_0px_0px_#2565C8]"
                fields={{
                  [Column.NAME]: name,
                  [Column.STAKEHOLDER_NAME]: name,
                  [Column.TYPE]: messagesLocale[stakeholderTypeTranslation[type]],
                  [Column.ISSUED]: toRound(issuedPercentage * 100),
                  [Column.DILUTED]: toRound(dilutedPercentage * 100),
                  [Column.VOTING]: toRound(votingPercentage * 100),
                  [Column.NO_ISSUED]: issued,
                  [Column.DILUTED_SHARES]: diluted.toLocaleString('en-US'),
                  [Column.COMMON_STOCK]: toNumber(defaultShareClass?.issued),
                  [Column.OTHER_SHARE_CLASSES]: allShareClassNames.map((name) => {
                    return otherShareClassesMap[name] ? otherShareClassesMap[name].issued : 0;
                  }),
                  [Column.VOTING_RIGHTS]: votingRight.toLocaleString('en-US'),
                  [Column.INVESTMENT]: invested,
                  [Column.AVERAGE_SHARE_PRICE]: toRound(avgSharePrice),
                  [Column.CONVERTED_BASIS]: converted,
                  [Column.CONVERTED_BASIS_PERCENT]: `${toRound(toNumber(convertedPercentage) * 100)}`,
                }}
                isExpandedRow={true}
                key={i}
                trClassName="bg-brand-25"
                type={DetailsSortVariant.BY_STAKEHOLDER}
                viewBy={viewBy}
                visibleColumns={visibleColumns}
              />
            );
          },
        )}

      {isExpanded && stakeholders.length > 3 && (
        <tr className="shadow-[inset_0px_-1px_0px_0px_#F2F4F7]">
          <td colSpan={visibleColumns.length}>
            <PaginationItem
              currentPage={currentPage}
              handleChangeCurrentPage={setCurrentPage}
              handleFetchAmountChange={setItemsToFetch}
              itemsToFetch={itemsToFetch}
              totalPages={totalPages}
            />
          </td>
        </tr>
      )}
    </>
  );
};
