import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { useFormat } from '../../../hooks';
import { StringKey } from '../../../lang';
import { conversionMethodTitle, DetailsTab, Safe } from '../../../types/safes.types';
import { toNumber } from '../../../utils/toNumber';

export type SafeItemProps = {
  safe: Safe;
  selectedTab: DetailsTab;
};

export const SafeItem: FC<SafeItemProps> = ({ safe, selectedTab }) => {
  const {
    stakeholder,
    investment,
    conversionDate,
    conversionMethod,
    convertedShares,
    floorValue,
    issueDate,
    maturityDate,
    discount,
    capValue,
  } = safe;

  const { format } = useFormat();

  return (
    <div className="flex min-w-[305px] flex-col divide-y-[1px] divide-gray-100 rounded-md shadow-sm">
      <div className="flex w-full items-center gap-3 p-4 pl-3">
        <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
          {stakeholder.fullName[0]}
        </div>
        <span className="w-full truncate text-nowrap text-sm font-[550] text-gray-700">
          {stakeholder.fullName}
        </span>
      </div>
      <div className="flex w-full items-center justify-between p-3 pr-4">
        <span className="text-nowrap text-sm font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.INVESTMENT} />
        </span>
        <span className="text-nowrap text-sm font-[450] text-gray-700">
          {toNumber(investment)?.toLocaleString('en-US')}
        </span>
      </div>
      <div className="flex w-full items-center justify-between p-3 pr-4">
        <span className="text-nowrap text-sm font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.ISSUE_DATE} />
        </span>
        <span className="text-nowrap text-sm font-[450] text-gray-700">
          {issueDate ? format(issueDate, 'dd/MM/yyyy') : '-'}
        </span>
      </div>

      {selectedTab === DetailsTab.PENDING_CONVERSION ? (
        <>
          <div className="flex w-full items-center justify-between p-3 pr-4">
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.VAL_CAP} />
            </span>
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              {capValue ? toNumber(capValue)?.toLocaleString('en-US') : '-'}
            </span>
          </div>
          <div className="flex w-full items-center justify-between p-3 pr-4">
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SHARE_PRICE_DISCOUNT_PERCENTAGE} />
            </span>
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              {discount ? `${discount * 100}%` : '-'}
            </span>
          </div>
          <div className="flex w-full items-center justify-between p-3 pr-4">
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.FLOOR} />
            </span>
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              {floorValue ? toNumber(floorValue)?.toLocaleString('en-US') : '-'}
            </span>
          </div>
          <div className="flex w-full items-center justify-between p-3 pr-4">
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.MATURITY_DATE} />
            </span>
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              {maturityDate ? format(maturityDate, 'dd/MM/yyyy') : '-'}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="flex w-full items-center justify-between p-3 pr-4">
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.CONVERSION_METHOD} />
            </span>
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              {conversionMethod ? (
                <AppFormattedMessage id={conversionMethodTitle[conversionMethod]} />
              ) : (
                '-'
              )}
            </span>
          </div>
          <div className="flex w-full items-center justify-between p-3 pr-4">
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.CONVERSION_DATE} />
            </span>
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              {conversionDate ? format(conversionDate, 'dd/MM/yyyy') : '-'}
            </span>
          </div>
          <div className="flex w-full items-center justify-between p-3 pr-4">
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SHARES} />
            </span>
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              {convertedShares ? toNumber(convertedShares)?.toLocaleString('en-US') : '-'}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
