import { Field, Label } from '@headlessui/react';
import { FC, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, CrossIcon, SearchIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { Checkbox } from '../../../../components/Checkbox';
import { Input } from '../../../../components/Input';
import Loader from '../../../../components/Loader';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/Popover';
import { SafeHooks, useFormat, useModalState } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { Safe } from '../../../../types/safes.types';
import { FormSchema } from '../Validation';

type FilterItemProps = {
  id: string;
  onChange: (state: boolean) => void;
  safe: Safe;
  checked: boolean;
  currencySign?: string;
};

export const FilterItem: FC<FilterItemProps> = ({ id, onChange, safe, checked, currencySign }) => {
  const { format } = useFormat();

  return (
    <Field
      className="relative flex cursor-pointer items-center gap-2 text-sm text-gray-700"
      key={id}
    >
      <div
        className={twMerge(
          'flex w-full items-center justify-between rounded-[4px] px-[6px] py-2',
          checked && 'bg-brand-25',
        )}
      >
        <div className="flex items-center gap-1">
          <Checkbox
            checked={checked}
            id={`select-safe-${id}`}
            onChange={(checked) => {
              onChange(checked);
            }}
          />
          <span className="max-w-[220px] truncate text-sm text-gray-700">
            {safe.stakeholder.fullName}
          </span>
        </div>

        <span className="text-sm text-gray-700">
          {currencySign}
          {safe.investment.toLocaleString('en-US')} - {format(safe.issueDate, 'dd/MM/yyyy')}
        </span>
        <Label
          className="absolute left-0 top-0 h-full w-full cursor-pointer"
          htmlFor={`select-safe-${id}`}
        />
      </div>
    </Field>
  );
};

export type SafeSelectProps = {
  setValue: UseFormSetValue<FormSchema>;
  companyId: string;
  currencySign?: string;
  control: Control<FormSchema>;
};
const take = 20;

export const SafeSelect: FC<SafeSelectProps> = ({ companyId, setValue, currencySign, control }) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const [safeIds, setSafeId] = useState<string[]>(stepOne?.safeIds || []);
  const [search, setSearch] = useState('');

  const handleSelectSafe = (id: string) => {
    const updatedSafes = [...safeIds, id];
    setSafeId(updatedSafes);
    setValue('stepOne.safeIds', updatedSafes);
    setValue(
      'stepOne.safes',
      convertSafesToValidFormat(validSafes.filter((safe) => updatedSafes.includes(safe.id))),
    );
  };

  const handleDeselectSafe = (id: string) => {
    const updatedSafes = safeIds.filter((safeId) => safeId !== id);
    setSafeId(updatedSafes);
    setValue('stepOne.safeIds', updatedSafes);
    setValue(
      'stepOne.safes',
      convertSafesToValidFormat(validSafes.filter((safe) => updatedSafes.includes(safe.id))),
    );
  };

  const { isOpen, toggler } = useModalState();

  const { safes, fetchNextPage, hasNextPage } = SafeHooks.useInfiniteSafes({
    companyId,
    take,
    search,
  });

  const validSafes =
    stepOne?.linkedEvent?.id === 'no-valuation' ? safes.filter((safe) => safe?.floorValue) : safes;

  const convertSafesToValidFormat = (safes: Safe[]) =>
    safes.map((safe) => ({
      id: safe.id,
      stakeholder: {
        fullName: safe.stakeholder.fullName,
        id: safe.stakeholder.id,
      },
      investment: Number(safe.investment),
      issueDate: new Date(safe.issueDate),
      floorValue: Number(safe.floorValue),
    }));

  return (
    <Popover onOpenChange={toggler} open={isOpen}>
      <PopoverTrigger
        className={twMerge(
          'flex w-full items-center justify-between rounded-tl-[4px] rounded-tr-[4px] border-b-[1px] border-gray-700 bg-gray-50 px-3 py-2 text-sm font-[450] text-gray-400',
          isOpen && 'border-brand-700',
          safeIds.length < 2 ? 'min-h-14' : 'h-fit',
        )}
      >
        {safeIds.length === 0 || isOpen ? (
          <span>
            <AppFormattedMessage id={StringKey.SELECT_SAFE} />
          </span>
        ) : (
          <div className="flex flex-wrap items-center gap-2">
            {stepOne?.safes?.slice(0, 2).map((safe) => (
              <div className="flex w-fit items-center gap-3 rounded-3xl bg-brand-50 p-[6px]">
                <span className="max-w-[220px] truncate text-sm text-gray-700">
                  {safe?.stakeholder?.fullName}
                </span>
                <CrossIcon
                  className="size-[14px] cursor-pointer"
                  onClick={() => safe.id && handleDeselectSafe(safe.id)}
                />
              </div>
            ))}
            {stepOne?.safes && stepOne?.safes?.length > 2 && (
              <span className="text-sm text-gray-700">+{stepOne?.safes.length - 2} more</span>
            )}
          </div>
        )}
        <ChevronDownIcon />
      </PopoverTrigger>
      <PopoverContent className="z-[100] flex w-[418px] flex-col rounded-lg bg-gray-100 p-0 font-normal shadow-2xl">
        <div className="flex h-fit w-full flex-col gap-3 rounded-lg bg-white p-2">
          <Input
            icon={<SearchIcon iconColor="#98A2B3" />}
            onChange={setSearch}
            placeholder={<AppFormattedMessage id={StringKey.SEARCH} />}
            value={search}
            wrapperClassName=" w-full"
          />
          {safeIds.length !== 0 && validSafes && (
            <div className="flex flex-wrap items-center gap-2">
              {stepOne?.safes?.map((safe) => (
                <div className="flex w-fit items-center gap-3 rounded-3xl bg-brand-50 p-[6px]">
                  <span className="max-w-[220px] truncate text-sm text-gray-700">
                    {safe?.stakeholder?.fullName}
                  </span>
                  <CrossIcon
                    className="size-[14px] cursor-pointer"
                    onClick={() => safe.id && handleDeselectSafe(safe.id)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="flex h-fit max-h-[300px] w-full flex-col gap-1 overflow-y-auto">
            <InfiniteScroll
              className="flex h-fit w-full flex-col items-stretch justify-start gap-2 max-lg:justify-center"
              dataLength={validSafes.length}
              hasMore={hasNextPage}
              loader={<Loader />}
              next={fetchNextPage}
              scrollableTarget="scrollbar-target"
            >
              {validSafes.map((safe) => (
                <FilterItem
                  checked={safeIds.includes(safe.id)}
                  currencySign={currencySign}
                  id={safe.id}
                  key={safe.id}
                  onChange={(checked) =>
                    checked ? handleSelectSafe(safe.id) : handleDeselectSafe(safe.id)
                  }
                  safe={safe}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
