import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { CheckIcon, InfoCircle } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import { FormInput, Input } from '../../../../components/Input';
import { FormRadio, FormRadioGroup } from '../../../../components/RadioGroup';
import { useDebounce, useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ValuationSelect, ValuationSelectTitle } from '../../../../types/events.types';
import { FormSchema } from '../validation';

export type ValuationStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  issuedShares: number;
  lockMode?: boolean;
};

export const ValuationStepOne: FC<ValuationStepOneProps> = ({
  control,
  setFormData,
  issuedShares,
  lockMode,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();
  const event = stepOne?.eventDetails?.valuation;
  const [preMoneyValuation, setPreMoneyValuation] = useState<number>(0);
  const [sharePrice, setSharePrice] = useState<number>(0);

  const { debounceCallback: debouncePreMoneyValuation } = useDebounce({
    callback: (calculatedPreMoneyValuation: number) => {
      setPreMoneyValuation(calculatedPreMoneyValuation);
      setFormData('stepOne.eventDetails.valuation.preMoneyValuation', calculatedPreMoneyValuation);
    },
    debounce: 100,
  });

  const { debounceCallback: debounceSharePrice } = useDebounce({
    callback: (calculatedSharePrice: number) => {
      setSharePrice(calculatedSharePrice);
      setFormData('stepOne.eventDetails.valuation.sharePrice', calculatedSharePrice);
    },
  });

  const issuedSharesValue =
    stepOne?.eventDetails?.valuation?.issuedSharesOnStart !== undefined
      ? Number(stepOne?.eventDetails?.valuation?.issuedSharesOnStart)
      : Number(issuedShares);

  useEffect(() => {
    const calculatedPreMoneyValuation =
      lockMode && event?.sharePrice && event?.issuedSharesOnStart !== undefined
        ? event.sharePrice * event.issuedSharesOnStart
        : event?.sharePrice
          ? event.sharePrice * Number(issuedShares)
          : 0;
    debouncePreMoneyValuation(calculatedPreMoneyValuation);
  }, [issuedShares, event?.sharePrice, event?.issuedSharesOnStart, lockMode, setFormData]);

  useEffect(() => {
    const calculatedSharePrice =
      lockMode && event?.preMoneyValuation && event?.issuedSharesOnStart !== undefined
        ? event.preMoneyValuation / event.issuedSharesOnStart
        : event?.preMoneyValuation
          ? event.preMoneyValuation / (Number(issuedShares) || 1)
          : 0;
    debounceSharePrice(calculatedSharePrice);
  }, [issuedShares, event?.preMoneyValuation, event?.issuedSharesOnStart, lockMode, setFormData]);

  useEffect(() => {
    if (lockMode || (!Number(issuedShares) && !event?.issuedSharesOnStart)) {
      setFormData('stepOne.eventDetails.valuation.valuationSelect', ValuationSelect.SHARE_PRICE);
    }
  }, [event?.issuedSharesOnStart, issuedShares, lockMode, setFormData]);

  return (
    <div className="flex w-full flex-col gap-8">
      <div className="flex w-full flex-col gap-4">
        <FormInput
          control={control}
          name={'stepOne.eventDetails.valuation.name'}
          placeholder={<AppFormattedMessage id={StringKey.EVENT_NAME} />}
          wrapperClassName="w-full"
        />
        <FormDatePicker
          defaultMonth={
            stepOne?.eventDetails?.valuation?.date
              ? new Date(stepOne?.eventDetails?.valuation?.date)
              : undefined
          }
          iconColor="#98A2B3"
          inputValue={
            stepOne?.eventDetails?.valuation?.date
              ? format(stepOne?.eventDetails?.valuation?.date, 'dd/MM/yyyy')
              : ''
          }
          inputWrapperClassName="w-full"
          onSelect={(date) => {
            setFormData('stepOne.eventDetails.valuation.date', date);
          }}
          placeholder="Event Date"
          value={stepOne?.eventDetails?.valuation?.date}
        />
      </div>
      <FormRadioGroup
        className={'flex shrink-0 gap-4 divide-x-0 overflow-x-auto'}
        control={control}
        name="stepOne.eventDetails.valuation.valuationSelect"
      >
        {Object.entries(ValuationSelectTitle).map(([valuationKey, valuationTitle]) => (
          <div className="flex items-center gap-2" key={valuationKey}>
            <FormRadio
              className={
                'flex size-4 items-center justify-center overflow-hidden rounded-full border border-gray-300 data-[checked]:border-none data-[checked]:bg-brand-500'
              }
              control={control}
              disabled={!Number(issuedShares) && !event?.issuedSharesOnStart}
              name="stepOne.eventDetails.valuation.valuationSelect"
              onClick={() => {
                setFormData('stepOne.eventDetails.valuation.preMoneyValuation', 0);
                setFormData('stepOne.eventDetails.valuation.sharePrice', 0);
              }}
              styleType="NONE"
              value={valuationKey}
            >
              <CheckIcon
                className="hidden size-[6.5px] group-data-[checked]:block"
                iconColor={'#ffffff'}
              />
            </FormRadio>
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={valuationTitle} />
            </span>
          </div>
        ))}
      </FormRadioGroup>
      {stepOne?.eventDetails?.valuation?.valuationSelect === ValuationSelect.SHARE_PRICE && (
        <div className="flex flex-col gap-6">
          <FormInput
            control={control}
            currencySign
            name={'stepOne.eventDetails.valuation.sharePrice'}
            placeholder={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
            shouldFormatNumber
            wrapperClassName="w-full"
          />
          <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 pb-1 pt-3">
            <div className="flex flex-col items-start gap-1 pl-[14px]">
              <div className="flex items-center gap-2">
                <span className="text-sm font-[550] text-brand-700">
                  <AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />
                </span>
                <HoverCard>
                  <HoverCardTrigger>
                    <InfoCircle className="size-4 cursor-pointer" iconColor="#2565C8 " />
                  </HoverCardTrigger>
                  <HoverCardContent
                    className="z-20 w-max rounded-lg bg-[#101828] px-3 pb-[6px] pt-1"
                    isShownTriangle
                    side="top"
                    triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
                  >
                    <span className="text-label-md font-[550] text-white">
                      <AppFormattedMessage id={StringKey.CALCULATED_BY} />
                    </span>
                  </HoverCardContent>
                </HoverCard>
              </div>
              <span className="text-xs font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.ISSUED_SHARES} />:{' '}
                {issuedSharesValue !== undefined && issuedSharesValue !== null
                  ? issuedSharesValue.toLocaleString('en-US')
                  : 0}
              </span>
            </div>
            <div className="flex flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
              <Input
                currencySign
                disabled
                onChange={() => {}}
                placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                value={preMoneyValuation.toLocaleString('en-US')}
                wrapperClassName="w-full"
              />
              <DropDown
                answer="The Pre-Money Valuation is determined by multiplying the share price entered by the user with the total number of issued shares at the time of the event. This value reflects the estimated value of the company before any new funding or investment is taken into account."
                question={<AppFormattedMessage id={StringKey.VALUE_CALCULATION_INFO} />}
              />
            </div>
          </div>
        </div>
      )}
      {stepOne?.eventDetails?.valuation?.valuationSelect ===
        ValuationSelect.PRE_MONEY_VALUATION && (
        <div className="flex flex-col gap-6">
          <FormInput
            control={control}
            currencySign
            name={'stepOne.eventDetails.valuation.preMoneyValuation'}
            placeholder={<AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />}
            shouldFormatNumber
            wrapperClassName="w-full"
          />
          <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 pb-1 pt-3">
            <div className="flex flex-col items-start gap-1 pl-[14px]">
              <div className="flex items-center gap-2">
                <span className="text-sm font-[550] text-brand-700">
                  <AppFormattedMessage id={StringKey.SHARES_PRICE} />
                </span>
                <HoverCard>
                  <HoverCardTrigger>
                    <InfoCircle className="size-4 cursor-pointer" iconColor="#2565C8 " />
                  </HoverCardTrigger>
                  <HoverCardContent
                    className="z-20 w-max rounded-lg bg-[#101828] px-3 pb-[6px] pt-1"
                    isShownTriangle
                    side="top"
                    triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
                  >
                    <span className="text-label-md font-[550] text-white">
                      <AppFormattedMessage id={StringKey.CALCULATED_BY} />
                    </span>
                  </HoverCardContent>
                </HoverCard>
              </div>
              <span className="text-xs font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.ISSUED_SHARES} />:{' '}
                {issuedSharesValue !== undefined && issuedSharesValue !== null
                  ? issuedSharesValue.toLocaleString('en-US')
                  : 0}
              </span>
            </div>
            <div className="flex flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
              <Input
                currencySign
                disabled
                onChange={() => {}}
                placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                value={sharePrice.toLocaleString('en-US')}
                wrapperClassName="w-full"
              />
              <DropDown
                answer="The Share Price is determined by dividing the Pre-Money Valuation by the total number of issued shares at the time of the event. This value reflects the price of a single share of the company before any new funding or investment is taken into account."
                question={<AppFormattedMessage id={StringKey.VALUE_CALCULATION_INFO} />}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
