import { createBrowserRouter } from 'react-router-dom';

import AppLayout from './components/AppLayout';
import { AuthGuard } from './guards';
import AcceptInvitation from './pages/AcceptInvitation';
import AllDonePage from './pages/AllDonePage';
import Billing from './pages/Billing';
import CapTable from './pages/CapTable';
import { CapTableMobileDetailsPage } from './pages/CapTable/CapTableDetailsPage';
import { CapTableMobileStakeholderDetailsPage } from './pages/CapTable/CapTableStakeholderDetailsPage';
import CompanySettings from './pages/CompanySettings';
import CompanySetup from './pages/CompanySetup';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error';
import Events from './pages/Events';
import { TransactionsList } from './pages/Events/Mobile/TransactionsList';
import ForgotPassword from './pages/ForgotPassword';
import HangTight from './pages/HangTight';
import Home from './pages/Home';
import Login from './pages/Login';
import { ModifyGuide } from './pages/ModifyGuide';
import MyCompanies from './pages/MyCompanies';
import MyHoldings from './pages/MyHoldings';
import Onboarding from './pages/Onboarding/Onboarding';
import PasswordRecovery from './pages/PasswordRecovery/PasswordRecovery';
import PlanDetails from './pages/PlanDetails';
import PoolDetails from './pages/PoolDetails';
import PoolPlans from './pages/PoolPlans';
import Profile from './pages/Profile';
import Register from './pages/Register';
import RegisterVerify from './pages/RegisterVerify';
import Safes from './pages/Safes/Safes';
import Settings from './pages/Settings';
import ShareClasses from './pages/ShareClasses';
import Stakeholders from './pages/Stakeholders';
import { Terms } from './pages/Terms';
import Vault from './pages/Vault/Vault';
import { PageRoute } from './types/pageTypes';

const router = createBrowserRouter([
  {
    path: PageRoute.HOME,
    element: (
      <AuthGuard>
        <AppLayout>
          <Home />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.TERMS,
    element: <Terms />,
  },
  {
    path: PageRoute.MODIFY_GUIDE,
    element: <ModifyGuide />,
  },
  {
    path: PageRoute.PROFILE,
    element: (
      <AuthGuard>
        <AppLayout>
          <Profile />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.MY_COMPANIES,
    element: (
      <AuthGuard>
        <AppLayout>
          <MyCompanies />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.COMPANY_SETUP + '/:id',
    element: (
      <AuthGuard>
        <AppLayout>
          <CompanySetup />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.COMPANY_SETTINGS,
    element: (
      <AuthGuard>
        <AppLayout>
          <CompanySettings />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.DASHBOARD,
    element: (
      <AuthGuard>
        <AppLayout>
          <Dashboard />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.STAKEHOLDERS,
    element: (
      <AuthGuard>
        <AppLayout>
          <Stakeholders />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.SHARE_CLASSES,
    element: (
      <AuthGuard>
        <AppLayout className="max-lg:bg-transparent max-lg:p-0">
          <ShareClasses />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.POOL_PLANS,
    element: (
      <AuthGuard>
        <AppLayout>
          <PoolPlans />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.POOL_PLANS_POOL,
    element: (
      <AuthGuard>
        <AppLayout>
          <PoolDetails />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.POOL_PLANS_PLAN,
    element: (
      <AuthGuard>
        <AppLayout>
          <PlanDetails />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.EVENTS,
    element: (
      <AuthGuard>
        <AppLayout className="max-lg:!bg-transparent max-lg:!p-0 max-lg:!shadow-none">
          <Events />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.EVENTS + '/:id',
    element: (
      <AuthGuard>
        <AppLayout className="!rounded-md !px-3 !py-4 !pt-0">
          <TransactionsList />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.CAP_TABLE,
    element: (
      <AuthGuard>
        <AppLayout>
          <CapTable />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.CAP_TABLE + '/:id',
    element: (
      <AuthGuard>
        <AppLayout className="max-lg:!bg-transparent max-lg:!p-0 max-lg:!shadow-none">
          <CapTableMobileDetailsPage />
        </AppLayout>
      </AuthGuard>
    ),
  },

  {
    path: PageRoute.CAP_TABLE + '/:id' + '/:stakeholderType',
    element: (
      <AuthGuard>
        <AppLayout className="max-lg:!bg-transparent max-lg:!p-0 max-lg:!shadow-none">
          <CapTableMobileStakeholderDetailsPage />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.SAFES,
    element: (
      <AuthGuard>
        <AppLayout>
          <Safes />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.MY_HOLDINGS,
    element: (
      <AuthGuard>
        <AppLayout>
          <MyHoldings />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.BILLING + '/:id',
    element: (
      <AuthGuard>
        <AppLayout>
          <Billing />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.SETTINGS,
    element: (
      <AuthGuard>
        <AppLayout>
          <Settings />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.ALL_DONE,
    element: (
      <AuthGuard>
        <AppLayout>
          <AllDonePage />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.HANG_TIGHT,
    element: (
      <AuthGuard>
        <AppLayout>
          <HangTight />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.VAULT,
    element: (
      <AuthGuard>
        <AppLayout>
          <Vault />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.ACCEPT_INVITATION,
    element: <AcceptInvitation />,
  },
  {
    path: PageRoute.LOGIN,
    element: <Login />,
  },
  {
    path: PageRoute.REGISTER,
    element: <Register />,
  },
  {
    path: PageRoute.REGISTER_VERIFY,
    element: <RegisterVerify />,
  },
  {
    path: PageRoute.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: PageRoute.PASSWORD_RECOVERY,
    element: <PasswordRecovery />,
  },

  {
    path: PageRoute.ONBOARDING,
    element: (
      <AppLayout>
        <Onboarding />
      </AppLayout>
    ),
  },

  {
    path: PageRoute.ERROR,
    element: <Error />,
  },
]);

export default router;
