import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { StringKey } from '../../../../lang';
import { stakeholderTypeTitle } from '../../../../types/stakeholderTypes';
import { ChildrenFormProps } from '../type';

export type FinalStepProps = ChildrenFormProps;

export const FinalStep: FC<FinalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  formData,
  prevFormStep,
  isLoading,
}) => {
  const { stepOne, stepTwo } = formData();

  const handleDocumentClick = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.GENERAL} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.FULL_NAME} />}
              value={stepOne.fullName}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.EMAIL} />}
              value={stepOne.email}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.STAKEHOLDER_TYPE} />}
              value={<AppFormattedMessage id={stakeholderTypeTitle[stepOne.stakeholderType]} />}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.PASSPORT_NO} />}
              value={stepOne.human?.passportNo || '-'}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.PASSPORT_EXPIRY_DATE} />}
              value={
                stepOne.human?.passportExpiryDate
                  ? `${stepOne.human?.passportExpiryDate?.day}/${stepOne.human?.passportExpiryDate?.month + 1}/${stepOne.human?.passportExpiryDate?.year}`
                  : '-'
              }
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.NATIONALITY} />}
              value={stepOne.human?.nationality?.name || '-'}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.BIRTHDAY} />}
              value={
                stepOne.human?.birthdayDate
                  ? `${stepOne.human?.birthdayDate?.day}/${stepOne.human?.birthdayDate?.month + 1}/${stepOne.human?.birthdayDate?.year}`
                  : '-'
              }
            />
            <SummaryItem
              className="pb-0"
              title={<AppFormattedMessage id={StringKey.PHONE_NUMBER} />}
              value={
                stepOne.phoneCountry && stepOne.phoneNumber
                  ? `${stepOne.phoneCountry.prefix} ${stepOne.phoneNumber}`
                  : '-'
              }
            />
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className={'flex gap-3 text-nowrap px-4 py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {(stepTwo?.files?.length || 0) > 0 ? (
                stepTwo?.files?.map(({ doc, docLink }) => (
                  <div
                    className="cursor-pointer truncate text-xs font-[450] text-brand-700 underline"
                    key={docLink}
                    onClick={() => handleDocumentClick(docLink)}
                  >
                    {doc?.name}
                  </div>
                ))
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className="flex w-full gap-3 px-4 py-2">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div className="max-w-[250px] break-words text-xs font-[450] text-gray-700">
              {stepTwo?.additionalNotes || '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>

          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            isLoading={isLoading}
            onClick={nextFormStep}
            type="button"
          >
            <AppFormattedMessage id={StringKey.ADD_STAKEHOLDER} />
          </Button>
        </div>
      </div>
    </>
  );
};
