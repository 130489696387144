import { FC, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import { SafeHooks, useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { safesSortStore } from '../../../storage/safesSortStore';
import { DetailsTab, detailsTabTitleMap, SafeOrder, SafeSortBy } from '../../../types/safes.types';
import { SelectedSafe } from '../../Safes';
import { TableSortingCell } from '../../Safes/Desktop/TableSortingCell';
import { EmptySafes } from '../../Safes/EmptySafes';
import { EditSafeModal } from '../../Safes/Modals/Edit';
import { SafeTableItem } from './SafeTableItem';

export type StakeholderSafesProps = {
  companyId: string;
  stakeholderId: string;
};

export const StakeholderSafes: FC<StakeholderSafesProps> = ({ companyId, stakeholderId }) => {
  const [selectedTab, setSelectedTab] = useState<DetailsTab>(DetailsTab.PENDING_CONVERSION);
  const [sortState, setSortState] = useState<{
    field: SafeSortBy | null;
    sortMode: SafeOrder | null;
  }>(safesSortStore.get() || { field: null, sortMode: null });
  const [selectedSafe, setSelectedSafe] = useState<SelectedSafe | null>({
    id: '',
    step: 1,
    mode: 'EDIT',
  });

  const { safes, invalidateQuery, isLoading } = SafeHooks.useStakeholderSafes({
    currentPage: 1,
    sortOrder: sortState.sortMode,
    sortBy: sortState.field,
    companyId,
    stakeholderId,
    numberOfFetchedItems: 100,
    search: '',
    convertedOnly: selectedTab === DetailsTab.CONVERTED,
  });

  const handleSortField = useCallback(
    (newField: SafeSortBy) => {
      setSortState((prevState) => {
        if (prevState.field === newField) {
          const nextSortMode = !prevState.sortMode
            ? SafeOrder.ASC
            : prevState.sortMode === SafeOrder.ASC
              ? SafeOrder.DESC
              : null;

          safesSortStore.set({ ...prevState, sortMode: nextSortMode });
          return { ...prevState, sortMode: nextSortMode };
        } else {
          safesSortStore.set({
            field: newField,
            sortMode: SafeOrder.ASC,
          });
          return { field: newField, sortMode: SafeOrder.ASC };
        }
      });
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const {
    handleCloseModal: handleCloseEditSafeModal,
    handleOpenModal: handleOpenEditSafeModal,
    isOpen: isOpenEditSafeModal,
  } = useModalState({});

  return (
    <>
      <EditSafeModal
        companyId={companyId}
        handleClose={handleCloseEditSafeModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditSafeModal}
        onEditClick={({ id, step }) => setSelectedSafe({ id, mode: 'EDIT', step })}
        previewMode={selectedSafe?.mode === 'VIEW' || undefined}
        safeId={selectedSafe?.id || ''}
        step={selectedSafe?.step || 1}
      />

      <div className="flex w-full flex-col gap-4 rounded-lg bg-white p-4 pb-0 shadow-sm">
        <div className="flex h-9 w-fit divide-x divide-gray-200 rounded border border-gray-200">
          {detailsTabTitleMap.map(([filterKey, filter]) => (
            <Button
              className={twMerge(
                'h-full w-fit text-nowrap rounded-none px-4 text-sm font-[450] text-[#858593]',
                selectedTab === filterKey && 'bg-brand-25 text-sm font-[550] text-[#172335]',
              )}
              key={filterKey}
              onClick={() => {
                setSelectedTab(filterKey);
              }}
              styleType="NONE"
            >
              <AppFormattedMessage id={filter} />
            </Button>
          ))}
        </div>

        <div className="flex h-full w-full flex-col overflow-x-auto">
          <table className="h-fit w-full divide-y-[1px] divide-[#F2F2F2] border-b-[1px] border-[#F2F2F2] max-lg:hidden">
            <thead>
              <tr className="h-11 bg-gray-50">
                <td className="pl-4 text-label-md font-[450] text-[#172335]">
                  <AppFormattedMessage id={StringKey.INVESTMENT} />
                </td>

                <td className="pl-4">
                  <TableSortingCell
                    onSortClick={(newField) => handleSortField(newField)}
                    sortByCell={SafeSortBy.ISSUE_DATE}
                    sortField={sortState.field}
                    sortMode={sortState.sortMode}
                    title={<AppFormattedMessage id={StringKey.ISSUE_DATE} />}
                  />
                </td>

                {selectedTab === DetailsTab.PENDING_CONVERSION ? (
                  <>
                    <td className="pl-4">
                      <TableSortingCell
                        onSortClick={(newField) => handleSortField(newField)}
                        sortByCell={SafeSortBy.CAP_VALUE}
                        sortField={sortState.field}
                        sortMode={sortState.sortMode}
                        title={<AppFormattedMessage id={StringKey.VAL_CAP} />}
                      />
                    </td>

                    <td className="pl-4">
                      <TableSortingCell
                        onSortClick={(newField) => handleSortField(newField)}
                        sortByCell={SafeSortBy.DISCOUNT}
                        sortField={sortState.field}
                        sortMode={sortState.sortMode}
                        title={
                          <AppFormattedMessage id={StringKey.SHARE_PRICE_DISCOUNT_PERCENTAGE} />
                        }
                      />
                    </td>

                    <td className="pl-4">
                      <TableSortingCell
                        onSortClick={(newField) => handleSortField(newField)}
                        sortByCell={SafeSortBy.FLOOR}
                        sortField={sortState.field}
                        sortMode={sortState.sortMode}
                        title={<AppFormattedMessage id={StringKey.FLOOR} />}
                      />
                    </td>

                    <td className="pl-4">
                      <TableSortingCell
                        onSortClick={(newField) => handleSortField(newField)}
                        sortByCell={SafeSortBy.MATURITY_DATE}
                        sortField={sortState.field}
                        sortMode={sortState.sortMode}
                        title={<AppFormattedMessage id={StringKey.MATURITY_DATE} />}
                      />
                    </td>
                  </>
                ) : (
                  <>
                    <td className="pl-4">
                      <TableSortingCell
                        onSortClick={(newField) => handleSortField(newField)}
                        sortByCell={SafeSortBy.CONVERSION_METHOD}
                        sortField={sortState.field}
                        sortMode={sortState.sortMode}
                        title={<AppFormattedMessage id={StringKey.CONVERSION_METHOD} />}
                      />
                    </td>
                    <td className="pl-4">
                      <TableSortingCell
                        onSortClick={(newField) => handleSortField(newField)}
                        sortByCell={SafeSortBy.CONVERSION_DATE}
                        sortField={sortState.field}
                        sortMode={sortState.sortMode}
                        title={<AppFormattedMessage id={StringKey.CONVERSION_DATE} />}
                      />
                    </td>
                    <td className="pl-4">
                      <TableSortingCell
                        onSortClick={(newField) => handleSortField(newField)}
                        sortByCell={SafeSortBy.CONVERTED_SHARES}
                        sortField={sortState.field}
                        sortMode={sortState.sortMode}
                        title={<AppFormattedMessage id={StringKey.CONVERTED_SHARES} />}
                      />
                    </td>
                  </>
                )}

                <td></td>
              </tr>
            </thead>
            <tbody className="divide-y-[1px] divide-[#F2F2F2]">
              {isLoading && (
                <tr>
                  <td className="ml-auto mr-auto w-full py-4" colSpan={100}>
                    <Loader />
                  </td>
                </tr>
              )}
              {safes && safes.length === 0 ? (
                <tr>
                  <td className="ml-auto mr-auto w-full pt-4" colSpan={100}>
                    <EmptySafes
                      isMyHoldingsPage
                      text={
                        selectedTab === DetailsTab.CONVERTED
                          ? 'When available, converted SAFEs will be displayed here'
                          : 'When available, SAFEs will be displayed here'
                      }
                      title={
                        selectedTab === DetailsTab.CONVERTED ? (
                          <AppFormattedMessage id={StringKey.NO_SAFES_CONVERTED} />
                        ) : (
                          <AppFormattedMessage id={StringKey.NO_SAFES_FOUND} />
                        )
                      }
                    />
                  </td>
                </tr>
              ) : (
                <>
                  {safes?.map((safe) => (
                    <SafeTableItem
                      handleView={(safeId) => {
                        handleOpenEditSafeModal();
                        setSelectedSafe({
                          id: safeId,
                          mode: 'VIEW',
                          step: 4,
                        });
                      }}
                      key={safe.id}
                      safe={safe}
                      selectedTab={selectedTab}
                    />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
