import React, { FC, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Checkbox } from '../../../../components/Checkbox';
import { FormInput } from '../../../../components/Input';
import { StringKey } from '../../../../lang';
import { b2bStakeholderTypes, b2cStakeholderTypes } from '../../../../types/stakeholderTypes';
import BirthdayDateSelect from '../DateCombobox/BirthdayDateSelect';
import IncorporationCompanyDateSelect from '../DateCombobox/IncorporationCompanyDateSelect';
import PassportExpiryDateSelect from '../DateCombobox/PassportExpiryDateSelect';
import IncorporationCountryCombobox, { SelectedCountry } from '../IncorporationCountryCombobox';
import NationalityCombobox, { SelectedNationality } from '../NationalityCombobox';
import PhoneNumberCombobox, { PhoneCountry } from '../PhoneNumberCombobox';
import StakeholderTypeCombobox from '../StakeholderTypeCombobox';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepOne as stepOneFormSchema } from '../Validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  lockMode,
  filedState,
  disabled,
  resetField,
  isDemo,
}) => {
  const [isOpenEquityOptions, setOpenEquityOptions] = useState(true);
  const { stepOne } = useWatch<FormSchema>({ control });
  const { success } = stepOneFormSchema.safeParse(stepOne);

  const selectedNationality = stepOne?.human?.nationality;

  const selectedIncorporationCountry = stepOne?.company?.incorporationCountry;

  const selectedPhoneNumber = stepOne?.phoneNumber;

  const selectedPhoneCountry = stepOne?.phoneCountry;

  const selectedStakeholderType = stepOne?.stakeholderType;

  const isB2BStakeholderType = useMemo(
    () => (selectedStakeholderType ? b2bStakeholderTypes.includes(selectedStakeholderType) : false),
    [selectedStakeholderType],
  );

  const isB2CStakeholderType = useMemo(
    () => (selectedStakeholderType ? b2cStakeholderTypes.includes(selectedStakeholderType) : false),
    [selectedStakeholderType],
  );

  const isValid = success && !filedState('stepOne').invalid;

  stepOne;

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <FormInput
          control={control}
          name="stepOne.fullName"
          placeholder={<AppFormattedMessage id={StringKey.FULL_NAME} />}
        />
        <FormInput
          control={control}
          name="stepOne.email"
          placeholder={<AppFormattedMessage id={StringKey.EMAIL} />}
        />
        <StakeholderTypeCombobox
          control={control}
          disabled={disabled}
          onSelect={() => {
            resetField('stepOne.company');
            resetField('stepOne.human');
            resetField('stepOne.phoneNumber');
            resetField('stepOne.address');
          }}
        />

        {isB2BStakeholderType && (
          <>
            <FormInput
              control={control}
              name="stepOne.human.passportNo"
              placeholder={<AppFormattedMessage id={StringKey.PASSPORT_NO_OPTIONAL} />}
            />
            <PassportExpiryDateSelect control={control} setValue={setFormData} />
            <NationalityCombobox
              control={control}
              selectedNationality={selectedNationality as SelectedNationality}
              setFormData={setFormData}
            />
            <BirthdayDateSelect control={control} setValue={setFormData} />
          </>
        )}
        {isB2CStakeholderType && (
          <>
            <IncorporationCountryCombobox
              control={control}
              selectedIncorporationCountry={selectedIncorporationCountry as SelectedCountry}
            />
            <FormInput
              control={control}
              name="stepOne.company.identifier"
              placeholder={<AppFormattedMessage id={StringKey.COMPANY_IDENTIFIER} />}
            />

            <IncorporationCompanyDateSelect control={control} setValue={setFormData} />
          </>
        )}

        {(isB2CStakeholderType || isB2BStakeholderType) && (
          <>
            <PhoneNumberCombobox
              onPhoneChange={(phoneNumber) =>
                setFormData('stepOne.phoneNumber', phoneNumber, {
                  shouldValidate: true,
                })
              }
              onSelectCountry={(country) => setFormData('stepOne.phoneCountry', country)}
              selectedPhoneNumber={{
                number: selectedPhoneNumber || '',
                country: selectedPhoneCountry as PhoneCountry,
              }}
            />
            <FormInput
              control={control}
              name="stepOne.address"
              placeholder={
                <>
                  <AppFormattedMessage id={StringKey.ADDRESS_OPTIONAL} />
                </>
              }
            />
            <div className="flex h-fit w-full flex-col gap-3 rounded bg-gray-50 p-3 pl-2">
              <span className="text-base font-semibold text-gray-700">
                <AppFormattedMessage id={StringKey.ACCESS_AND_PERMISSIONS} />
              </span>
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={stepOne?.access?.capTable}
                    onChange={(e) => {
                      setFormData('stepOne.access.capTable', e);
                    }}
                  />
                  <span className="text-sm font-[450] text-gray-700">Cap Table</span>
                </div>
                <Button
                  className="flex h-fit w-fit items-center gap-1"
                  onClick={() => setOpenEquityOptions((prev) => !prev)}
                  styleType="NONE"
                  type="button"
                >
                  <Checkbox checked={isOpenEquityOptions} disabled />
                  <span className="text-sm font-[450] text-gray-700">
                    <AppFormattedMessage id={StringKey.MY_HOLDING_EQUITY} />
                  </span>
                  <ChevronDownIcon
                    className={twMerge(
                      'size-4 transition-all',
                      isOpenEquityOptions && 'rotate-180',
                    )}
                  />
                </Button>
                <div
                  className={twMerge(
                    'ml-[30px] flex h-0 flex-col gap-2 overflow-hidden transition-all duration-500',
                    isOpenEquityOptions && 'h-fit',
                  )}
                >
                  <div className="flex items-center gap-1">
                    <Checkbox
                      checked={stepOne?.access?.position}
                      onChange={(e) => setFormData('stepOne.access.position', e)}
                    />
                    <span className="text-sm font-[450] text-gray-700">
                      <AppFormattedMessage id={StringKey.YOUR_POSITION_ISSUED} />
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Checkbox
                      checked={stepOne?.access?.equity}
                      onChange={(e) => setFormData('stepOne.access.equity', e)}
                    />
                    <span className="text-sm font-[450] text-gray-700">
                      <AppFormattedMessage id={StringKey.EQUITY_AND_CAPITAL_CHANGE} />
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Checkbox
                      checked={stepOne?.access?.companyTable}
                      onChange={(e) => setFormData('stepOne.access.companyTable', e)}
                    />
                    <span className="text-sm font-[450] text-gray-700">
                      Company Table (of{' '}
                      <i>
                        <AppFormattedMessage id={StringKey.EQUITY_AND_CAPITAL_CHANGE} />
                      </i>
                      )
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid || isDemo}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
