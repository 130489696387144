export enum QueryKey {
  //CRUD STAKEHOLDERS
  CREATE_HUMAN_STAKEHOLDER = 'create-human-stakeholder',
  CREATE_COMPANY_STAKEHOLDER = 'create-company-stakeholder',
  GET_STAKEHOLDER = 'get-stakeholder',
  GET_STAKEHOLDER_EXCLUDED_BALANCE = 'get-stakeholder-excluded-balance',
  GET_STAKEHOLDERS = 'get-stakeholders',
  PATCH_STAKEHOLDER = 'patch-stakeholder',
  DELETE_STAKEHOLDER = 'delete-stakeholder',
  TERMINATE_STAKEHOLDERS = 'terminate-stakeholders',
  PATCH_TERMINATE_STAKEHOLDERS = 'patch-terminate-stakeholders',
  CALC_FINAL_EXERCISE_DATE = 'calc-final-exercise-date',
  GET_STAKEHOLDER_BALANCE = 'get-stakeholder-balance',
  GET_STAKEHOLDER_OWNERSHIP_SUMMARY = 'get-stakeholder-ownership-summary',
  GET_STAKEHOLDERS_WITH_GRANTS = 'get-stakeholders-with-grants',
  GET_STAKEHOLDERS_WITH_SHARES = 'get-stakeholders-with-shares',
  GET_STAKEHOLDERS_FOR_EXERCISE = 'get-stakeholders-for-exercise',

  //CRUD SHARE CLASSES
  GET_SHARE_CLASSES = 'get-share-classes',
  CREATE_SHARE_CLASS = 'create-share-class',
  GET_SHARE_CLASS = 'get-share-class',
  GET_DEFAULT_SHARE_CLASS = 'get-default-share-class',
  PATCH_SHARE_CLASS = 'patch-share-class',
  DELETE_SHARE_CLASS = 'delete-share-class',
  SET_SHARE_CLASS_DOC = 'set-share-class-doc',
  SHARE_CLASS_NAME_CHECK = 'share-class-name-check',

  //CRUD POOL
  GET_POOL = 'get-pool',
  GET_POOL_BY_SHARE_PLAN = 'get-pool-by-share-plan',
  GET_POOLS = 'get-pools',
  CREATE_POOL = 'create-pool',
  PATCH_POOL = 'patch-pool',
  TERMINATE_POOL = 'terminate-pool',
  GET_TERMINATED_POOLS = 'get-terminated-pools',
  DELETE_POOL = 'delete-pool',
  GET_POOLS_BY_STAKEHOLDER = 'get-pools-by-stakeholder',
  POOL_NAME_CHECK = 'pool-name-check',

  //STAKEHOLDER ACTIONS
  GET_INVITATION = 'get-invitation',
  ACCEPT_INVITE_STAKEHOLDER = 'accept-invite-stakeholder',
  INVITE_STAKEHOLDER = 'invite-stakeholder',
  CANCEL_STAKEHOLDER_INVITATION = 'cancel-stakeholder-invitation',
  TOGGLE_STAKEHOLDER_STATUS = 'toggle-stakeholder-status',
  TOGGLE_STAKEHOLDER_ACCESS = 'toggle-stakeholder-access',
  BULK_STAKEHOLDER_ACTIONS = 'bulk-stakeholder-actions',
  STAKEHOLDERS_BULK_MODIFY = 'stakeholders-bulk-modify',
  STAKEHOLDERS_BULK_IMPORT = 'stakeholders-bulk-import',
  STAKEHOLDERS_DOWNLOAD_INFO = 'stakeholders-download-info',
  STAKEHOLDERS_DOWNLOAD_TEMPLATE = 'stakeholders-download-template',
  STAKEHOLDERS_EXPORT = 'stakeholders-export',

  //STRIPE
  GET_RETRIEVE_PAYMENT_INTENT = 'get-retrieve-payment-intent',
  GET_CLIENT_SECRET = 'get-client-secret',
  GET_PAYMENT_DATA = 'get-payment-data',
  GET_PAYMENT_SESSION_ID = 'get-payment-session-id',
  SUBSCRIPTION_PAYMENT = 'subscription-payment',

  //CRUD USER
  GET_USER = 'get-user',
  PATCH_USER = 'patch-user',
  DELETE_USER = 'delete-user',

  //USER ACTIONS
  GET_LOGOUT = 'get-logout',

  //TARGET BASED MILESTONES
  GET_TARGET_BASED_MILESTONES = 'get-target-based-milestones',
  APPROVE_TARGET_BASED_MILESTONE = 'approve-target-based-milestone',
  //CHECK
  PASSWORD_RECOVERY_ID_CHECK = 'password-recovery-id-check',
  VERIFICATION_ID_CHECK = 'verification-id-check',
  AUTH_CHECK = 'auth-check',

  //VERIFY
  REGISTER_VERIFY = 'register-verify',

  //COUNTRY
  GET_COUNTRY = 'get-country',
  GET_CITY = 'get-city',
  GET_STATE = 'get-state',

  //CURRENCY
  GET_CURRENCY = 'get-currency',

  //SNAPSHOT
  GET_SNAPSHOTS = 'get-snapshots',
  GET_SNAPSHOT = 'get-snapshot',
  GET_STATS = 'get-stats',
  GET_STAKEHOLDER_STATS = 'get-stakeholder-stats',
  GET_SHARE_CLASSES_STATS = 'get-share-classes-stats',
  GET_STAKEHOLDER_TYPES_STATS = 'get-stakeholder-types-stats',
  GET_SNAPSHOT_BY_DATE = 'get-snapshot-by-date',
  EXPORT_SNAPSHOT = 'export-snapshot',

  GET_PLANS = 'get-plans',
  GET_IP_COUNTRY_CODE = 'get-ip-country-code',

  //CRUD SHARE PLAN
  GET_SHARE_PLANS = 'get-share-plans',
  GET_ALL_SHARE_PLANS = 'get-all-share-plans',
  GET_SHARE_PLAN = 'get-plan',
  GET_AUTOMATIC_VESTING_SHARE_PLAN = 'get-automatic-vesting-share-plan',
  GET_EXERCISABLE_VESTING_SHARE_PLAN = 'get-exercisable-vesting-share-plan',
  CREATE_SHARE_PLAN = 'create-share-plan',
  GRANT_AUTH_TEMPORARY_ACCESS = 'grant-auth-temporary-access',
  CHECK_AUTH_TEMPORARY_ACCESS = 'CHECK-auth-temporary-access',
  PATCH_SHARE_PLAN = 'patch-share-plan',
  TERMINATE_SHARE_PLAN = 'terminate-share-plan',
  DELETE_SHARE_PLAN = 'delete-share-plan',
  GET_APPROVE_SHARE_PLANS = 'get-approve-share-plans',
  GET_APPROVE_TARGET_BASED_VESTING_TASKS = 'get-approve-target-based-vesting-tasks',
  GET_SHARE_PLAN_VESTING_TASKS = 'get-share-plan-vesting-tasks',
  SHARE_PLAN_NAME_CHECK = 'share-plan-name-check',
  SHARE_PLAN_STAKEHOLDERS = 'share-plan-stakeholders',
  GET_SHARE_PLANS_BY_STAKEHOLDER = 'get-share-plans-by-stakeholder',
  EXPORT_POOLS_PLANS = 'export-pools-plans',

  //CRUD EVENT
  GET_EVENTS = 'get-events',
  GET_EVENT = 'get-event',
  BULK_GRANT_DOWNLOAD_TEMPLATE = 'bulk-grant-download-template',
  GRANT_BULK_IMPORT = 'grant-bulk-import',
  CREATE_EVENT = 'create-event',
  PATCH_EVENT = 'patch-event',
  DELETE_EVENT = 'delete-event',
  EXPORT_EVENTS = 'export-events',
  EXPORT_SAFES = 'export-safes',
  PIN_EVENT = 'pin-event',

  //SAFES
  SAFES_BULK_IMPORT = 'safes-bulk-import',
  SAFE_DOWNLOAD_TEMPLATE = 'safe-download-template',

  //PLAN ACTION
  CHANGE_PLAN = 'change-plan',

  //CRUD COMPANY
  GET_COMPANY = 'get-company',
  GET_COMPANY_GET_STARTED_PROGRESS = 'get-company-get-started-progress',
  GET_COMPANIES = 'get-companies',
  GET_SELECTED_COMPANY = 'get-selected-company',
  GET_SELECTED_COMPANY_ID = 'get-selected-company-id',
  GET_COMPANY_DETAILS = 'get-company-details',
  CREATE_COMPANY = 'create-company',
  CHANGE_TO_ENTERPRISE = 'change-to-enterprise',
  ACTIVATE_COUPON = 'activate-coupon',
  GET_COUPON = 'get-coupon',
  CHECK_MODULE_ACCESS = 'check-module-access',
  DEACTIVATE_COUPON = 'deactivate-coupon',
  PATCH_COMPANY = 'patch-company',
  PATCH_SELECTED_COMPANY = 'patch-selected-company',
  DELETE_COMPANY = 'delete-company',
  GET_EVENT_TRANSACTIONS = 'get-event-transactions',
  GET_INVESTMENT_SUMMARY = 'get-investment-summary',
  IS_HAVE_VESTING_TASKS = 'is-have-vesting-tasks',
  GET_COMPANY_EXCLUDED_BALANCE = 'get-company-excluded-balance',

  //COMPANY ACTIONS
  REACTIVATE_COMPANY = 'reactivate-company',
  DEACTIVATE_COMPANY = 'deactivate-company',
  SELECT_COMPANY = 'select-company',
  GET_ISSUED_SHARES = 'get-issued-shares',

  //NOTIFICATION
  GET_NOTIFICATIONS = 'get-notifications',
  GET_UNREAD_NOTIFICATION_COUNT = 'get-unread-notification-count',
  MARK_NOTIFICATION_AS_READ = 'mark-notification-as-read',
  NOTIFICATION_EXERCISE_REQUEST = 'notification-exercise-request',
  MARK_NOTIFICATION_AS_RESOLVED = 'mark-notification-as-resolved',

  //VESTING TASK
  GET_CURRENT_VALUATION = 'get-current-valuation',
  GET_VESTING_TASKS = 'get-vesting-tasks',
  GET_PHANTOM_VESTING_TASKS = 'get-phantom-vesting-tasks',
  GET_VESTING_TASKS_STRUCTURE = 'get-vesting-tasks-structure',
  GET_REQUESTED_VESTING_TASKS = 'get-requested-vesting-tasks',
  EXERCISE = 'exercise',
  EXPIRE = 'expire',

  //CRUD SAFE
  GET_SAFES = 'get-safes',
  GET_SAFE = 'get-safe',
  CREATE_SAFE = 'create-safe',
  PATCH_SAFE = 'patch-safe',
  DELETE_SAFE = 'delete-safe',
  GET_SAFE_RECAP = 'get-safe-recap',
  GET_SAFE_OVERALL_STATUS = 'get-safe-overall-status',
  GET_SAFE_CONVERSION_SUMMARY = 'get-safe-conversion-summary',
  CONVERT_SAFES = 'convert-safes',
  GET_STAKEHOLDER_SAFES = 'get-stakeholder-safes',
  IS_COMPANY_HAVE_SAVES = 'is-company-have-safes',

  //CRUD VAULT
  CREATE_FILE = 'create-file',
  CREATE_FOLDER = 'create-folder',
  GET_HARDCODED_REPOSITORY = 'get-hardcoded-repository',
  GET_OPEN_REPOSITORY = 'get-open-repository',
  GET_OPEN_FILES = 'get-open-files',
  GET_VAULT_FOLDER = 'get-vault-folder',
  DELETE_FOLDER = 'delete-folder',
  DELETE_FILE = 'delete-file',
  RENAME_FOLDER = 'rename-folder',
  RENAME_FILE = 'rename-file',
  FOLDER_EXPORT = 'folder-export',
  FILE_VIEW = 'file-view',
}
