import React, { FC } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { SafeHooks, useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';

interface CustomBarProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
}

const CustomBar = (props: CustomBarProps) => {
  const { x, y, width, height, fill } = props;
  const radius = 4;

  const barPath = `
    M${x},${y + height}
    H${x + width}
    V${y + radius}
    Q${x + width},${y} ${x + width - radius},${y}
    H${x + radius}
    Q${x},${y} ${x},${y + radius}
    Z
  `;

  return <path d={barPath} fill={fill} />;
};

interface CustomTickProps {
  x: number;
  y: number;
  payload: { value: string };
}

const CustomXAxisTick: FC<any> = ({ x, y, payload }: CustomTickProps) => {
  const [date, name] = payload.value.split('|');
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={16}
        fill="#475467"
        fontSize={10}
        fontWeight={500}
        style={{ lineHeight: '16px', dominantBaseline: 'middle' }}
        textAnchor="middle"
        x={0}
        y={0}
      >
        {name}
      </text>
      <text
        dy={32}
        fill="#475467"
        fontSize={10}
        fontWeight={500}
        style={{ lineHeight: '16px', dominantBaseline: 'middle' }}
        textAnchor="middle"
        x={0}
        y={0}
      >
        {date}
      </text>
    </g>
  );
};

interface GraphData {
  name: string;
  capValue: number;
  discount: number;
  floorValue: number;
}

const CustomToolTip: FC<TooltipProps<number | string, keyof GraphData>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, capValue, floorValue, discount } = payload[0].payload;
    return (
      <div className="flex w-[255px] flex-col gap-2 rounded-lg bg-gray-25 p-1 pt-2 shadow-2xl">
        <div className="border-b-[1px] border-gray-200 px-4">
          <span className="text-label-sm font-semibold uppercase text-gray-700">{name}</span>
        </div>
        <div className="flex w-full flex-col">
          <div className="flex justify-between gap-1 border-b-[1px] border-[#F2F2F2] px-4 pb-2">
            <span className="text-label-sm font-medium text-[#172335]">
              <AppFormattedMessage id={StringKey.PARAMETER} />
            </span>
            <span className="text-label-sm font-medium text-[#172335]">
              <AppFormattedMessage id={StringKey.VALUE} />
            </span>
          </div>
          <div className="flex justify-between gap-1 border-b-[1px] border-gray-50 px-4 pb-1">
            <div className="flex items-center justify-center gap-1">
              <div className="h-2 w-3 rounded-sm bg-brand-100" />
              <span className="text-label-sm font-medium text-[#172335]">
                <AppFormattedMessage id={StringKey.FLOOR} />
              </span>
            </div>

            <span className="text-label-sm font-medium text-[#172335]">
              {(floorValue * 1000000 || 0).toLocaleString('en-US')}
            </span>
          </div>
          <div className="flex justify-between gap-1 border-b-[1px] border-gray-50 px-4 pb-1">
            <div className="flex items-center justify-center gap-1">
              <div className="h-2 w-3 rounded-sm bg-brand-700" />
              <span className="text-label-sm font-medium text-[#172335]">
                <AppFormattedMessage id={StringKey.VALUATION_CAP} />
              </span>
            </div>

            <span className="text-label-sm font-medium text-[#172335]">
              {(capValue * 1000000 || 0).toLocaleString('en-US')}
            </span>
          </div>
          <div className="flex justify-between gap-1 border-b-[1px] border-gray-50 px-4 pb-1">
            <div className="flex items-center justify-center gap-1">
              <div className="h-2 w-3 rounded-sm bg-sunbeam-800" />
              <span className="text-label-sm font-medium text-[#172335]">
                <AppFormattedMessage id={StringKey.DISCOUNT} />
              </span>
            </div>

            <span className="text-label-sm font-medium text-[#172335]">{discount || 0}%</span>
          </div>
        </div>
      </div>
    );
  }
};

export type SafeGraphProps = {
  companyId: string;
};

export const SafeGraph: FC<SafeGraphProps> = ({ companyId }) => {
  const { data } = SafeHooks.useSafeRecap({ companyId });
  const { messagesLocale } = useLocale();
  if (!data) return;
  const { maxCapValue, maxDiscount, maxFloorValue, minCapValue, minDiscount, minFloorValue } = data;

  const showOnlyHighestValues =
    minFloorValue === maxFloorValue && minCapValue === maxCapValue && minDiscount === maxDiscount;

  const graphData = showOnlyHighestValues
    ? [
        {
          name: messagesLocale[StringKey.HIGHEST_VALUES],
          floorValue: maxFloorValue / 1000000,
          capValue: maxCapValue / 1000000,
          discount: maxDiscount * 100,
        },
      ]
    : [
        {
          name: messagesLocale[StringKey.LOWEST_VALUES],
          floorValue: minFloorValue / 1000000,
          capValue: minCapValue / 1000000,
          discount: minDiscount * 100,
        },
        {
          name: messagesLocale[StringKey.HIGHEST_VALUES],
          floorValue: maxFloorValue / 1000000,
          capValue: maxCapValue / 1000000,
          discount: maxDiscount * 100,
        },
      ];

  return (
    <>
      <div className="flex h-full w-full xlg:gap-3">
        <div className="flex h-full min-h-[246px] max-w-[16px] items-center justify-center">
          <span className="-rotate-90 transform whitespace-nowrap text-label-sm font-[500] text-gray-500">
            <AppFormattedMessage id={StringKey.VALUE_MILLION} />
          </span>
        </div>
        <div className="h-full w-full">
          <ResponsiveContainer height="100%" minHeight="246px" width="100%">
            <ComposedChart
              data={graphData}
              height={230}
              margin={{
                top: 10,
                right: 0,
                bottom: 20,
                left: 0,
              }}
              width={570}
            >
              <CartesianGrid horizontal={true} stroke="#F2F4F7" vertical={false} />
              <XAxis dataKey="name" tick={<CustomXAxisTick />} tickLine={false} />
              <YAxis
                axisLine={false}
                orientation="left"
                stroke="#475467"
                tick={{ fontSize: 10, fontWeight: 500 }}
                tickLine={false}
                tickMargin={8}
                yAxisId="left"
              />
              <YAxis
                axisLine={false}
                domain={[0, 100]}
                orientation="right"
                stroke="#475467"
                tick={{ fontSize: 10, fontWeight: 500 }}
                tickFormatter={(value) => `${value}%`}
                tickLine={false}
                tickMargin={8}
                yAxisId="right"
              />
              <Bar dataKey="floorValue" fill="#93BAF7" shape={CustomBar as any} yAxisId="left" />
              <Bar dataKey="capValue" fill="#2565C8" shape={CustomBar as any} yAxisId="left" />
              <Line
                dataKey="discount"
                dot={{ fill: '#DC6803', stroke: '#DC6803', r: 3 }}
                stroke="#DC6803"
                strokeDasharray="5 5"
                type="linear"
                yAxisId="right"
              />
              <Tooltip
                content={<CustomToolTip />}
                cursor={{ stroke: '#D0D5DD', strokeDasharray: '5 5' }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="flex h-full min-h-[246px] max-w-[16px] items-center justify-center">
          <span className="-rotate-90 transform whitespace-nowrap text-label-sm font-[500] text-gray-500">
            <AppFormattedMessage id={StringKey.DILUTED_PERCENTAGE} />
          </span>
        </div>
      </div>
    </>
  );
};
