import { FC, ReactNode, useMemo } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { Table, TableRowData } from '../../components/Table';
import { SnapshotHooks, useSelectedCompany } from '../../hooks';
import { StringKey } from '../../lang';
import { toRound } from '../../utils/getRoundedNumber';
import { SummaryItem } from './SummaryItem';

enum Column {
  SHARE_CLASS = 'share-class',
  ISSUED = 'issued',
  DILUTED = 'diluted',
  VOTING = 'voting',
  ISSUED_SHARES = 'issued-shares',
  DILUTED_SHARES = 'diluted-shares',
  INVESTMENT = 'investment',
  CONVERTED_BASIS = 'converted-basis',
  CONVERTED_BASIS_PERCENT = 'converted-basis-percent',
}

const tableColumn: Column[] = Object.values(Column);

const tableColumnTitle: Record<Column, ReactNode> = {
  [Column.SHARE_CLASS]: <AppFormattedMessage id={StringKey.SHARE_CLASS} />,
  [Column.ISSUED]: <AppFormattedMessage id={StringKey.ISSUED_PERCENTAGE} />,
  [Column.DILUTED]: <AppFormattedMessage id={StringKey.DILUTED_PERCENTAGE} />,
  [Column.VOTING]: <AppFormattedMessage id={StringKey.VOTING} />,
  [Column.ISSUED_SHARES]: <AppFormattedMessage id={StringKey.ISSUED_SHARES} />,
  [Column.DILUTED_SHARES]: <AppFormattedMessage id={StringKey.DILUTED_SHARES} />,
  [Column.INVESTMENT]: <AppFormattedMessage id={StringKey.INVESTMENT} />,
  [Column.CONVERTED_BASIS]: <AppFormattedMessage id={StringKey.CONVERTED_BASIS} />,
  [Column.CONVERTED_BASIS_PERCENT]: <AppFormattedMessage id={StringKey.CONVERTED_BASIS_PERCENT} />,
};

export type ConsolidatedPositionProps = {
  companyId: string;
};

export const ConsolidatedPosition: FC<ConsolidatedPositionProps> = ({ companyId }) => {
  const { shareClasses } = SnapshotHooks.useStats({ companyId });
  const { selectedCompany } = useSelectedCompany();
  const currency = selectedCompany?.currency;

  const tableData: TableRowData<Column>[] = useMemo(
    () =>
      shareClasses
        ?.filter(({ issued }) => issued > 0)
        ?.map(
          ({
            name,
            id,
            diluted,
            dilutedPercentage,
            investment,
            issued,
            issuedPercentage,
            votingPercentage,
            converted,
            convertedPercentage,
          }) => ({
            [Column.SHARE_CLASS]: name,
            [Column.ISSUED]: `${toRound(issuedPercentage * 100)}%`,
            [Column.DILUTED]: `${toRound(dilutedPercentage * 100)}%`,
            [Column.VOTING]: `${toRound(votingPercentage * 100)}%`,
            [Column.ISSUED_SHARES]: `${issued.toLocaleString('en-US')}`,
            [Column.DILUTED_SHARES]: `${diluted.toLocaleString('en-US')}`,
            [Column.INVESTMENT]: `${currency?.symbol || currency?.iso3?.toUpperCase()}${investment.toLocaleString('en-US')}`,
            id,
            [Column.CONVERTED_BASIS]: converted,
            [Column.CONVERTED_BASIS_PERCENT]: `${toRound(convertedPercentage * 100)}`,
          }),
        ),
    [currency?.iso3, currency?.symbol, shareClasses],
  );

  return (
    <>
      <div className="flex flex-grow flex-col gap-3 overflow-hidden rounded-lg bg-gray-50 p-[2px] pt-3 max-lg:hidden lg:basis-[60%]">
        <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          <AppFormattedMessage id={StringKey.YOUR_CONSOLIDATED_POSITION} />
        </span>
        <div className="flex h-full w-full flex-wrap gap-3 rounded-md bg-white p-1 shadow-sm">
          <div className={'h-full w-full overflow-x-auto rounded-md border border-gray-100'}>
            <Table columns={tableColumn} columnsTitle={tableColumnTitle} rows={tableData} />
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-3 rounded-lg bg-gray-50 p-1 pt-3 lg:hidden">
        <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          <AppFormattedMessage id={StringKey.YOUR_CONSOLIDATED_POSITION} />
        </span>
        <div className="flex flex-col gap-2">
          {tableData.map((data) => (
            <div
              className="flex w-full flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm"
              key={data.id}
            >
              {tableColumn.map((column) => (
                <SummaryItem key={column} title={tableColumnTitle[column]} value={data[column]} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
