import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import CircleLoader from '../CircleLoader';

enum ButtonStyleVariant {
  BLUE_ROUNDED = 'bg-brand-700 rounded py-3 text-white font-inter font-[550] text-sm disabled:ring-1 disabled:ring-gray-200 disabled:bg-gray-100 disabled:text-gray-300',
  DEFAULT_ROUNDED = 'w-fit rounded px-6 py-[10px]',
  NONE = '',
}

export type ButtonProps = {
  children?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  styleType?: keyof typeof ButtonStyleVariant;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button: FC<ButtonProps> = memo(
  ({ children, disabled, isLoading, className, styleType = 'BLUE_ROUNDED', onClick, ...props }) => {
    return (
      <button
        className={twMerge(
          'flex w-full cursor-pointer items-center justify-center rounded disabled:cursor-default disabled:bg-gray-100 disabled:text-gray-300 disabled:ring-1 disabled:ring-gray-200',
          ButtonStyleVariant[styleType],
          className,
        )}
        disabled={disabled}
        onClick={(e) => {
          if (isLoading) return;
          onClick?.(e);
        }}
        {...props}
      >
        {isLoading ? <CircleLoader /> : children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
