import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { EmptySearchList } from '../../../components/EmptySearchList';
import { StringKey } from '../../../lang';
import {
  Repository,
  VaultFolder,
  VaultOrder,
  VaultSortBy,
  VaultTab,
} from '../../../types/vault.types';
import { VaultTableFile } from './TableFile';
import { VaultTableFolder } from './TableFolder';

export type VaultTableProps = {
  data?: Repository;
  className?: string;
  onSortClick: (field: VaultSortBy) => void;
  sortMode?: VaultOrder;
  sortField?: VaultSortBy;
  searchValue: string;
  handleFolderClick: (folder: VaultFolder) => void;
  selectedTab: VaultTab;
  handleDeleteFolder: (id: string) => void;
  handleDeleteFile: (id: string) => void;
  handleRenameFile: (id: string, name: string, link?: string) => void;
  handleRenameFolder: (id: string, name: string) => void;
  handleDownloadFolder: (id: string) => void;
  handleDownloadDocument: (link: string) => void;
};

export const VaultTable: FC<VaultTableProps> = ({
  data,
  className,
  onSortClick,
  sortMode,
  sortField,
  searchValue,
  selectedTab,
  handleFolderClick,
  handleDeleteFolder,
  handleDeleteFile,
  handleRenameFile,
  handleRenameFolder,
  handleDownloadFolder,
  handleDownloadDocument,
}) => {
  return (
    <div className={twMerge('h-full w-full', className)}>
      {data?.files && data?.folders && (data?.files?.length > 0 || data?.folders?.length > 0) && (
        <div className="w-full shrink-0 overflow-x-auto p-4 *:text-nowrap">
          <div className="w-full rounded-md shadow-sm">
            <table
              className={twMerge(
                'h-fit w-full',
                searchValue && data?.folders?.length === 0 && data?.files?.length === 0
                  ? 'divide-y-0 bg-white'
                  : 'divide-y-[1px] divide-[#F2F2F2] border-b-[1px]',
              )}
            >
              <thead>
                <tr className="h-11 bg-gray-50">
                  <td className="pl-4">
                    <div
                      className="flex items-center gap-1"
                      onClick={() => onSortClick(VaultSortBy.NAME)}
                    >
                      <span className="text-label-md font-[450] text-[#172335]">
                        <AppFormattedMessage id={StringKey.NAME} />
                      </span>
                      <Button
                        className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                        styleType="NONE"
                      >
                        {sortField === VaultSortBy.NAME ? (
                          sortMode == null ? (
                            <>
                              <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                              <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                            </>
                          ) : (
                            <>
                              {sortMode === VaultOrder.ASC ? (
                                <ChevronDownIcon className="w-2" />
                              ) : (
                                <ChevronDownIcon className="w-2 rotate-180" />
                              )}
                            </>
                          )
                        ) : (
                          <>
                            <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                            <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                          </>
                        )}
                      </Button>
                    </div>
                  </td>

                  {data?.folders?.length > 0 && (
                    <td className="pl-4">
                      <div
                        className="flex items-center gap-1"
                        onClick={() => onSortClick(VaultSortBy.FILES)}
                      >
                        <span className="text-label-md font-[450] text-[#172335]">
                          <AppFormattedMessage id={StringKey.FILES} />
                        </span>
                        <Button
                          className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                          styleType="NONE"
                        >
                          {sortField === VaultSortBy.FILES ? (
                            sortMode == null ? (
                              <>
                                <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                                <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                              </>
                            ) : (
                              <>
                                {sortMode === VaultOrder.ASC ? (
                                  <ChevronDownIcon className="w-2" />
                                ) : (
                                  <ChevronDownIcon className="w-2 rotate-180" />
                                )}
                              </>
                            )
                          ) : (
                            <>
                              <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                              <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                            </>
                          )}
                        </Button>
                      </div>
                    </td>
                  )}

                  <td className="pl-4">
                    <div
                      className="flex items-center gap-1"
                      onClick={() => onSortClick(VaultSortBy.LAST_EDIT)}
                    >
                      <span className="text-label-md font-[450] text-[#172335]">
                        <AppFormattedMessage id={StringKey.LAST_EDIT} />
                      </span>
                      <Button
                        className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                        styleType="NONE"
                      >
                        {sortField === VaultSortBy.LAST_EDIT ? (
                          sortMode == null ? (
                            <>
                              <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                              <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                            </>
                          ) : (
                            <>
                              {sortMode === VaultOrder.ASC ? (
                                <ChevronDownIcon className="w-2" />
                              ) : (
                                <ChevronDownIcon className="w-2 rotate-180" />
                              )}
                            </>
                          )
                        ) : (
                          <>
                            <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                            <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                          </>
                        )}
                      </Button>
                    </div>
                  </td>

                  <td className="pl-4">
                    <div
                      className="flex items-center gap-1"
                      onClick={() => onSortClick(VaultSortBy.SIZE)}
                    >
                      <span className="text-label-md font-[450] text-[#172335]">
                        <AppFormattedMessage id={StringKey.SIZE} />
                      </span>
                      <Button
                        className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                        styleType="NONE"
                      >
                        {sortField === VaultSortBy.SIZE ? (
                          sortMode == null ? (
                            <>
                              <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                              <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                            </>
                          ) : (
                            <>
                              {sortMode === VaultOrder.ASC ? (
                                <ChevronDownIcon className="w-2" />
                              ) : (
                                <ChevronDownIcon className="w-2 rotate-180" />
                              )}
                            </>
                          )
                        ) : (
                          <>
                            <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                            <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                          </>
                        )}
                      </Button>
                    </div>
                  </td>
                  <td className="w-12"></td>
                </tr>
              </thead>

              <tbody
                className={twMerge(
                  'w-full bg-gray-600',
                  searchValue && data?.folders?.length === 0 && data?.files?.length === 0
                    ? '!divide-y-0 bg-white'
                    : 'divide-y-[1px] divide-[#F2F2F2]',
                )}
              >
                {searchValue && data?.folders?.length === 0 && data?.files?.length === 0 && (
                  <tr>
                    <td colSpan={100}>
                      <div className="flex w-full items-center justify-center">
                        <EmptySearchList className="mt-20" />
                      </div>
                    </td>
                  </tr>
                )}
                {data?.folders &&
                  data?.folders?.length > 0 &&
                  data?.folders?.map((folder) => (
                    <VaultTableFolder
                      data={data}
                      handleDelete={handleDeleteFolder}
                      handleDownload={handleDownloadFolder}
                      handleRename={handleRenameFolder}
                      item={folder}
                      key={folder.id}
                      onFolderClick={() => handleFolderClick(folder)}
                      selectedTab={selectedTab}
                    />
                  ))}
                {data?.files &&
                  data?.files?.length > 0 &&
                  data?.files?.map((file) => (
                    <VaultTableFile
                      handleDelete={handleDeleteFile}
                      handleDownload={handleDownloadDocument}
                      handleRename={handleRenameFile}
                      item={file}
                      key={file.id}
                      renderFileColumn={data?.folders && data?.folders?.length > 0}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
