import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { ArrowIcon, FolderIcon, ThreeDotsIcon } from '../../../assets/icons';
import { FolderIconVariant } from '../../../assets/icons/FolderIcon';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { StringKey } from '../../../lang';
import { Repository, VaultFolder, VaultTab } from '../../../types/vault.types';
import { VaultMobileFile } from './MobileFile';
import { VaultMobileFolder } from './MobileFolder';

export type VaultMobileProps = {
  data?: Repository;
  className?: string;
  selectedFolders: { id: string; name: string }[];
  handleFolderClick: (folder: VaultFolder) => void;
  onBackClick: (index: number, id: string | undefined) => void;
  selectedTab: VaultTab;
  handleDeleteFolder: (id: string) => void;
  handleDeleteFile: (id: string) => void;
  handleRenameFile: (id: string, name: string, link?: string) => void;
  handleRenameFolder: (id: string, name: string) => void;
  handleDownloadFolder: (id: string) => void;
  handleDownloadDocument: (link: string) => void;
};

export const VaultMobile: FC<VaultMobileProps> = ({
  data,
  className,
  selectedFolders,
  handleFolderClick,
  onBackClick,
  selectedTab,
  handleDeleteFolder,
  handleDeleteFile,
  handleRenameFile,
  handleRenameFolder,
  handleDownloadDocument,
  handleDownloadFolder,
}) => {
  return (
    <div className={twMerge('h-full w-full', className)}>
      {data && (
        <div className="w-full divide-y-[1px] divide-[#F2F2F2] rounded-lg border-[1px] border-gray-100">
          {selectedFolders.length > 0 && (
            <div className="flex items-center justify-between">
              <div className="flex w-full items-center gap-3 bg-brand-25 px-2 py-[18px]">
                <Button
                  className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full border-[1px] border-[#F2F2F2] bg-white shadow-buttonShadow"
                  onClick={() =>
                    onBackClick(
                      selectedFolders.length - 2,
                      selectedFolders[selectedFolders.length - 2]?.id || undefined,
                    )
                  }
                  styleType="NONE"
                >
                  <ArrowIcon />
                </Button>
                <div className="flex w-full flex-col overflow-hidden">
                  <span className="truncate text-base font-semibold text-gray-700">
                    {selectedFolders[selectedFolders.length - 1].name}
                  </span>
                </div>
                <Popover>
                  <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
                    <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                      <ThreeDotsIcon />
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
                    <PopoverClose>
                      <div
                        className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={() => ''}
                      >
                        <FolderIcon variant={FolderIconVariant.DOWNLOAD} />
                        <span className="text-sm font-normal text-gray-700">
                          {' '}
                          <AppFormattedMessage id={StringKey.DOWNLOAD} />
                        </span>
                      </div>
                    </PopoverClose>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          )}
          {data?.folders &&
            data?.folders?.map((folder) => (
              <VaultMobileFolder
                data={data}
                handleDelete={handleDeleteFolder}
                handleDownload={handleDownloadFolder}
                handleRename={handleRenameFolder}
                item={folder}
                key={folder.id}
                onFolderClick={() => handleFolderClick(folder)}
                selectedTab={selectedTab}
              />
            ))}
          {data?.files &&
            data?.files?.length > 0 &&
            data?.files?.map((file) => (
              <VaultMobileFile
                handleDelete={handleDeleteFile}
                handleDownload={handleDownloadDocument}
                handleRename={handleRenameFile}
                item={file}
                key={file.id}
              />
            ))}
        </div>
      )}
    </div>
  );
};
