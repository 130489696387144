import { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { FormInput, Input } from '../../../components/Input';
import { useReactForm } from '../../../hooks';
import { StringKey } from '../../../lang';
import { VaultSelect } from '../../../types/vault.types';
import { toUpperCaseWords } from '../../../utils/toUppercaseUtil';
import { FormSchema, formSchema } from './CreateModal';

export type RenameModalProps = {
  onSuccess: (name: string | undefined) => void;
  onClose: () => void;
  type: VaultSelect;
  currentName?: string;
  folderNames?: string[];
};

export const RenameModal: FC<RenameModalProps> = ({
  onClose,
  onSuccess,
  type,
  currentName,
  folderNames,
}) => {
  const { control } = useReactForm({
    schema: formSchema,
  });
  const { name } = useWatch<FormSchema>({ control });
  const { success } = formSchema.safeParse({ name });
  const isNameDuplicates = name ? folderNames?.includes(name?.toLowerCase()) : false;

  const isValid = success && !isNameDuplicates;

  const handleRename = () => {
    onSuccess(name);
  };
  return (
    <div className="m-2 flex w-full max-w-[493px] flex-col gap-8 rounded bg-white p-4">
      <div className="flex flex-col gap-4">
        <span className="text-xl font-semibold text-gray-700">
          {toUpperCaseWords(type)} <AppFormattedMessage id={StringKey.RENAME} />
        </span>
        <Input
          disabled
          onChange={() => ''}
          placeholder={
            <AppFormattedMessage
              id={StringKey.CURRENT_FILE_NAME}
              values={{
                text:
                  type === VaultSelect.FILE ? (
                    <AppFormattedMessage id={StringKey.FILE} />
                  ) : (
                    <AppFormattedMessage id={StringKey.FOLDER} />
                  ),
              }}
            />
          }
          value={currentName || ''}
          wrapperClassName={'transition-all duration-1000 w-full'}
        />
        <FormInput
          control={control}
          errorMessage={isNameDuplicates && `${name} already exists`}
          name={'name'}
          placeholder={
            <AppFormattedMessage
              id={StringKey.NEW_FILE_NAME}
              values={{
                text:
                  type === VaultSelect.FILE ? (
                    <AppFormattedMessage id={StringKey.FILE} />
                  ) : (
                    <AppFormattedMessage id={StringKey.FOLDER} />
                  ),
              }}
            />
          }
          wrapperClassName={'transition-all duration-1000 w-full'}
        />
      </div>
      <div className="flex h-11 w-full justify-end gap-3">
        <Button
          className="h-full w-fit rounded border border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700"
          onClick={onClose}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <Button
          className="h-full w-fit bg-brand-700 px-6 py-[10px] text-sm font-[550] text-white"
          disabled={!isValid}
          onClick={handleRename}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.DONE} />
        </Button>
      </div>
    </div>
  );
};
