import { ReactNode } from 'react';

import { AppFormattedMessage } from '../components/AppFormattedMessage';
import { StringKey } from '../lang';
import { Pool, SharePlan } from './pool-plans.types';
import { ShareClass } from './share-classes.types';
import { Stakeholder } from './stakeholderTypes';
import { VestingTask } from './vestingTasks.types';

export enum SortByType {
  GRANT = 'grant',
  FUNDRAISING_ROUND = 'fundraising-round',
  SHARE_ISSUANCE = 'share-issuance',
  SECONDARIES = 'secondaries',
  BUYBACK = 'buyback',
  VALUATION = 'valuation',
  CONVERSION = 'conversion',
  NEW_POOL = 'new-pool',
  NEW_PLAN = 'new-plan',
  EXERCISE = 'exercise',
  APPROVAL = 'approval',
  PAYOUT = 'payout',
  EXPIRE = 'expire',
}

export const sortByTypeTitle: Record<SortByType, StringKey> = {
  [SortByType.GRANT]: StringKey.GRANT,
  [SortByType.FUNDRAISING_ROUND]: StringKey.FUNDRAISING,
  [SortByType.SHARE_ISSUANCE]: StringKey.SHARES_ISSUANCE,
  [SortByType.SECONDARIES]: StringKey.SECONDARIES,
  [SortByType.BUYBACK]: StringKey.BUYBACK_DECREASE,
  [SortByType.VALUATION]: StringKey.VALUATION,
  [SortByType.CONVERSION]: StringKey.CONVERSION,
  [SortByType.NEW_POOL]: StringKey.POOLS,
  [SortByType.NEW_PLAN]: StringKey.PLANS,
  [SortByType.EXERCISE]: StringKey.EXERCISE,
  [SortByType.APPROVAL]: StringKey.APPROVAL_HURDLE,
  [SortByType.PAYOUT]: StringKey.PAYOUT_PHANTOM,
  [SortByType.EXPIRE]: StringKey.EXPIRE_WARRANTS,
};

export const sortByTypeMap = Object.entries(sortByTypeTitle);

export enum SortByDate {
  LAST_30_DAYS = 'last-30-days',
  LAST_60_DAYS = 'last-60-days',
  LAST_QUARTER = 'last-quatre',
  LAST_YEAR = 'last-year',
  ALL_TIME = 'all-time',
  CUSTOM_DATE = 'custom-date',
}

export const sortByDateTitle: Record<SortByDate, ReactNode> = {
  [SortByDate.LAST_30_DAYS]: (
    <AppFormattedMessage id={StringKey.LAST_COUNT_DAYS} values={{ count: 30 }} />
  ),
  [SortByDate.LAST_60_DAYS]: (
    <AppFormattedMessage id={StringKey.LAST_COUNT_DAYS} values={{ count: 60 }} />
  ),
  [SortByDate.LAST_QUARTER]: <AppFormattedMessage id={StringKey.LAST_QUARTER} />,
  [SortByDate.LAST_YEAR]: <AppFormattedMessage id={StringKey.LAST_YEAR} />,
  [SortByDate.ALL_TIME]: <AppFormattedMessage id={StringKey.ALL_TIME} />,
  [SortByDate.CUSTOM_DATE]: <AppFormattedMessage id={StringKey.CUSTOM_DATE} />,
};

export const sortByDateMap = Object.entries(sortByDateTitle);

export enum EventExercisedTypeBackend {
  STOCK_OPTIONS = 'stock-options',
  WARRANTS = 'warrants',
  SAR = 'sar',
  RSA = 'rsa',
}

export enum EventTypeBackend {
  GRANT = 'grant',
  FUNDRAISING_ROUND = 'fundraising-round',
  SHARE_ISSUANCE = 'share-issuance',
  BUYBACK = 'buyback',
  SECONDARIES = 'secondaries',
  CONVERSION = 'conversion',
  VALUATION = 'valuation',
  NEW_POOL = 'new-pool',
  NEW_PLAN = 'new-plan',
  EXERCISE = 'exercise',
  APPROVAL = 'approval',
  PAYOUT = 'payout',
  EXPIRE = 'expire',
}

export enum EventFormType {
  GRANT = 'grant',
  BULK_IMPORT_GRANT = 'bulk-import-grant',
  FUNDRAISING_ROUND = 'fundraising-round',
  SHARE_ISSUANCE = 'share-issuance',
  SECONDARIES = 'secondaries',
  BUYBACK = 'buyback',
  CONVERSION = 'conversion',
  VALUATION = 'valuation',
}

export const eventFormType: Record<EventTypeBackend, StringKey> = {
  [EventTypeBackend.GRANT]: StringKey.GRANT,
  [EventTypeBackend.BUYBACK]: StringKey.BUYBACK,
  [EventTypeBackend.CONVERSION]: StringKey.CLASS_CONVERSION,
  [EventTypeBackend.FUNDRAISING_ROUND]: StringKey.FUNDRAISING_ROUND,
  [EventTypeBackend.SECONDARIES]: StringKey.SECONDARIES,
  [EventTypeBackend.SHARE_ISSUANCE]: StringKey.SHARE_ISSUANCE,
  [EventTypeBackend.VALUATION]: StringKey.VALUATION_EVENT,
  [EventTypeBackend.NEW_PLAN]: StringKey.NEW_PLAN,
  [EventTypeBackend.NEW_POOL]: StringKey.NEW_POOL,
  [EventTypeBackend.EXERCISE]: StringKey.EXERCISED,
  [EventTypeBackend.APPROVAL]: StringKey.APPROVAL,
  [EventTypeBackend.PAYOUT]: StringKey.PAYOUT,
  [EventTypeBackend.EXPIRE]: StringKey.EXPIRE,
};

export const eventTypeMapping: Record<EventFormType, EventTypeBackend> = {
  [EventFormType.BULK_IMPORT_GRANT]: EventTypeBackend.GRANT,
  [EventFormType.GRANT]: EventTypeBackend.GRANT,
  [EventFormType.FUNDRAISING_ROUND]: EventTypeBackend.FUNDRAISING_ROUND,
  [EventFormType.SHARE_ISSUANCE]: EventTypeBackend.SHARE_ISSUANCE,
  [EventFormType.SECONDARIES]: EventTypeBackend.SECONDARIES,
  [EventFormType.BUYBACK]: EventTypeBackend.BUYBACK,
  [EventFormType.CONVERSION]: EventTypeBackend.CONVERSION,
  [EventFormType.VALUATION]: EventTypeBackend.VALUATION,
};

export const backendToFormTypeMapping: Record<EventTypeBackend, EventFormType> = Object.fromEntries(
  Object.entries(eventTypeMapping).map(([formType, backendType]) => [backendType, formType]),
) as Record<EventTypeBackend, EventFormType>;

export const EventTypeTitle: Record<EventFormType, ReactNode> = {
  [EventFormType.GRANT]: <AppFormattedMessage id={StringKey.ADD_GRANT} />,
  [EventFormType.BULK_IMPORT_GRANT]: <AppFormattedMessage id={StringKey.BULK_IMPORT_GRANT} />,
  [EventFormType.FUNDRAISING_ROUND]: <AppFormattedMessage id={StringKey.ADD_FUNDRAISING_ROUND} />,
  [EventFormType.SHARE_ISSUANCE]: <AppFormattedMessage id={StringKey.ADD_SHARE_ISSUANCE} />,
  [EventFormType.SECONDARIES]: <AppFormattedMessage id={StringKey.ADD_SECONDARIES} />,
  [EventFormType.BUYBACK]: <AppFormattedMessage id={StringKey.ADD_BUYBACK} />,
  [EventFormType.CONVERSION]: <AppFormattedMessage id={StringKey.ADD_CLASS_CONVERSION} />,
  [EventFormType.VALUATION]: <AppFormattedMessage id={StringKey.ADD_VALUATION} />,
};

export const EventTypeButtonTitle: Record<EventFormType, ReactNode> = {
  [EventFormType.GRANT]: <AppFormattedMessage id={StringKey.GRANT} />,
  [EventFormType.BULK_IMPORT_GRANT]: <AppFormattedMessage id={StringKey.BULK_IMPORT_GRANT} />,
  [EventFormType.FUNDRAISING_ROUND]: <AppFormattedMessage id={StringKey.FUNDRAISING_ROUND} />,
  [EventFormType.SHARE_ISSUANCE]: <AppFormattedMessage id={StringKey.SHARES_ISSUANCE} />,
  [EventFormType.SECONDARIES]: <AppFormattedMessage id={StringKey.SECONDARIES} />,
  [EventFormType.BUYBACK]: <AppFormattedMessage id={StringKey.BUYBACK_DECREASE} />,
  [EventFormType.CONVERSION]: <AppFormattedMessage id={StringKey.CLASS_CONVERSION} />,
  [EventFormType.VALUATION]: <AppFormattedMessage id={StringKey.VALUATION} />,
};

export enum ValuationSelect {
  PRE_MONEY_VALUATION = 'pre-money-valuation',
  SHARE_PRICE = 'share-price',
}

export const ValuationSelectTitle: Record<ValuationSelect, StringKey> = {
  [ValuationSelect.PRE_MONEY_VALUATION]: StringKey.PRE_MONEY_VALUATION,
  [ValuationSelect.SHARE_PRICE]: StringKey.SHARES_PRICE,
};

export type CreateGrantItemDto = {
  date: string;
  planId: string;
  stakeholderId: string;
  numbersOfGrants: number;
  filesLinks: string[];
};

export type CreateInvestmentItemDto = {
  stakeholderId: string;
  shareClassId: string;
  investedValue: number;
  date: string;
  filesLinks: string[];
};

export type CreateBuybackItemDto = {
  date: string;
  stakeholderId: string;
  shareClassId: string;
  sharesCount: number;
  filesLinks: string[];
};

export type CreateIssuanceEventItemDto = {
  stakeholderId: string;
  shareClassId: string;
  sharesCount: number;
};

export type CreateSecondariesEventItemDto = {
  date: string;
  fromStakeholderId: string;
  toStakeholderId: string;
  shareClassId: string;
  sharesCount: number;
  sharesValue: number;
  filesLinks: string[];
};

export type CreateConversionItemDto = {
  date: string;
  stakeholderId: string;
  fromShareClassId: string;
  toShareClassId: string;
  sharesCount: number;
};

export type CreateValuationEventDto = {
  id?: string;
  name: string;
  date: string;
  sharePrice: number;
};

export type CreateEventDto = {
  type: EventTypeBackend;
  grant?: {
    id?: string;
    items: CreateGrantItemDto[];
  };
  fundraisingRound?: {
    id?: string;
    items: CreateInvestmentItemDto[];
    valuationId: string;
    sharePrice: number;
    isOpen?: boolean;
  };
  issuance?: {
    id?: string;
    valuationId: string;
    date: string;
    sharePrice: number;
    items: CreateIssuanceEventItemDto[];
  };
  buyback?: {
    id?: string;
    items: CreateBuybackItemDto[];
  };
  secondaries?: {
    id?: string;
    items: CreateSecondariesEventItemDto[];
  };
  conversion?: {
    id?: string;
    items: CreateConversionItemDto[];
  };
  valuation?: CreateValuationEventDto;
  filesLinks: string[];
  additionalNotes: string;
};

export type Valuation = {
  id: string;
  event: EventUnion;
  name: string;
  date: Date;
  sharePrice: number;
  issuedSharesOnStart?: number;
  issuedShares?: number;
};
export interface CommonEvent {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  filesLinks: string[];
  additionalNotes: string;
  eventId: string;
  pinned: boolean;
}

export type ExerciseEvent = {
  id: string;
  vestingTask: VestingTask;
  exercised: number;
};

export interface NewExerciseEvent extends CommonEvent {
  type:
    | EventTypeBackend.EXERCISE
    | EventTypeBackend.APPROVAL
    | EventTypeBackend.PAYOUT
    | EventTypeBackend.EXPIRE;
  exercise: ExerciseEvent;
}

export interface GrantEventItem {
  id: string;
  date: Date;
  plan: SharePlan;
  stakeholder: Stakeholder;
  numbersOfGrants: number;
  processed: boolean;
  filesLinks: string[];
}

export interface FundraisingEventItem {
  id: string;
  date: Date;
  stakeholder: Stakeholder;
  shareClass: ShareClass;
  investedValue: number;
  filesLinks: string[];
}

export interface GrantEvent extends CommonEvent {
  type: EventTypeBackend.GRANT;
  items: GrantEventItem[];
  filesLinks: string[];
}

export interface FundraisingEvent extends CommonEvent {
  type: EventTypeBackend.FUNDRAISING_ROUND;
  valuation: Valuation;
  sharePrice: number;
  items: FundraisingEventItem[];
  isOpen: boolean;
}

export interface ShareIssuanceEventItem {
  id: string;
  stakeholder: Stakeholder;
  shareClass: ShareClass;
  sharesCount: number;
}

export interface ShareIssuanceEvent extends CommonEvent {
  type: EventTypeBackend.SHARE_ISSUANCE;
  valuation: Valuation;
  date: Date;
  sharePrice: number;
  items: ShareIssuanceEventItem[];
}

export interface BuybackEventItem {
  id: string;
  date: Date;
  stakeholder: Stakeholder;
  shareClass: ShareClass;
  sharesCount: number;
  filesLinks: string[];
}

export interface BuybackEvent extends CommonEvent {
  type: EventTypeBackend.BUYBACK;
  items: BuybackEventItem[];
}

export interface SecondariesEventItem {
  id: string;
  date: Date;
  fromStakeholder: Stakeholder;
  toStakeholder: Stakeholder;
  shareClass: ShareClass;
  sharesCount: number;
  sharesValue: number;
  filesLinks: string[];
}

export interface SecondariesEvent extends CommonEvent {
  type: EventTypeBackend.SECONDARIES;
  items: SecondariesEventItem[];
}

export interface ConversionEventItem {
  id: string;
  date: Date;
  stakeholder: Stakeholder;
  fromShareClass: ShareClass;
  toShareClass: ShareClass;
  sharesCount: number;
}

export interface ConversionEvent extends CommonEvent {
  type: EventTypeBackend.CONVERSION;
  items: ConversionEventItem[];
}

export interface ValuationEvent extends CommonEvent {
  type: EventTypeBackend.VALUATION;
  name: string;
  date: Date;
  sharePrice: number;
  issuedSharesOnStart: number;
  issuedShares: number;
}

export interface NewPoolEvent extends CommonEvent {
  type: EventTypeBackend.NEW_POOL;
  pool: Pool;
}

export interface NewPlanEvent extends CommonEvent {
  type: EventTypeBackend.NEW_PLAN;
  pool: Pool;
  sharePlan: SharePlan;
}

export type EventUnion =
  | GrantEvent
  | FundraisingEvent
  | ShareIssuanceEvent
  | BuybackEvent
  | SecondariesEvent
  | ConversionEvent
  | ValuationEvent
  | NewPoolEvent
  | NewPlanEvent
  | NewExerciseEvent;
