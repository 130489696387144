import React, { FC } from 'react';

import { RefreshIcon, TrashBinIcon } from '../../../../assets/icons';
import { RefreshIconVariant } from '../../../../assets/icons/RefreshIcon';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Checkbox } from '../../../../components/Checkbox';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { useLocale } from '../../../../hooks';
import { Locale, StringKey } from '../../../../lang';
import { Safe } from '../../../../types/safes.types';

export type BulkActionsModalProps = {
  selectedSafesIds: string[];
  safes: Safe[];
  isOpenModal: boolean;
  onRemoveSelectAll: () => void;
  handleDelete: (ids: string[]) => void;
  handleConvert: (ids: string[]) => void;
};

export const BulkActionsModal: FC<BulkActionsModalProps> = ({
  isOpenModal,
  safes,
  selectedSafesIds,
  onRemoveSelectAll,
  handleDelete,
  handleConvert,
}) => {
  const { locale } = useLocale();
  const selectedSafes = safes.filter(({ id }) => selectedSafesIds.includes(id));
  const hasConvertedSafes = selectedSafes.some((safe) => safe.isConvertedToShares);

  const isPlural = selectedSafesIds.length > 1;

  return (
    <Sheet modal={false} open={isOpenModal}>
      <SheetContent
        className="m-auto flex h-fit w-fit justify-center border-transparent bg-transparent p-0 pb-6 shadow-none"
        side="BOTTOM"
      >
        <div className="flex h-10 items-center justify-center divide-x divide-gray-100 rounded-[40px] bg-white px-4 py-2 shadow-2xl">
          <div
            className="flex h-5 items-center gap-3 text-nowrap pr-3 text-sm font-[450] text-gray-700"
            onClick={onRemoveSelectAll}
          >
            <Checkbox checked={selectedSafesIds.length > 0} />
            <AppFormattedMessage
              id={StringKey.STAKEHOLDERS_SELECTED}
              values={{ count: selectedSafesIds.length }}
            />
          </div>
          {!hasConvertedSafes && (
            <>
              <Button
                className="flex h-5 gap-2 text-nowrap pl-3 text-sm font-[450] text-brand-700"
                onClick={() => handleConvert(selectedSafesIds)}
                styleType="NONE"
              >
                <RefreshIcon
                  className="size-4"
                  iconColor="#2565C8"
                  variant={RefreshIconVariant.SAFES}
                />
                {isPlural ? (
                  <AppFormattedMessage id={StringKey.CONVERT_SAFE} /> + locale === Locale.EN ? (
                    's'
                  ) : (
                    ''
                  )
                ) : (
                  <AppFormattedMessage id={StringKey.CONVERT_SAFE} />
                )}
              </Button>
              <Button
                className="flex h-5 gap-2 text-nowrap pl-3 text-sm font-[450] text-fireside-500"
                onClick={() => handleDelete(selectedSafesIds)}
                styleType="NONE"
              >
                <TrashBinIcon className="size-4" iconColor="#F04438" />
                <AppFormattedMessage id={StringKey.DELETE} />
              </Button>
            </>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};
