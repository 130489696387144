import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { VaultFolder, VaultOrder, VaultSortBy } from '../../types/vault.types';
import { useAppQuery } from '../useAppQuery';

export type GetRepositoryResponse = {
  data: VaultFolder[];
  totalPages: number;
};

export const useHardcodedRepository = ({
  companyId,
  numberOfFetchedItems = 100,
  currentPage,
  search,
  sortBy,
  orderBy,
  ...searchParams
}: {
  companyId: string;
  numberOfFetchedItems?: number;
  currentPage: number;
  search: string;
  sortBy?: VaultSortBy;
  orderBy?: VaultOrder;
}) => {
  const queryClient = useQueryClient();
  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * numberOfFetchedItems;
  const {
    data: { data, totalPages } = {},
    isLoading,
    refetch,
  } = useAppQuery<GetRepositoryResponse, HttpException>({
    queryKey: [
      QueryKey.GET_HARDCODED_REPOSITORY,
      companyId,
      {
        page: currentPage,
        skip,
        numberOfFetchedItems,
        sortBy,
        orderBy,
      },
    ],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'vault', 'folders'] },
        {
          queryParams: {
            ...searchParams,
            take: numberOfFetchedItems,
            skip,
            search,
            ...(orderBy && sortBy ? { sortBy, orderBy } : {}),
          },
        },
      ),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_HARDCODED_REPOSITORY] });
  }, [queryClient]);

  return {
    invalidateQuery,
    folders: data,
    totalPages,
    isLoading,
    refetch,
  };
};
