import { FC } from 'react';

import { CloudUploadIcon, PlusIcon } from '../../assets/icons';
import { PlusIconVariant } from '../../assets/icons/PlusIcon';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { StringKey } from '../../lang';
import { VaultTab } from '../../types/vault.types';
import fileBox from './../../assets/images/file-box.png';

type EmptyVaultProps = {
  selectedTab: VaultTab;
  onAddFile: () => void;
  onAddFolder: () => void;
  folderButtonDisabled?: boolean;
};

export const EmptyVault: FC<EmptyVaultProps> = ({
  selectedTab,
  onAddFile,
  onAddFolder,
  folderButtonDisabled,
}) => {
  return (
    <div className="radial-gradient-border-yellow centered relative m-auto my-20 flex h-fit flex-col gap-6 rounded-2xl px-10 py-8 lg:max-w-[394px]">
      <div
        className="rounded-full bg-white p-3"
        style={{
          boxShadow: '0px 0px 8px 0px #DC680321 inset',
        }}
      >
        <div
          className="centered flex size-28 items-center gap-3 rounded-full"
          style={{
            background:
              ' linear-gradient(180deg, rgba(254, 200, 75, 0.13) 0%, rgba(220, 104, 3, 0.13) 100%)',
          }}
        >
          <div className="size-[56px] object-cover">
            <img alt="" src={fileBox} />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="flex flex-col items-center justify-center gap-2">
          <span
            className="!bg-clip-text text-xl font-semibold text-transparent"
            style={{
              background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
            }}
          >
            <AppFormattedMessage id={StringKey.NOTHING_FOUND} />
          </span>
          {selectedTab === VaultTab.OPEN_DRIVE ? (
            <div className="flex flex-col gap-4 xlg:gap-0">
              <span className="hidden max-w-[314px] text-center text-xs font-[450] text-gray-500 xlg:block">
                <AppFormattedMessage id={StringKey.UPLOAD_PROMPT} />
              </span>
              <span className="max-w-[314px] text-center text-xs font-[450] text-gray-500 xlg:hidden">
                <AppFormattedMessage id={StringKey.USE_TABLET_DESKTOP} />
              </span>
              <span className="max-w-[314px] text-center text-xs font-[450] text-gray-500">
                <AppFormattedMessage id={StringKey.FILES_FOLDERS_DISPLAYED} />
              </span>
            </div>
          ) : (
            <span className="max-w-[314px] text-center text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.FILES_FOLDERS_DISPLAYED} />
            </span>
          )}
        </div>
        {selectedTab === VaultTab.OPEN_DRIVE && (
          <div className="hidden items-center gap-4 xlg:flex">
            <Button
              className="gap-1 text-nowrap border-[1px] border-gray-100 bg-gray-25 px-[14px] py-2 text-sm font-[450] text-brand-700 shadow-xs"
              onClick={onAddFile}
            >
              <CloudUploadIcon className="size-5" />
              <AppFormattedMessage id={StringKey.UPLOAD_FILE} />
            </Button>
            {!folderButtonDisabled && (
              <Button
                className="gap-1 text-nowrap border-[1px] border-gray-100 bg-gray-25 px-[14px] py-2 text-sm font-[450] text-brand-700 shadow-xs"
                onClick={onAddFolder}
              >
                <PlusIcon iconColor="#2565c8" variant={PlusIconVariant.VAULT} />
                <AppFormattedMessage id={StringKey.FOLDER} />
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
