import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDeleteFile = () => {
  const { mutate: deleteFile, isPending } = useAppMutation([QueryKey.DELETE_FILE], {
    mutationFn: ({ companyId, fileId }: { companyId: string; fileId: string }) =>
      ApiService.delete({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'vault', 'files', fileId],
      }),
  });
  return { deleteFile, isPending };
};
