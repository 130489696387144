import { FC } from 'react';

import { EmptySearchList } from '../../../../components/EmptySearchList';
import { ShareClass } from '../../../../hooks';
import { Column, ViewBy } from '../../../../types/snapshot.types';
import { TableProps } from '../Details';
import { EmptyDetails } from '../EmptyDetails';
import { snapshotViewBy } from '../SnapshotViewBy';
import { getShareClasses } from '../SnapshotViewBy/type';
import { BasicTable } from './TableBasic';

export const Table: FC<TableProps> = ({
  viewBy,
  visibleColumns,
  type,
  search,
  isEmptySearchResult,
  setEmptySearchResult,
  snapshot,
}) => {
  const isSnapshotStatsExist = snapshot?.stats && snapshot?.stats?.stakeholders?.length > 0;
  const { shareClass } = ShareClass.useDefaultShareClass();

  if (!snapshot || !isSnapshotStatsExist) return <EmptyDetails />;

  if (isEmptySearchResult) return <EmptySearchList className="m-auto my-20" />;

  const selectedViewBy: ViewBy | 'all' =
    viewBy.stakeholders && viewBy.type ? 'all' : viewBy.type ? ViewBy.TYPE : ViewBy.STAKEHOLDERS;

  const defaultShareClassId = shareClass?.id || '';

  const { otherShareClasses } = getShareClasses(defaultShareClassId, snapshot.stats.shareClasses);

  return (
    <>
      <BasicTable
        additionalColumns={{
          [Column.OTHER_SHARE_CLASSES]: otherShareClasses
            .map(({ name }) => name)
            .sort((a, b) => a.localeCompare(b)),
        }}
        sortBy={type}
        viewBy={viewBy}
        visibleColumns={visibleColumns}
      >
        {snapshotViewBy[selectedViewBy]({
          snapshot,
          viewBy,
          visibleColumns,
          type,
          search,
          setEmptySearchResult,
        })}
      </BasicTable>
    </>
  );
};
