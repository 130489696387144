import { StringKey } from '../lang';
import { getTranslation } from './getTranslation';

export function formatBytes(bytes: number) {
  if (!+bytes) return '0';

  const k = 1024;
  const sizes = [StringKey.SIZE_B, StringKey.SIZE_KB, StringKey.SIZE_MB, StringKey.SIZE_GB];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed())} ${getTranslation(sizes[i])}`;
}
