import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDeleteFolder = () => {
  const { mutate: deleteFolder, isPending } = useAppMutation([QueryKey.DELETE_FOLDER], {
    mutationFn: ({ companyId, folderId }: { companyId: string; folderId: string }) =>
      ApiService.delete({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'vault', 'folders', folderId],
      }),
  });
  return { deleteFolder, isPending };
};
