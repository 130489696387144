import { MutationKey, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { HttpException } from '../exceptions';
import { StringKey } from '../lang';
import { useLocale } from './useLocale';

type AppMutationProps<
  TData = unknown,
  TError = HttpException,
  TVariables = void,
  TContext = unknown,
> = {
  defaultErrorHandling?: boolean;
} & Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey'>;

export const useAppMutation = <
  TData = unknown,
  TError = HttpException,
  TVariables = void,
  TContext = unknown,
>(
  mutationKey: MutationKey = [],
  { defaultErrorHandling = true, ...params }: AppMutationProps<TData, TError, TVariables, TContext>,
) => {
  const { messagesLocale } = useLocale();
  return useMutation({
    mutationKey,
    ...params,
    onError: (error, variables, context) => {
      if (defaultErrorHandling && error instanceof HttpException) {
        const { body } = error;
        const errorMessage: string | undefined = messagesLocale?.[body?.message as StringKey];
        toast(`${errorMessage[0].toUpperCase() + errorMessage.slice(1) || body?.message}`, {
          position: 'bottom-center',
          type: 'error',
          theme: 'light',
        });
      }

      params.onError?.(error, variables, context);
    },
  });
};
