import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';

export const useIsCompanyHaveSafes = ({ companyId }: { companyId: string }) => {
  const queryClient = useQueryClient();
  const {
    data: isCompanyHaveSafes,
    isLoading,
    refetch,
  } = useAppQuery<string, HttpException>({
    queryKey: [QueryKey.IS_COMPANY_HAVE_SAVES, companyId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'safes', 'is-company-have-safes'],
      }),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.IS_COMPANY_HAVE_SAVES] });
  }, [queryClient]);

  return {
    invalidateQuery,
    isCompanyHaveSafes,
    isLoading,
    refetch,
  };
};
