import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { RenameFileDto } from '../../types/vault.types';
import { useAppMutation } from '../useAppMutation';

export const useRenameFile = () => {
  const { mutate: renameFile, isPending } = useAppMutation([QueryKey.RENAME_FILE], {
    mutationFn: ({
      companyId,
      data,
      fileId,
    }: {
      data: RenameFileDto;
      companyId: string;
      fileId: string;
    }) =>
      ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'vault', 'files', fileId] },
        {
          body: data,
        },
      ),
  });
  return { renameFile, isPending };
};
