import { z } from 'zod';

import { ReasonToChangeToEnterprise } from '../../../../hooks/company';
import { StringKey } from '../../../../lang';
import { getTranslation } from '../../../../utils/getTranslation';

export const formSchema = z.object({
  reasonsToChange: z.object<
    Record<ReasonToChangeToEnterprise, z.ZodDefault<z.ZodOptional<z.ZodBoolean>>>
  >({
    [ReasonToChangeToEnterprise.NEED_STAKEHOLDERS]: z.boolean().optional().default(false),
    [ReasonToChangeToEnterprise.NEW_FEATURES]: z.boolean().optional().default(false),
    [ReasonToChangeToEnterprise.PRIORITY_SERVICE]: z.boolean().optional().default(false),
  }),
  additionalDetails: z
    .string()
    .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
    .optional(),

  additionalStakeholdersDetails: z
    .string()
    .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
    .optional(),
});

export type FormSchema = z.infer<typeof formSchema>;
