import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import {
  CapTableManagementTypes,
  EnterpriseFeatures,
} from '../../pages/CompanySetup/CompanySetupForm/EnterpriseModal/types';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useSelected } from './useSelected';

export enum ReasonToChangeToEnterprise {
  NEED_STAKEHOLDERS = 'need-stakeholders',
  PRIORITY_SERVICE = 'priority-service',
  NEW_FEATURES = 'new-features',
}

export const reasonToChangeToEnterpriseTitle: Record<ReasonToChangeToEnterprise, string> = {
  [ReasonToChangeToEnterprise.NEED_STAKEHOLDERS]: 'I need additional stakeholders',
  [ReasonToChangeToEnterprise.PRIORITY_SERVICE]: 'I need priority service',
  [ReasonToChangeToEnterprise.NEW_FEATURES]: 'I need access to new features',
};

export const reasonToChangeToEnterpriseTitleMap = Object.entries(reasonToChangeToEnterpriseTitle);

type RequestEnterpriseDto = {
  companyId: string;
  capTableManagement: CapTableManagementTypes;
  email?: string;
  phonoNumber?: string;
  additionalNotes?: string;
  features?: Record<EnterpriseFeatures, boolean>;
  anotherSoftware?: string;
};

type RequestChangeToEnterpriseDto = {
  reasonsToChange: Record<ReasonToChangeToEnterprise, boolean>;
  additionalNotesAboutStakeholders?: string;
  additionalNotes?: string;
};

export const useEnterpriseRequest = () => {
  const { selectedCompanyId: companyId } = useSelected();

  const { mutate: enterpriseRequest, isPending } = useAppMutation([QueryKey.CHANGE_TO_ENTERPRISE], {
    mutationFn: (body: RequestEnterpriseDto | RequestChangeToEnterpriseDto) =>
      ApiService.post(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'request-enterprise'],
        },
        { body },
      ),
  });
  return { enterpriseRequest, isPending };
};
