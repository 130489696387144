import React, { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../../../../components/Combobox';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { toUpperCaseWords } from '../../../../utils/toUppercaseUtil';

type Month = {
  month: string;
  index: number;
};

type MonthItemsListProps = {
  selectedMonth?: Month | null;
};

const useAllMonths = () => {
  const months = Array.from({ length: 12 }, (_, index) => index);
  const { format } = useFormat();
  return months.map((month) => ({
    month: format(new Date(2024, month, 1), 'MMMM'),
    index: month,
  }));
};

const MonthItemsList: FC<MonthItemsListProps> = ({ selectedMonth }) => {
  const allMonth = useAllMonths();
  return (
    <>
      {selectedMonth && (
        <ComboboxOption
          className="text-sm font-normal text-gray-700"
          value={{ month: '', index: null } as unknown as string}
        >
          Reset
        </ComboboxOption>
      )}
      {allMonth.map((month) => (
        <ComboboxOption key={month.month} value={month as unknown as string}>
          <span className="text-sm font-normal text-gray-700">{toUpperCaseWords(month.month)}</span>
        </ComboboxOption>
      ))}
    </>
  );
};

export type MonthComboboxProps = {
  onSelect?: (month: Month) => void;
  defaultValue?: Month;
};

const MonthCombobox: FC<MonthComboboxProps> = ({ onSelect, defaultValue }) => {
  const [selectedMonth, setMonth] = useState<Month | null>(null);
  return (
    <Combobox
      as={'div'}
      className="relative"
      onChange={(e: Month) => {
        setMonth(e);
        onSelect?.(e);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className={'absolute z-0 bg-gray-900'}
          icon={<ChevronDownIcon className="mt-3" />}
          onChange={() => ''}
          placeholder={<AppFormattedMessage id={StringKey.MONTH} />}
          readOnly
          value={
            toUpperCaseWords(defaultValue?.month) || toUpperCaseWords(selectedMonth?.month) || ''
          }
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <MonthItemsList selectedMonth={selectedMonth} />
      </ComboboxOptions>
    </Combobox>
  );
};

export default MonthCombobox;
