import { StringKey } from '../lang';

export type Safe = {
  id: string;
  stakeholder: { id: string; fullName: string };
  investment: number;
  issueDate: string;
  capValue?: number;
  discount?: number;
  floorValue?: number;
  maturityDate?: string;
  filesLinks: string[];
  additionalNotes: string;
  createdAt?: string;
  conversionMethod?: ConversionMethod;
  conversionDate?: string;
  convertedShares?: number;
  isConvertedToShares: boolean;
};

export enum ConversionMethod {
  DISCOUNT = 'discount',
  FLOOR = 'floor',
  VALUATION_CAP = 'valuation-cap',
}

export const conversionMethodTitle: Record<ConversionMethod, StringKey> = {
  [ConversionMethod.DISCOUNT]: StringKey.DISCOUNT,
  [ConversionMethod.FLOOR]: StringKey.FLOOR,
  [ConversionMethod.VALUATION_CAP]: StringKey.VALUATION_CAP,
};

export enum DetailsTab {
  PENDING_CONVERSION = 'pending-conversion',
  CONVERTED = 'converted',
}

export const detailsTabTitle: Record<DetailsTab, StringKey> = {
  [DetailsTab.PENDING_CONVERSION]: StringKey.PENDING_CONVERSION,
  [DetailsTab.CONVERTED]: StringKey.CONVERTED,
};

export const detailsTabTitleMap = Object.entries(detailsTabTitle);

export enum SafeOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SafeSortBy {
  ISSUE_DATE = 'issue-date',
  CAP_VALUE = 'cap-value',
  DISCOUNT = 'discount',
  FLOOR = 'floor',
  MATURITY_DATE = 'maturity-date',
  FULL_NAME = 'full-name',
  CONVERSION_METHOD = 'conversion-method',
  CONVERSION_DATE = 'conversion-date',
  CONVERTED_SHARES = 'converted-shares',
}

export enum AmountOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
  HUNDRED = '100',
}

export const amountOfFetchedItemsMap = Object.values(AmountOfFetchedItems);

export type CreateSafeDto = {
  stakeholderId: string;
  investment: number;
  issueDate: string;
  capValue?: number;
  discount?: number;
  floorValue?: number;
  maturityDate?: string;
  filesLinks: string[];
  additionalNotes?: string;
};

export type PatchSafeDto = {
  stakeholderId?: string;
  investment?: number;
  issueDate?: string;
  capValue?: number | null;
  discount?: number | null;
  floorValue?: number | null;
  maturityDate?: string | null;
  filesLinks?: string[];
  additionalNotes?: string;
};

export type ConvertToSharesSummaryDto = {
  valuationId?: string;
  safeIds: string[];
};

export type ConvertSafesDto = {
  valuationId?: string;
  shareClassId: string;
  safeIds: string[];
  filesLinks: string[];
  additionalNotes?: string;
  conversionDate: string;
};
