import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { VaultFolder, VaultOrder, VaultSortBy } from '../../types/vault.types';
import { useAppQuery } from '../useAppQuery';

export const useVaultFolder = ({
  companyId,
  folderId,
  search,
  sortBy,
  orderBy,
}: {
  companyId: string;
  folderId: string;
  search?: string | null;
  sortBy?: VaultSortBy;
  orderBy?: VaultOrder;
}) => {
  const queryClient = useQueryClient();

  const {
    data: folder,
    isLoading,
    refetch,
  } = useAppQuery<VaultFolder>({
    queryKey: [QueryKey.GET_VAULT_FOLDER, companyId, folderId, { sortBy, orderBy }],
    queryFn: () =>
      ApiService.get(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'vault', 'folders', folderId],
        },
        { queryParams: { search, ...(orderBy && sortBy ? { sortBy, orderBy } : {}) } },
      ),
    enabled: !!companyId && !!folderId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_VAULT_FOLDER] });
  }, [queryClient]);

  return {
    invalidateQuery,
    folder,
    isLoading,
    refetch,
  };
};
