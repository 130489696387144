import { Company } from './companyTypes';
import { Stakeholder } from './stakeholderTypes';

export enum VaultTab {
  REPOSITORY = 'repository',
  OPEN_DRIVE = 'open-drive',
}

export enum VaultSortBy {
  NAME = 'name',
  FILES = 'files',
  LAST_EDIT = 'last-edit',
  SIZE = 'size',
}

export enum VaultOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum VaultSelect {
  FOLDER = 'folder',
  FILE = 'file',
}

export type VaultSelectVariant = 'add' | VaultSelect;

export type VaultFolder = {
  id: string;
  name: string;
  size: number;
  createdAt: string;
  updatedAt: string;
  fileCount: number;
  generatedAutomatically: boolean;
  uploadPermission: boolean;
  parentFolder?: VaultFolder;
  folders?: VaultFolder[];
  files?: VaultFile[];
  stakeholder?: Stakeholder;
  company?: Company;
};

export type VaultFile = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  generatedAutomatically: boolean;
  size: number;
  link: string;
  folder?: VaultFolder;
  stakeholder?: Stakeholder;
  company?: Company;
};

export type Repository = {
  folders?: VaultFolder[];
  files?: VaultFile[];
  uploadPermission?: boolean;
};

export type CreateFileDto = {
  files: { link: string; name?: string }[];
  folderId?: string;
};

export type CreateFolderDto = {
  name: string;
  parentFolderId?: string;
};

export type RenameFolderDto = {
  name?: string;
  parentFolderId?: string;
};

export type RenameFileDto = {
  name?: string;
  link?: string;
  folderId?: string;
};
