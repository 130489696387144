import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { StringKey } from '../../../lang';
import { BreadcrumbsProps } from './Breadcrumbs';

export const MobileBreadcrumbs: FC<BreadcrumbsProps> = ({ onBreadcrumbClick, selectedFolders }) => {
  return (
    <nav className="flex items-center gap-2 p-4">
      <div className="flex items-center">
        <button
          className="text-xs font-[450] text-brand-700"
          onClick={() => onBreadcrumbClick(-1, undefined)}
        >
          <AppFormattedMessage id={StringKey.HOME} />
        </button>
      </div>

      {selectedFolders.map((folder, index) => {
        const isLast = index === selectedFolders.length - 1;

        return (
          <div className="flex items-center gap-2" key={folder.id}>
            <span className="cursor-default text-xs font-[450] text-gray-400">/</span>
            {isLast ? (
              <span className="cursor-default text-xs font-[450] text-gray-400">{folder.name}</span>
            ) : (
              <>
                <button
                  className="text-xs font-[450] text-brand-700"
                  onClick={() => onBreadcrumbClick(index, folder.id)}
                >
                  {folder.name}
                </button>
              </>
            )}
          </div>
        );
      })}
    </nav>
  );
};
