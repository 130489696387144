import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { RenameFolderDto } from '../../types/vault.types';
import { useAppMutation } from '../useAppMutation';

export const useRenameFolder = () => {
  const { mutate: renameFolder, isPending } = useAppMutation([QueryKey.RENAME_FOLDER], {
    mutationFn: ({
      companyId,
      data,
      folderId,
    }: {
      data: RenameFolderDto;
      companyId: string;
      folderId: string;
    }) =>
      ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'vault', 'folders', folderId] },
        {
          body: data,
        },
      ),
  });
  return { renameFolder, isPending };
};
