import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { VaultFile, VaultOrder, VaultSortBy } from '../../types/vault.types';
import { useAppQuery } from '../useAppQuery';

export type GetOpenFilesResponse = {
  data: VaultFile[];
  totalPages: number;
};

export const useOpenFiles = ({
  companyId,
  numberOfFetchedItems = 100,
  currentPage,
  search,
  sortBy,
  orderBy,
  enabled,
}: {
  companyId: string;
  numberOfFetchedItems?: number;
  currentPage: number;
  search: string;
  sortBy?: VaultSortBy;
  orderBy?: VaultOrder;
  enabled?: boolean;
}) => {
  const queryClient = useQueryClient();
  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * numberOfFetchedItems;
  const {
    data: { data, totalPages } = {},
    isLoading,
    refetch,
  } = useAppQuery<GetOpenFilesResponse, HttpException>({
    queryKey: [
      QueryKey.GET_OPEN_FILES,
      companyId,
      {
        page: currentPage,
        skip,
        numberOfFetchedItems,
        sortBy,
        orderBy,
      },
    ],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'vault', 'open-files'] },
        {
          queryParams: {
            take: numberOfFetchedItems,
            skip,
            search,
            ...(orderBy && sortBy ? { sortBy, orderBy } : {}),
          },
        },
      ),
    enabled: !!companyId && !!enabled,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_OPEN_FILES] });
  }, [queryClient]);

  if (!enabled) {
    return {
      invalidateQuery: () => {},
      folders: [],
      totalPages: 0,
      isLoading: false,
      refetch: () => {},
    };
  }

  return {
    invalidateQuery,
    files: data,
    totalPages,
    isLoading,
    refetch,
  };
};
