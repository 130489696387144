import { useIntl } from 'react-intl';

import { dateFnsLocaleMap, localeMessagesMap, StringKey } from '../lang';

export const useLocale = () => {
  const { locale } = useIntl();
  return {
    locale,
    dateFnsLocale: dateFnsLocaleMap[locale],
    messagesLocale: localeMessagesMap[locale],
    messagesLocaleFn: (key: StringKey, values: Record<string, string | number> = {}) =>
      Object.entries(values).reduce<string>(
        (text, [key, value]) => text.replace(`{${key}}`, value.toString()),
        localeMessagesMap[locale][key],
      ),
  };
};
