import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export enum PlusIconVariant {
  DEFAULT,
  EVENTS,
  VAULT,
}

export type PlusIconProps = { variant?: PlusIconVariant } & IconProps;

const iconVariant: Record<PlusIconVariant, (props: Except<PlusIconProps, 'variant'>) => ReactNode> =
  {
    [PlusIconVariant.DEFAULT]: ({ iconColor, className, ...props }) => (
      <svg
        className={twMerge('shrink-0', className)}
        fill="none"
        height="14"
        viewBox="0 0 14 14"
        width="14"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00002 1.1665V12.8332M1.16669 6.99984H12.8334"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
    ),

    [PlusIconVariant.EVENTS]: ({ iconColor, className, ...props }) => (
      <svg
        className={twMerge('shrink-0', className)}
        fill="none"
        height="14"
        viewBox="0 0 14 14"
        width="14"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.99996 1.16669V12.8334M1.16663 7.00002H12.8333"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
    ),

    [PlusIconVariant.VAULT]: ({ iconColor, className, ...props }) => (
      <svg
        className={twMerge('shrink-0', className)}
        fill="none"
        height="20"
        viewBox="0 0 21 20"
        width="21"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6651 4.16667V15.8333M4.83173 10H16.4984"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
    ),
  };

const PlusIcon: FC<PlusIconProps> = ({
  iconColor = '#ffffff',
  className,
  variant = PlusIconVariant.DEFAULT,
  ...props
}) => iconVariant[variant]({ iconColor, className, ...props });

export default PlusIcon;
