import React, { FC, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { useReactForm } from '../../../hooks';
import { StringKey } from '../../../lang';
import { IssuedSharesFormula, IssuedSharesFormulaTitle } from '../../../types/companyTypes';
import { CompanySettingsCardComponentProps } from '../CompanySettings';
import { EquityCard } from './EquityCard';
import { formSchema, FormSchema } from './validation';

const CapTableManagementValues = Object.entries(IssuedSharesFormulaTitle);

export type CompanyEquityProps = CompanySettingsCardComponentProps;

export const CompanyEquity: FC<CompanyEquityProps> = ({
  data,
  isEditing,
  setEditing,
  invalidateQuery,
  updateData,
}) => {
  const defaultData: DeepPartial<FormSchema> = {
    issuedSharesFormula: data.issuedSharesFormula,
  };

  const { control, handleSubmit, setValue, reset } = useReactForm({
    mode: 'all',
    schema: formSchema,
    disabled: !isEditing,
    defaultValues: defaultData,
    values: defaultData as FormSchema,
  });

  const { issuedSharesFormula } = useWatch<FormSchema>({ control });
  const { success, error } = formSchema.safeParse({ issuedSharesFormula });
  const isValid = success;

  const submitHandler = useCallback(
    async (data: FormSchema) => {
      updateData(
        {
          issuedSharesFormula: data.issuedSharesFormula,
        },
        {
          onSuccess: () => {
            setEditing(false);
            invalidateQuery();
          },
        },
      );
    },
    [invalidateQuery, setEditing, updateData],
  );

  return (
    <form
      className="flex w-fit flex-col gap-4 rounded-md lg:p-4 lg:shadow-sm"
      onSubmit={handleSubmit(submitHandler)}
    >
      <span className="text-nowrap text-base font-[550] text-gray-700">
        <AppFormattedMessage id={StringKey.CAP_TABLE_MANAGEMENT} />
      </span>
      <div className="flex items-center gap-4">
        {CapTableManagementValues.map(([key, card]) => (
          <Button
            className={twMerge(
              'flex w-full min-w-[336px] flex-1 flex-col rounded-2xl bg-transparent px-0 py-0 text-start ring-1 ring-gray-200 data-[checked]:ring-2 data-[checked]:ring-brand-700',
              issuedSharesFormula === key && 'ring-2 ring-brand-700',
            )}
            key={key}
            onClick={() => {
              if (issuedSharesFormula === key) return;

              setValue('issuedSharesFormula', key);
              setEditing(true);
            }}
            styleType="NONE"
            type="button"
          >
            <EquityCard
              capTableManagement={card}
              isDefault={key === IssuedSharesFormula.ISSUED_SHARES_AND_AVAILABLE_POOL}
              selected={issuedSharesFormula === key}
            />
          </Button>
        ))}
      </div>
      {isEditing && (
        <div className="mt-2 flex w-full justify-end gap-4 border-t-[1px] border-gray-200 pb-2 pt-6">
          <Button
            className="border-[1px] border-gray-100 p-[10px] text-sm font-[550] text-gray-700 transition-colors hover:bg-gray-100"
            onClick={() => {
              setEditing(false);
              reset();
            }}
            styleType="DEFAULT_ROUNDED"
            type="button"
          >
            <AppFormattedMessage id={StringKey.CANCEL} />
          </Button>
          <Button
            className="w-full max-w-[308px] px-4 py-3 text-sm font-[550] text-gray-25 transition-colors hover:bg-brand-600"
            disabled={!isValid}
            type="submit"
          >
            <AppFormattedMessage id={StringKey.UPDATE} />
          </Button>
        </div>
      )}
    </form>
  );
};
